export default [
  {
    regionId: 11,
    parentId: 0,
    regionName: "北京市",
    level: 0,
    children: [
      {
        regionId: 1101,
        parentId: 11,
        regionName: "北京市",
        level: 1,
        children: [
          {
            regionId: 110101,
            parentId: 1101,
            regionName: "东城区",
            level: 2,
          },
          {
            regionId: 110102,
            parentId: 1101,
            regionName: "西城区",
            level: 2,
          },
          {
            regionId: 110105,
            parentId: 1101,
            regionName: "朝阳区",
            level: 2,
          },
          {
            regionId: 110106,
            parentId: 1101,
            regionName: "丰台区",
            level: 2,
          },
          {
            regionId: 110107,
            parentId: 1101,
            regionName: "石景山区",
            level: 2,
          },
          {
            regionId: 110108,
            parentId: 1101,
            regionName: "海淀区",
            level: 2,
          },
          {
            regionId: 110109,
            parentId: 1101,
            regionName: "门头沟区",
            level: 2,
          },
          {
            regionId: 110111,
            parentId: 1101,
            regionName: "房山区",
            level: 2,
          },
          {
            regionId: 110112,
            parentId: 1101,
            regionName: "通州区",
            level: 2,
          },
          {
            regionId: 110113,
            parentId: 1101,
            regionName: "顺义区",
            level: 2,
          },
          {
            regionId: 110114,
            parentId: 1101,
            regionName: "昌平区",
            level: 2,
          },
          {
            regionId: 110115,
            parentId: 1101,
            regionName: "大兴区",
            level: 2,
          },
          {
            regionId: 110116,
            parentId: 1101,
            regionName: "怀柔区",
            level: 2,
          },
          {
            regionId: 110117,
            parentId: 1101,
            regionName: "平谷区",
            level: 2,
          },
          {
            regionId: 110118,
            parentId: 1101,
            regionName: "密云区",
            level: 2,
          },
          {
            regionId: 110119,
            parentId: 1101,
            regionName: "延庆区",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 12,
    parentId: 0,
    regionName: "天津市",
    level: 0,
    children: [
      {
        regionId: 1201,
        parentId: 12,
        regionName: "天津市",
        level: 1,
        children: [
          {
            regionId: 120101,
            parentId: 1201,
            regionName: "和平区",
            level: 2,
          },
          {
            regionId: 120102,
            parentId: 1201,
            regionName: "河东区",
            level: 2,
          },
          {
            regionId: 120103,
            parentId: 1201,
            regionName: "河西区",
            level: 2,
          },
          {
            regionId: 120104,
            parentId: 1201,
            regionName: "南开区",
            level: 2,
          },
          {
            regionId: 120105,
            parentId: 1201,
            regionName: "河北区",
            level: 2,
          },
          {
            regionId: 120106,
            parentId: 1201,
            regionName: "红桥区",
            level: 2,
          },
          {
            regionId: 120110,
            parentId: 1201,
            regionName: "东丽区",
            level: 2,
          },
          {
            regionId: 120111,
            parentId: 1201,
            regionName: "西青区",
            level: 2,
          },
          {
            regionId: 120112,
            parentId: 1201,
            regionName: "津南区",
            level: 2,
          },
          {
            regionId: 120113,
            parentId: 1201,
            regionName: "北辰区",
            level: 2,
          },
          {
            regionId: 120114,
            parentId: 1201,
            regionName: "武清区",
            level: 2,
          },
          {
            regionId: 120115,
            parentId: 1201,
            regionName: "宝坻区",
            level: 2,
          },
          {
            regionId: 120116,
            parentId: 1201,
            regionName: "滨海新区",
            level: 2,
          },
          {
            regionId: 120117,
            parentId: 1201,
            regionName: "宁河区",
            level: 2,
          },
          {
            regionId: 120118,
            parentId: 1201,
            regionName: "静海区",
            level: 2,
          },
          {
            regionId: 120119,
            parentId: 1201,
            regionName: "蓟州区",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 13,
    parentId: 0,
    regionName: "河北省",
    level: 0,
    children: [
      {
        regionId: 1301,
        parentId: 13,
        regionName: "石家庄市",
        level: 1,
        children: [
          {
            regionId: 130102,
            parentId: 1301,
            regionName: "长安区",
            level: 2,
          },
          {
            regionId: 130104,
            parentId: 1301,
            regionName: "桥西区",
            level: 2,
          },
          {
            regionId: 130105,
            parentId: 1301,
            regionName: "新华区",
            level: 2,
          },
          {
            regionId: 130107,
            parentId: 1301,
            regionName: "井陉矿区",
            level: 2,
          },
          {
            regionId: 130108,
            parentId: 1301,
            regionName: "裕华区",
            level: 2,
          },
          {
            regionId: 130109,
            parentId: 1301,
            regionName: "藁城区",
            level: 2,
          },
          {
            regionId: 130110,
            parentId: 1301,
            regionName: "鹿泉区",
            level: 2,
          },
          {
            regionId: 130111,
            parentId: 1301,
            regionName: "栾城区",
            level: 2,
          },
          {
            regionId: 130121,
            parentId: 1301,
            regionName: "井陉县",
            level: 2,
          },
          {
            regionId: 130123,
            parentId: 1301,
            regionName: "正定县",
            level: 2,
          },
          {
            regionId: 130125,
            parentId: 1301,
            regionName: "行唐县",
            level: 2,
          },
          {
            regionId: 130126,
            parentId: 1301,
            regionName: "灵寿县",
            level: 2,
          },
          {
            regionId: 130127,
            parentId: 1301,
            regionName: "高邑县",
            level: 2,
          },
          {
            regionId: 130128,
            parentId: 1301,
            regionName: "深泽县",
            level: 2,
          },
          {
            regionId: 130129,
            parentId: 1301,
            regionName: "赞皇县",
            level: 2,
          },
          {
            regionId: 130130,
            parentId: 1301,
            regionName: "无极县",
            level: 2,
          },
          {
            regionId: 130131,
            parentId: 1301,
            regionName: "平山县",
            level: 2,
          },
          {
            regionId: 130132,
            parentId: 1301,
            regionName: "元氏县",
            level: 2,
          },
          {
            regionId: 130133,
            parentId: 1301,
            regionName: "赵县",
            level: 2,
          },
          {
            regionId: 130181,
            parentId: 1301,
            regionName: "辛集市",
            level: 2,
          },
          {
            regionId: 130183,
            parentId: 1301,
            regionName: "晋州市",
            level: 2,
          },
          {
            regionId: 130184,
            parentId: 1301,
            regionName: "新乐市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1302,
        parentId: 13,
        regionName: "唐山市",
        level: 1,
        children: [
          {
            regionId: 130202,
            parentId: 1302,
            regionName: "路南区",
            level: 2,
          },
          {
            regionId: 130203,
            parentId: 1302,
            regionName: "路北区",
            level: 2,
          },
          {
            regionId: 130204,
            parentId: 1302,
            regionName: "古冶区",
            level: 2,
          },
          {
            regionId: 130205,
            parentId: 1302,
            regionName: "开平区",
            level: 2,
          },
          {
            regionId: 130207,
            parentId: 1302,
            regionName: "丰南区",
            level: 2,
          },
          {
            regionId: 130208,
            parentId: 1302,
            regionName: "丰润区",
            level: 2,
          },
          {
            regionId: 130209,
            parentId: 1302,
            regionName: "曹妃甸区",
            level: 2,
          },
          {
            regionId: 130224,
            parentId: 1302,
            regionName: "滦南县",
            level: 2,
          },
          {
            regionId: 130225,
            parentId: 1302,
            regionName: "乐亭县",
            level: 2,
          },
          {
            regionId: 130227,
            parentId: 1302,
            regionName: "迁西县",
            level: 2,
          },
          {
            regionId: 130229,
            parentId: 1302,
            regionName: "玉田县",
            level: 2,
          },
          {
            regionId: 130281,
            parentId: 1302,
            regionName: "遵化市",
            level: 2,
          },
          {
            regionId: 130283,
            parentId: 1302,
            regionName: "迁安市",
            level: 2,
          },
          {
            regionId: 130284,
            parentId: 1302,
            regionName: "滦州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1303,
        parentId: 13,
        regionName: "秦皇岛市",
        level: 1,
        children: [
          {
            regionId: 130302,
            parentId: 1303,
            regionName: "海港区",
            level: 2,
          },
          {
            regionId: 130303,
            parentId: 1303,
            regionName: "山海关区",
            level: 2,
          },
          {
            regionId: 130304,
            parentId: 1303,
            regionName: "北戴河区",
            level: 2,
          },
          {
            regionId: 130306,
            parentId: 1303,
            regionName: "抚宁区",
            level: 2,
          },
          {
            regionId: 130321,
            parentId: 1303,
            regionName: "青龙满族自治县",
            level: 2,
          },
          {
            regionId: 130322,
            parentId: 1303,
            regionName: "昌黎县",
            level: 2,
          },
          {
            regionId: 130324,
            parentId: 1303,
            regionName: "卢龙县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1304,
        parentId: 13,
        regionName: "邯郸市",
        level: 1,
        children: [
          {
            regionId: 130402,
            parentId: 1304,
            regionName: "邯山区",
            level: 2,
          },
          {
            regionId: 130403,
            parentId: 1304,
            regionName: "丛台区",
            level: 2,
          },
          {
            regionId: 130404,
            parentId: 1304,
            regionName: "复兴区",
            level: 2,
          },
          {
            regionId: 130406,
            parentId: 1304,
            regionName: "峰峰矿区",
            level: 2,
          },
          {
            regionId: 130407,
            parentId: 1304,
            regionName: "肥乡区",
            level: 2,
          },
          {
            regionId: 130408,
            parentId: 1304,
            regionName: "永年区",
            level: 2,
          },
          {
            regionId: 130423,
            parentId: 1304,
            regionName: "临漳县",
            level: 2,
          },
          {
            regionId: 130424,
            parentId: 1304,
            regionName: "成安县",
            level: 2,
          },
          {
            regionId: 130425,
            parentId: 1304,
            regionName: "大名县",
            level: 2,
          },
          {
            regionId: 130426,
            parentId: 1304,
            regionName: "涉县",
            level: 2,
          },
          {
            regionId: 130427,
            parentId: 1304,
            regionName: "磁县",
            level: 2,
          },
          {
            regionId: 130430,
            parentId: 1304,
            regionName: "邱县",
            level: 2,
          },
          {
            regionId: 130431,
            parentId: 1304,
            regionName: "鸡泽县",
            level: 2,
          },
          {
            regionId: 130432,
            parentId: 1304,
            regionName: "广平县",
            level: 2,
          },
          {
            regionId: 130433,
            parentId: 1304,
            regionName: "馆陶县",
            level: 2,
          },
          {
            regionId: 130434,
            parentId: 1304,
            regionName: "魏县",
            level: 2,
          },
          {
            regionId: 130435,
            parentId: 1304,
            regionName: "曲周县",
            level: 2,
          },
          {
            regionId: 130481,
            parentId: 1304,
            regionName: "武安市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1305,
        parentId: 13,
        regionName: "邢台市",
        level: 1,
        children: [
          {
            regionId: 130502,
            parentId: 1305,
            regionName: "襄都区",
            level: 2,
          },
          {
            regionId: 130503,
            parentId: 1305,
            regionName: "信都区",
            level: 2,
          },
          {
            regionId: 130505,
            parentId: 1305,
            regionName: "任泽区",
            level: 2,
          },
          {
            regionId: 130506,
            parentId: 1305,
            regionName: "南和区",
            level: 2,
          },
          {
            regionId: 130522,
            parentId: 1305,
            regionName: "临城县",
            level: 2,
          },
          {
            regionId: 130523,
            parentId: 1305,
            regionName: "内丘县",
            level: 2,
          },
          {
            regionId: 130524,
            parentId: 1305,
            regionName: "柏乡县",
            level: 2,
          },
          {
            regionId: 130525,
            parentId: 1305,
            regionName: "隆尧县",
            level: 2,
          },
          {
            regionId: 130528,
            parentId: 1305,
            regionName: "宁晋县",
            level: 2,
          },
          {
            regionId: 130529,
            parentId: 1305,
            regionName: "巨鹿县",
            level: 2,
          },
          {
            regionId: 130530,
            parentId: 1305,
            regionName: "新河县",
            level: 2,
          },
          {
            regionId: 130531,
            parentId: 1305,
            regionName: "广宗县",
            level: 2,
          },
          {
            regionId: 130532,
            parentId: 1305,
            regionName: "平乡县",
            level: 2,
          },
          {
            regionId: 130533,
            parentId: 1305,
            regionName: "威县",
            level: 2,
          },
          {
            regionId: 130534,
            parentId: 1305,
            regionName: "清河县",
            level: 2,
          },
          {
            regionId: 130535,
            parentId: 1305,
            regionName: "临西县",
            level: 2,
          },
          {
            regionId: 130581,
            parentId: 1305,
            regionName: "南宫市",
            level: 2,
          },
          {
            regionId: 130582,
            parentId: 1305,
            regionName: "沙河市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1306,
        parentId: 13,
        regionName: "保定市",
        level: 1,
        children: [
          {
            regionId: 130602,
            parentId: 1306,
            regionName: "竞秀区",
            level: 2,
          },
          {
            regionId: 130606,
            parentId: 1306,
            regionName: "莲池区",
            level: 2,
          },
          {
            regionId: 130607,
            parentId: 1306,
            regionName: "满城区",
            level: 2,
          },
          {
            regionId: 130608,
            parentId: 1306,
            regionName: "清苑区",
            level: 2,
          },
          {
            regionId: 130609,
            parentId: 1306,
            regionName: "徐水区",
            level: 2,
          },
          {
            regionId: 130623,
            parentId: 1306,
            regionName: "涞水县",
            level: 2,
          },
          {
            regionId: 130624,
            parentId: 1306,
            regionName: "阜平县",
            level: 2,
          },
          {
            regionId: 130626,
            parentId: 1306,
            regionName: "定兴县",
            level: 2,
          },
          {
            regionId: 130627,
            parentId: 1306,
            regionName: "唐县",
            level: 2,
          },
          {
            regionId: 130628,
            parentId: 1306,
            regionName: "高阳县",
            level: 2,
          },
          {
            regionId: 130629,
            parentId: 1306,
            regionName: "容城县",
            level: 2,
          },
          {
            regionId: 130630,
            parentId: 1306,
            regionName: "涞源县",
            level: 2,
          },
          {
            regionId: 130631,
            parentId: 1306,
            regionName: "望都县",
            level: 2,
          },
          {
            regionId: 130632,
            parentId: 1306,
            regionName: "安新县",
            level: 2,
          },
          {
            regionId: 130633,
            parentId: 1306,
            regionName: "易县",
            level: 2,
          },
          {
            regionId: 130634,
            parentId: 1306,
            regionName: "曲阳县",
            level: 2,
          },
          {
            regionId: 130635,
            parentId: 1306,
            regionName: "蠡县",
            level: 2,
          },
          {
            regionId: 130636,
            parentId: 1306,
            regionName: "顺平县",
            level: 2,
          },
          {
            regionId: 130637,
            parentId: 1306,
            regionName: "博野县",
            level: 2,
          },
          {
            regionId: 130638,
            parentId: 1306,
            regionName: "雄县",
            level: 2,
          },
          {
            regionId: 130681,
            parentId: 1306,
            regionName: "涿州市",
            level: 2,
          },
          {
            regionId: 130682,
            parentId: 1306,
            regionName: "定州市",
            level: 2,
          },
          {
            regionId: 130683,
            parentId: 1306,
            regionName: "安国市",
            level: 2,
          },
          {
            regionId: 130684,
            parentId: 1306,
            regionName: "高碑店市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1307,
        parentId: 13,
        regionName: "张家口市",
        level: 1,
        children: [
          {
            regionId: 130702,
            parentId: 1307,
            regionName: "桥东区",
            level: 2,
          },
          {
            regionId: 130703,
            parentId: 1307,
            regionName: "桥西区",
            level: 2,
          },
          {
            regionId: 130705,
            parentId: 1307,
            regionName: "宣化区",
            level: 2,
          },
          {
            regionId: 130706,
            parentId: 1307,
            regionName: "下花园区",
            level: 2,
          },
          {
            regionId: 130708,
            parentId: 1307,
            regionName: "万全区",
            level: 2,
          },
          {
            regionId: 130709,
            parentId: 1307,
            regionName: "崇礼区",
            level: 2,
          },
          {
            regionId: 130722,
            parentId: 1307,
            regionName: "张北县",
            level: 2,
          },
          {
            regionId: 130723,
            parentId: 1307,
            regionName: "康保县",
            level: 2,
          },
          {
            regionId: 130724,
            parentId: 1307,
            regionName: "沽源县",
            level: 2,
          },
          {
            regionId: 130725,
            parentId: 1307,
            regionName: "尚义县",
            level: 2,
          },
          {
            regionId: 130726,
            parentId: 1307,
            regionName: "蔚县",
            level: 2,
          },
          {
            regionId: 130727,
            parentId: 1307,
            regionName: "阳原县",
            level: 2,
          },
          {
            regionId: 130728,
            parentId: 1307,
            regionName: "怀安县",
            level: 2,
          },
          {
            regionId: 130730,
            parentId: 1307,
            regionName: "怀来县",
            level: 2,
          },
          {
            regionId: 130731,
            parentId: 1307,
            regionName: "涿鹿县",
            level: 2,
          },
          {
            regionId: 130732,
            parentId: 1307,
            regionName: "赤城县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1308,
        parentId: 13,
        regionName: "承德市",
        level: 1,
        children: [
          {
            regionId: 130802,
            parentId: 1308,
            regionName: "双桥区",
            level: 2,
          },
          {
            regionId: 130803,
            parentId: 1308,
            regionName: "双滦区",
            level: 2,
          },
          {
            regionId: 130804,
            parentId: 1308,
            regionName: "鹰手营子矿区",
            level: 2,
          },
          {
            regionId: 130821,
            parentId: 1308,
            regionName: "承德县",
            level: 2,
          },
          {
            regionId: 130822,
            parentId: 1308,
            regionName: "兴隆县",
            level: 2,
          },
          {
            regionId: 130824,
            parentId: 1308,
            regionName: "滦平县",
            level: 2,
          },
          {
            regionId: 130825,
            parentId: 1308,
            regionName: "隆化县",
            level: 2,
          },
          {
            regionId: 130826,
            parentId: 1308,
            regionName: "丰宁满族自治县",
            level: 2,
          },
          {
            regionId: 130827,
            parentId: 1308,
            regionName: "宽城满族自治县",
            level: 2,
          },
          {
            regionId: 130828,
            parentId: 1308,
            regionName: "围场满族蒙古族自治县",
            level: 2,
          },
          {
            regionId: 130881,
            parentId: 1308,
            regionName: "平泉市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1309,
        parentId: 13,
        regionName: "沧州市",
        level: 1,
        children: [
          {
            regionId: 130902,
            parentId: 1309,
            regionName: "新华区",
            level: 2,
          },
          {
            regionId: 130903,
            parentId: 1309,
            regionName: "运河区",
            level: 2,
          },
          {
            regionId: 130921,
            parentId: 1309,
            regionName: "沧县",
            level: 2,
          },
          {
            regionId: 130922,
            parentId: 1309,
            regionName: "青县",
            level: 2,
          },
          {
            regionId: 130923,
            parentId: 1309,
            regionName: "东光县",
            level: 2,
          },
          {
            regionId: 130924,
            parentId: 1309,
            regionName: "海兴县",
            level: 2,
          },
          {
            regionId: 130925,
            parentId: 1309,
            regionName: "盐山县",
            level: 2,
          },
          {
            regionId: 130926,
            parentId: 1309,
            regionName: "肃宁县",
            level: 2,
          },
          {
            regionId: 130927,
            parentId: 1309,
            regionName: "南皮县",
            level: 2,
          },
          {
            regionId: 130928,
            parentId: 1309,
            regionName: "吴桥县",
            level: 2,
          },
          {
            regionId: 130929,
            parentId: 1309,
            regionName: "献县",
            level: 2,
          },
          {
            regionId: 130930,
            parentId: 1309,
            regionName: "孟村回族自治县",
            level: 2,
          },
          {
            regionId: 130981,
            parentId: 1309,
            regionName: "泊头市",
            level: 2,
          },
          {
            regionId: 130982,
            parentId: 1309,
            regionName: "任丘市",
            level: 2,
          },
          {
            regionId: 130983,
            parentId: 1309,
            regionName: "黄骅市",
            level: 2,
          },
          {
            regionId: 130984,
            parentId: 1309,
            regionName: "河间市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1310,
        parentId: 13,
        regionName: "廊坊市",
        level: 1,
        children: [
          {
            regionId: 131002,
            parentId: 1310,
            regionName: "安次区",
            level: 2,
          },
          {
            regionId: 131003,
            parentId: 1310,
            regionName: "广阳区",
            level: 2,
          },
          {
            regionId: 131022,
            parentId: 1310,
            regionName: "固安县",
            level: 2,
          },
          {
            regionId: 131023,
            parentId: 1310,
            regionName: "永清县",
            level: 2,
          },
          {
            regionId: 131024,
            parentId: 1310,
            regionName: "香河县",
            level: 2,
          },
          {
            regionId: 131025,
            parentId: 1310,
            regionName: "大城县",
            level: 2,
          },
          {
            regionId: 131026,
            parentId: 1310,
            regionName: "文安县",
            level: 2,
          },
          {
            regionId: 131028,
            parentId: 1310,
            regionName: "大厂回族自治县",
            level: 2,
          },
          {
            regionId: 131081,
            parentId: 1310,
            regionName: "霸州市",
            level: 2,
          },
          {
            regionId: 131082,
            parentId: 1310,
            regionName: "三河市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1311,
        parentId: 13,
        regionName: "衡水市",
        level: 1,
        children: [
          {
            regionId: 131102,
            parentId: 1311,
            regionName: "桃城区",
            level: 2,
          },
          {
            regionId: 131103,
            parentId: 1311,
            regionName: "冀州区",
            level: 2,
          },
          {
            regionId: 131121,
            parentId: 1311,
            regionName: "枣强县",
            level: 2,
          },
          {
            regionId: 131122,
            parentId: 1311,
            regionName: "武邑县",
            level: 2,
          },
          {
            regionId: 131123,
            parentId: 1311,
            regionName: "武强县",
            level: 2,
          },
          {
            regionId: 131124,
            parentId: 1311,
            regionName: "饶阳县",
            level: 2,
          },
          {
            regionId: 131125,
            parentId: 1311,
            regionName: "安平县",
            level: 2,
          },
          {
            regionId: 131126,
            parentId: 1311,
            regionName: "故城县",
            level: 2,
          },
          {
            regionId: 131127,
            parentId: 1311,
            regionName: "景县",
            level: 2,
          },
          {
            regionId: 131128,
            parentId: 1311,
            regionName: "阜城县",
            level: 2,
          },
          {
            regionId: 131182,
            parentId: 1311,
            regionName: "深州市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 14,
    parentId: 0,
    regionName: "山西省",
    level: 0,
    children: [
      {
        regionId: 1401,
        parentId: 14,
        regionName: "太原市",
        level: 1,
        children: [
          {
            regionId: 140105,
            parentId: 1401,
            regionName: "小店区",
            level: 2,
          },
          {
            regionId: 140106,
            parentId: 1401,
            regionName: "迎泽区",
            level: 2,
          },
          {
            regionId: 140107,
            parentId: 1401,
            regionName: "杏花岭区",
            level: 2,
          },
          {
            regionId: 140108,
            parentId: 1401,
            regionName: "尖草坪区",
            level: 2,
          },
          {
            regionId: 140109,
            parentId: 1401,
            regionName: "万柏林区",
            level: 2,
          },
          {
            regionId: 140110,
            parentId: 1401,
            regionName: "晋源区",
            level: 2,
          },
          {
            regionId: 140121,
            parentId: 1401,
            regionName: "清徐县",
            level: 2,
          },
          {
            regionId: 140122,
            parentId: 1401,
            regionName: "阳曲县",
            level: 2,
          },
          {
            regionId: 140123,
            parentId: 1401,
            regionName: "娄烦县",
            level: 2,
          },
          {
            regionId: 140181,
            parentId: 1401,
            regionName: "古交市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1402,
        parentId: 14,
        regionName: "大同市",
        level: 1,
        children: [
          {
            regionId: 140212,
            parentId: 1402,
            regionName: "新荣区",
            level: 2,
          },
          {
            regionId: 140213,
            parentId: 1402,
            regionName: "平城区",
            level: 2,
          },
          {
            regionId: 140214,
            parentId: 1402,
            regionName: "云冈区",
            level: 2,
          },
          {
            regionId: 140215,
            parentId: 1402,
            regionName: "云州区",
            level: 2,
          },
          {
            regionId: 140221,
            parentId: 1402,
            regionName: "阳高县",
            level: 2,
          },
          {
            regionId: 140222,
            parentId: 1402,
            regionName: "天镇县",
            level: 2,
          },
          {
            regionId: 140223,
            parentId: 1402,
            regionName: "广灵县",
            level: 2,
          },
          {
            regionId: 140224,
            parentId: 1402,
            regionName: "灵丘县",
            level: 2,
          },
          {
            regionId: 140225,
            parentId: 1402,
            regionName: "浑源县",
            level: 2,
          },
          {
            regionId: 140226,
            parentId: 1402,
            regionName: "左云县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1403,
        parentId: 14,
        regionName: "阳泉市",
        level: 1,
        children: [
          {
            regionId: 140302,
            parentId: 1403,
            regionName: "城区",
            level: 2,
          },
          {
            regionId: 140303,
            parentId: 1403,
            regionName: "矿区",
            level: 2,
          },
          {
            regionId: 140311,
            parentId: 1403,
            regionName: "郊区",
            level: 2,
          },
          {
            regionId: 140321,
            parentId: 1403,
            regionName: "平定县",
            level: 2,
          },
          {
            regionId: 140322,
            parentId: 1403,
            regionName: "盂县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1404,
        parentId: 14,
        regionName: "长治市",
        level: 1,
        children: [
          {
            regionId: 140403,
            parentId: 1404,
            regionName: "潞州区",
            level: 2,
          },
          {
            regionId: 140404,
            parentId: 1404,
            regionName: "上党区",
            level: 2,
          },
          {
            regionId: 140405,
            parentId: 1404,
            regionName: "屯留区",
            level: 2,
          },
          {
            regionId: 140406,
            parentId: 1404,
            regionName: "潞城区",
            level: 2,
          },
          {
            regionId: 140423,
            parentId: 1404,
            regionName: "襄垣县",
            level: 2,
          },
          {
            regionId: 140425,
            parentId: 1404,
            regionName: "平顺县",
            level: 2,
          },
          {
            regionId: 140426,
            parentId: 1404,
            regionName: "黎城县",
            level: 2,
          },
          {
            regionId: 140427,
            parentId: 1404,
            regionName: "壶关县",
            level: 2,
          },
          {
            regionId: 140428,
            parentId: 1404,
            regionName: "长子县",
            level: 2,
          },
          {
            regionId: 140429,
            parentId: 1404,
            regionName: "武乡县",
            level: 2,
          },
          {
            regionId: 140430,
            parentId: 1404,
            regionName: "沁县",
            level: 2,
          },
          {
            regionId: 140431,
            parentId: 1404,
            regionName: "沁源县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1405,
        parentId: 14,
        regionName: "晋城市",
        level: 1,
        children: [
          {
            regionId: 140502,
            parentId: 1405,
            regionName: "城区",
            level: 2,
          },
          {
            regionId: 140521,
            parentId: 1405,
            regionName: "沁水县",
            level: 2,
          },
          {
            regionId: 140522,
            parentId: 1405,
            regionName: "阳城县",
            level: 2,
          },
          {
            regionId: 140524,
            parentId: 1405,
            regionName: "陵川县",
            level: 2,
          },
          {
            regionId: 140525,
            parentId: 1405,
            regionName: "泽州县",
            level: 2,
          },
          {
            regionId: 140581,
            parentId: 1405,
            regionName: "高平市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1406,
        parentId: 14,
        regionName: "朔州市",
        level: 1,
        children: [
          {
            regionId: 140602,
            parentId: 1406,
            regionName: "朔城区",
            level: 2,
          },
          {
            regionId: 140603,
            parentId: 1406,
            regionName: "平鲁区",
            level: 2,
          },
          {
            regionId: 140621,
            parentId: 1406,
            regionName: "山阴县",
            level: 2,
          },
          {
            regionId: 140622,
            parentId: 1406,
            regionName: "应县",
            level: 2,
          },
          {
            regionId: 140623,
            parentId: 1406,
            regionName: "右玉县",
            level: 2,
          },
          {
            regionId: 140681,
            parentId: 1406,
            regionName: "怀仁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1407,
        parentId: 14,
        regionName: "晋中市",
        level: 1,
        children: [
          {
            regionId: 140702,
            parentId: 1407,
            regionName: "榆次区",
            level: 2,
          },
          {
            regionId: 140703,
            parentId: 1407,
            regionName: "太谷区",
            level: 2,
          },
          {
            regionId: 140721,
            parentId: 1407,
            regionName: "榆社县",
            level: 2,
          },
          {
            regionId: 140722,
            parentId: 1407,
            regionName: "左权县",
            level: 2,
          },
          {
            regionId: 140723,
            parentId: 1407,
            regionName: "和顺县",
            level: 2,
          },
          {
            regionId: 140724,
            parentId: 1407,
            regionName: "昔阳县",
            level: 2,
          },
          {
            regionId: 140725,
            parentId: 1407,
            regionName: "寿阳县",
            level: 2,
          },
          {
            regionId: 140727,
            parentId: 1407,
            regionName: "祁县",
            level: 2,
          },
          {
            regionId: 140728,
            parentId: 1407,
            regionName: "平遥县",
            level: 2,
          },
          {
            regionId: 140729,
            parentId: 1407,
            regionName: "灵石县",
            level: 2,
          },
          {
            regionId: 140781,
            parentId: 1407,
            regionName: "介休市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1408,
        parentId: 14,
        regionName: "运城市",
        level: 1,
        children: [
          {
            regionId: 140802,
            parentId: 1408,
            regionName: "盐湖区",
            level: 2,
          },
          {
            regionId: 140821,
            parentId: 1408,
            regionName: "临猗县",
            level: 2,
          },
          {
            regionId: 140822,
            parentId: 1408,
            regionName: "万荣县",
            level: 2,
          },
          {
            regionId: 140823,
            parentId: 1408,
            regionName: "闻喜县",
            level: 2,
          },
          {
            regionId: 140824,
            parentId: 1408,
            regionName: "稷山县",
            level: 2,
          },
          {
            regionId: 140825,
            parentId: 1408,
            regionName: "新绛县",
            level: 2,
          },
          {
            regionId: 140826,
            parentId: 1408,
            regionName: "绛县",
            level: 2,
          },
          {
            regionId: 140827,
            parentId: 1408,
            regionName: "垣曲县",
            level: 2,
          },
          {
            regionId: 140828,
            parentId: 1408,
            regionName: "夏县",
            level: 2,
          },
          {
            regionId: 140829,
            parentId: 1408,
            regionName: "平陆县",
            level: 2,
          },
          {
            regionId: 140830,
            parentId: 1408,
            regionName: "芮城县",
            level: 2,
          },
          {
            regionId: 140881,
            parentId: 1408,
            regionName: "永济市",
            level: 2,
          },
          {
            regionId: 140882,
            parentId: 1408,
            regionName: "河津市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1409,
        parentId: 14,
        regionName: "忻州市",
        level: 1,
        children: [
          {
            regionId: 140902,
            parentId: 1409,
            regionName: "忻府区",
            level: 2,
          },
          {
            regionId: 140921,
            parentId: 1409,
            regionName: "定襄县",
            level: 2,
          },
          {
            regionId: 140922,
            parentId: 1409,
            regionName: "五台县",
            level: 2,
          },
          {
            regionId: 140923,
            parentId: 1409,
            regionName: "代县",
            level: 2,
          },
          {
            regionId: 140924,
            parentId: 1409,
            regionName: "繁峙县",
            level: 2,
          },
          {
            regionId: 140925,
            parentId: 1409,
            regionName: "宁武县",
            level: 2,
          },
          {
            regionId: 140926,
            parentId: 1409,
            regionName: "静乐县",
            level: 2,
          },
          {
            regionId: 140927,
            parentId: 1409,
            regionName: "神池县",
            level: 2,
          },
          {
            regionId: 140928,
            parentId: 1409,
            regionName: "五寨县",
            level: 2,
          },
          {
            regionId: 140929,
            parentId: 1409,
            regionName: "岢岚县",
            level: 2,
          },
          {
            regionId: 140930,
            parentId: 1409,
            regionName: "河曲县",
            level: 2,
          },
          {
            regionId: 140931,
            parentId: 1409,
            regionName: "保德县",
            level: 2,
          },
          {
            regionId: 140932,
            parentId: 1409,
            regionName: "偏关县",
            level: 2,
          },
          {
            regionId: 140981,
            parentId: 1409,
            regionName: "原平市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1410,
        parentId: 14,
        regionName: "临汾市",
        level: 1,
        children: [
          {
            regionId: 141002,
            parentId: 1410,
            regionName: "尧都区",
            level: 2,
          },
          {
            regionId: 141021,
            parentId: 1410,
            regionName: "曲沃县",
            level: 2,
          },
          {
            regionId: 141022,
            parentId: 1410,
            regionName: "翼城县",
            level: 2,
          },
          {
            regionId: 141023,
            parentId: 1410,
            regionName: "襄汾县",
            level: 2,
          },
          {
            regionId: 141024,
            parentId: 1410,
            regionName: "洪洞县",
            level: 2,
          },
          {
            regionId: 141025,
            parentId: 1410,
            regionName: "古县",
            level: 2,
          },
          {
            regionId: 141026,
            parentId: 1410,
            regionName: "安泽县",
            level: 2,
          },
          {
            regionId: 141027,
            parentId: 1410,
            regionName: "浮山县",
            level: 2,
          },
          {
            regionId: 141028,
            parentId: 1410,
            regionName: "吉县",
            level: 2,
          },
          {
            regionId: 141029,
            parentId: 1410,
            regionName: "乡宁县",
            level: 2,
          },
          {
            regionId: 141030,
            parentId: 1410,
            regionName: "大宁县",
            level: 2,
          },
          {
            regionId: 141031,
            parentId: 1410,
            regionName: "隰县",
            level: 2,
          },
          {
            regionId: 141032,
            parentId: 1410,
            regionName: "永和县",
            level: 2,
          },
          {
            regionId: 141033,
            parentId: 1410,
            regionName: "蒲县",
            level: 2,
          },
          {
            regionId: 141034,
            parentId: 1410,
            regionName: "汾西县",
            level: 2,
          },
          {
            regionId: 141081,
            parentId: 1410,
            regionName: "侯马市",
            level: 2,
          },
          {
            regionId: 141082,
            parentId: 1410,
            regionName: "霍州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1411,
        parentId: 14,
        regionName: "吕梁市",
        level: 1,
        children: [
          {
            regionId: 141102,
            parentId: 1411,
            regionName: "离石区",
            level: 2,
          },
          {
            regionId: 141121,
            parentId: 1411,
            regionName: "文水县",
            level: 2,
          },
          {
            regionId: 141122,
            parentId: 1411,
            regionName: "交城县",
            level: 2,
          },
          {
            regionId: 141123,
            parentId: 1411,
            regionName: "兴县",
            level: 2,
          },
          {
            regionId: 141124,
            parentId: 1411,
            regionName: "临县",
            level: 2,
          },
          {
            regionId: 141125,
            parentId: 1411,
            regionName: "柳林县",
            level: 2,
          },
          {
            regionId: 141126,
            parentId: 1411,
            regionName: "石楼县",
            level: 2,
          },
          {
            regionId: 141127,
            parentId: 1411,
            regionName: "岚县",
            level: 2,
          },
          {
            regionId: 141128,
            parentId: 1411,
            regionName: "方山县",
            level: 2,
          },
          {
            regionId: 141129,
            parentId: 1411,
            regionName: "中阳县",
            level: 2,
          },
          {
            regionId: 141130,
            parentId: 1411,
            regionName: "交口县",
            level: 2,
          },
          {
            regionId: 141181,
            parentId: 1411,
            regionName: "孝义市",
            level: 2,
          },
          {
            regionId: 141182,
            parentId: 1411,
            regionName: "汾阳市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 15,
    parentId: 0,
    regionName: "内蒙古自治区",
    level: 0,
    children: [
      {
        regionId: 1501,
        parentId: 15,
        regionName: "呼和浩特市",
        level: 1,
        children: [
          {
            regionId: 150102,
            parentId: 1501,
            regionName: "新城区",
            level: 2,
          },
          {
            regionId: 150103,
            parentId: 1501,
            regionName: "回民区",
            level: 2,
          },
          {
            regionId: 150104,
            parentId: 1501,
            regionName: "玉泉区",
            level: 2,
          },
          {
            regionId: 150105,
            parentId: 1501,
            regionName: "赛罕区",
            level: 2,
          },
          {
            regionId: 150121,
            parentId: 1501,
            regionName: "土默特左旗",
            level: 2,
          },
          {
            regionId: 150122,
            parentId: 1501,
            regionName: "托克托县",
            level: 2,
          },
          {
            regionId: 150123,
            parentId: 1501,
            regionName: "和林格尔县",
            level: 2,
          },
          {
            regionId: 150124,
            parentId: 1501,
            regionName: "清水河县",
            level: 2,
          },
          {
            regionId: 150125,
            parentId: 1501,
            regionName: "武川县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1502,
        parentId: 15,
        regionName: "包头市",
        level: 1,
        children: [
          {
            regionId: 150202,
            parentId: 1502,
            regionName: "东河区",
            level: 2,
          },
          {
            regionId: 150203,
            parentId: 1502,
            regionName: "昆都仑区",
            level: 2,
          },
          {
            regionId: 150204,
            parentId: 1502,
            regionName: "青山区",
            level: 2,
          },
          {
            regionId: 150205,
            parentId: 1502,
            regionName: "石拐区",
            level: 2,
          },
          {
            regionId: 150206,
            parentId: 1502,
            regionName: "白云鄂博矿区",
            level: 2,
          },
          {
            regionId: 150207,
            parentId: 1502,
            regionName: "九原区",
            level: 2,
          },
          {
            regionId: 150221,
            parentId: 1502,
            regionName: "土默特右旗",
            level: 2,
          },
          {
            regionId: 150222,
            parentId: 1502,
            regionName: "固阳县",
            level: 2,
          },
          {
            regionId: 150223,
            parentId: 1502,
            regionName: "达尔罕茂明安联合旗",
            level: 2,
          },
        ],
      },
      {
        regionId: 1503,
        parentId: 15,
        regionName: "乌海市",
        level: 1,
        children: [
          {
            regionId: 150302,
            parentId: 1503,
            regionName: "海勃湾区",
            level: 2,
          },
          {
            regionId: 150303,
            parentId: 1503,
            regionName: "海南区",
            level: 2,
          },
          {
            regionId: 150304,
            parentId: 1503,
            regionName: "乌达区",
            level: 2,
          },
        ],
      },
      {
        regionId: 1504,
        parentId: 15,
        regionName: "赤峰市",
        level: 1,
        children: [
          {
            regionId: 150402,
            parentId: 1504,
            regionName: "红山区",
            level: 2,
          },
          {
            regionId: 150403,
            parentId: 1504,
            regionName: "元宝山区",
            level: 2,
          },
          {
            regionId: 150404,
            parentId: 1504,
            regionName: "松山区",
            level: 2,
          },
          {
            regionId: 150421,
            parentId: 1504,
            regionName: "阿鲁科尔沁旗",
            level: 2,
          },
          {
            regionId: 150422,
            parentId: 1504,
            regionName: "巴林左旗",
            level: 2,
          },
          {
            regionId: 150423,
            parentId: 1504,
            regionName: "巴林右旗",
            level: 2,
          },
          {
            regionId: 150424,
            parentId: 1504,
            regionName: "林西县",
            level: 2,
          },
          {
            regionId: 150425,
            parentId: 1504,
            regionName: "克什克腾旗",
            level: 2,
          },
          {
            regionId: 150426,
            parentId: 1504,
            regionName: "翁牛特旗",
            level: 2,
          },
          {
            regionId: 150428,
            parentId: 1504,
            regionName: "喀喇沁旗",
            level: 2,
          },
          {
            regionId: 150429,
            parentId: 1504,
            regionName: "宁城县",
            level: 2,
          },
          {
            regionId: 150430,
            parentId: 1504,
            regionName: "敖汉旗",
            level: 2,
          },
        ],
      },
      {
        regionId: 1505,
        parentId: 15,
        regionName: "通辽市",
        level: 1,
        children: [
          {
            regionId: 150502,
            parentId: 1505,
            regionName: "科尔沁区",
            level: 2,
          },
          {
            regionId: 150521,
            parentId: 1505,
            regionName: "科尔沁左翼中旗",
            level: 2,
          },
          {
            regionId: 150522,
            parentId: 1505,
            regionName: "科尔沁左翼后旗",
            level: 2,
          },
          {
            regionId: 150523,
            parentId: 1505,
            regionName: "开鲁县",
            level: 2,
          },
          {
            regionId: 150524,
            parentId: 1505,
            regionName: "库伦旗",
            level: 2,
          },
          {
            regionId: 150525,
            parentId: 1505,
            regionName: "奈曼旗",
            level: 2,
          },
          {
            regionId: 150526,
            parentId: 1505,
            regionName: "扎鲁特旗",
            level: 2,
          },
          {
            regionId: 150581,
            parentId: 1505,
            regionName: "霍林郭勒市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1506,
        parentId: 15,
        regionName: "鄂尔多斯市",
        level: 1,
        children: [
          {
            regionId: 150602,
            parentId: 1506,
            regionName: "东胜区",
            level: 2,
          },
          {
            regionId: 150603,
            parentId: 1506,
            regionName: "康巴什区",
            level: 2,
          },
          {
            regionId: 150621,
            parentId: 1506,
            regionName: "达拉特旗",
            level: 2,
          },
          {
            regionId: 150622,
            parentId: 1506,
            regionName: "准格尔旗",
            level: 2,
          },
          {
            regionId: 150623,
            parentId: 1506,
            regionName: "鄂托克前旗",
            level: 2,
          },
          {
            regionId: 150624,
            parentId: 1506,
            regionName: "鄂托克旗",
            level: 2,
          },
          {
            regionId: 150625,
            parentId: 1506,
            regionName: "杭锦旗",
            level: 2,
          },
          {
            regionId: 150626,
            parentId: 1506,
            regionName: "乌审旗",
            level: 2,
          },
          {
            regionId: 150627,
            parentId: 1506,
            regionName: "伊金霍洛旗",
            level: 2,
          },
        ],
      },
      {
        regionId: 1507,
        parentId: 15,
        regionName: "呼伦贝尔市",
        level: 1,
        children: [
          {
            regionId: 150702,
            parentId: 1507,
            regionName: "海拉尔区",
            level: 2,
          },
          {
            regionId: 150703,
            parentId: 1507,
            regionName: "扎赉诺尔区",
            level: 2,
          },
          {
            regionId: 150721,
            parentId: 1507,
            regionName: "阿荣旗",
            level: 2,
          },
          {
            regionId: 150722,
            parentId: 1507,
            regionName: "莫力达瓦达斡尔族自治旗",
            level: 2,
          },
          {
            regionId: 150723,
            parentId: 1507,
            regionName: "鄂伦春自治旗",
            level: 2,
          },
          {
            regionId: 150724,
            parentId: 1507,
            regionName: "鄂温克族自治旗",
            level: 2,
          },
          {
            regionId: 150725,
            parentId: 1507,
            regionName: "陈巴尔虎旗",
            level: 2,
          },
          {
            regionId: 150726,
            parentId: 1507,
            regionName: "新巴尔虎左旗",
            level: 2,
          },
          {
            regionId: 150727,
            parentId: 1507,
            regionName: "新巴尔虎右旗",
            level: 2,
          },
          {
            regionId: 150781,
            parentId: 1507,
            regionName: "满洲里市",
            level: 2,
          },
          {
            regionId: 150782,
            parentId: 1507,
            regionName: "牙克石市",
            level: 2,
          },
          {
            regionId: 150783,
            parentId: 1507,
            regionName: "扎兰屯市",
            level: 2,
          },
          {
            regionId: 150784,
            parentId: 1507,
            regionName: "额尔古纳市",
            level: 2,
          },
          {
            regionId: 150785,
            parentId: 1507,
            regionName: "根河市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1508,
        parentId: 15,
        regionName: "巴彦淖尔市",
        level: 1,
        children: [
          {
            regionId: 150802,
            parentId: 1508,
            regionName: "临河区",
            level: 2,
          },
          {
            regionId: 150821,
            parentId: 1508,
            regionName: "五原县",
            level: 2,
          },
          {
            regionId: 150822,
            parentId: 1508,
            regionName: "磴口县",
            level: 2,
          },
          {
            regionId: 150823,
            parentId: 1508,
            regionName: "乌拉特前旗",
            level: 2,
          },
          {
            regionId: 150824,
            parentId: 1508,
            regionName: "乌拉特中旗",
            level: 2,
          },
          {
            regionId: 150825,
            parentId: 1508,
            regionName: "乌拉特后旗",
            level: 2,
          },
          {
            regionId: 150826,
            parentId: 1508,
            regionName: "杭锦后旗",
            level: 2,
          },
        ],
      },
      {
        regionId: 1509,
        parentId: 15,
        regionName: "乌兰察布市",
        level: 1,
        children: [
          {
            regionId: 150902,
            parentId: 1509,
            regionName: "集宁区",
            level: 2,
          },
          {
            regionId: 150921,
            parentId: 1509,
            regionName: "卓资县",
            level: 2,
          },
          {
            regionId: 150922,
            parentId: 1509,
            regionName: "化德县",
            level: 2,
          },
          {
            regionId: 150923,
            parentId: 1509,
            regionName: "商都县",
            level: 2,
          },
          {
            regionId: 150924,
            parentId: 1509,
            regionName: "兴和县",
            level: 2,
          },
          {
            regionId: 150925,
            parentId: 1509,
            regionName: "凉城县",
            level: 2,
          },
          {
            regionId: 150926,
            parentId: 1509,
            regionName: "察哈尔右翼前旗",
            level: 2,
          },
          {
            regionId: 150927,
            parentId: 1509,
            regionName: "察哈尔右翼中旗",
            level: 2,
          },
          {
            regionId: 150928,
            parentId: 1509,
            regionName: "察哈尔右翼后旗",
            level: 2,
          },
          {
            regionId: 150929,
            parentId: 1509,
            regionName: "四子王旗",
            level: 2,
          },
          {
            regionId: 150981,
            parentId: 1509,
            regionName: "丰镇市",
            level: 2,
          },
        ],
      },
      {
        regionId: 1522,
        parentId: 15,
        regionName: "兴安盟",
        level: 1,
        children: [
          {
            regionId: 152201,
            parentId: 1522,
            regionName: "乌兰浩特市",
            level: 2,
          },
          {
            regionId: 152202,
            parentId: 1522,
            regionName: "阿尔山市",
            level: 2,
          },
          {
            regionId: 152221,
            parentId: 1522,
            regionName: "科尔沁右翼前旗",
            level: 2,
          },
          {
            regionId: 152222,
            parentId: 1522,
            regionName: "科尔沁右翼中旗",
            level: 2,
          },
          {
            regionId: 152223,
            parentId: 1522,
            regionName: "扎赉特旗",
            level: 2,
          },
          {
            regionId: 152224,
            parentId: 1522,
            regionName: "突泉县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1525,
        parentId: 15,
        regionName: "锡林郭勒盟",
        level: 1,
        children: [
          {
            regionId: 152501,
            parentId: 1525,
            regionName: "二连浩特市",
            level: 2,
          },
          {
            regionId: 152502,
            parentId: 1525,
            regionName: "锡林浩特市",
            level: 2,
          },
          {
            regionId: 152522,
            parentId: 1525,
            regionName: "阿巴嘎旗",
            level: 2,
          },
          {
            regionId: 152523,
            parentId: 1525,
            regionName: "苏尼特左旗",
            level: 2,
          },
          {
            regionId: 152524,
            parentId: 1525,
            regionName: "苏尼特右旗",
            level: 2,
          },
          {
            regionId: 152525,
            parentId: 1525,
            regionName: "东乌珠穆沁旗",
            level: 2,
          },
          {
            regionId: 152526,
            parentId: 1525,
            regionName: "西乌珠穆沁旗",
            level: 2,
          },
          {
            regionId: 152527,
            parentId: 1525,
            regionName: "太仆寺旗",
            level: 2,
          },
          {
            regionId: 152528,
            parentId: 1525,
            regionName: "镶黄旗",
            level: 2,
          },
          {
            regionId: 152529,
            parentId: 1525,
            regionName: "正镶白旗",
            level: 2,
          },
          {
            regionId: 152530,
            parentId: 1525,
            regionName: "正蓝旗",
            level: 2,
          },
          {
            regionId: 152531,
            parentId: 1525,
            regionName: "多伦县",
            level: 2,
          },
        ],
      },
      {
        regionId: 1529,
        parentId: 15,
        regionName: "阿拉善盟",
        level: 1,
        children: [
          {
            regionId: 152921,
            parentId: 1529,
            regionName: "阿拉善左旗",
            level: 2,
          },
          {
            regionId: 152922,
            parentId: 1529,
            regionName: "阿拉善右旗",
            level: 2,
          },
          {
            regionId: 152923,
            parentId: 1529,
            regionName: "额济纳旗",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 21,
    parentId: 0,
    regionName: "辽宁省",
    level: 0,
    children: [
      {
        regionId: 2101,
        parentId: 21,
        regionName: "沈阳市",
        level: 1,
        children: [
          {
            regionId: 210102,
            parentId: 2101,
            regionName: "和平区",
            level: 2,
          },
          {
            regionId: 210103,
            parentId: 2101,
            regionName: "沈河区",
            level: 2,
          },
          {
            regionId: 210104,
            parentId: 2101,
            regionName: "大东区",
            level: 2,
          },
          {
            regionId: 210105,
            parentId: 2101,
            regionName: "皇姑区",
            level: 2,
          },
          {
            regionId: 210106,
            parentId: 2101,
            regionName: "铁西区",
            level: 2,
          },
          {
            regionId: 210111,
            parentId: 2101,
            regionName: "苏家屯区",
            level: 2,
          },
          {
            regionId: 210112,
            parentId: 2101,
            regionName: "浑南区",
            level: 2,
          },
          {
            regionId: 210113,
            parentId: 2101,
            regionName: "沈北新区",
            level: 2,
          },
          {
            regionId: 210114,
            parentId: 2101,
            regionName: "于洪区",
            level: 2,
          },
          {
            regionId: 210115,
            parentId: 2101,
            regionName: "辽中区",
            level: 2,
          },
          {
            regionId: 210123,
            parentId: 2101,
            regionName: "康平县",
            level: 2,
          },
          {
            regionId: 210124,
            parentId: 2101,
            regionName: "法库县",
            level: 2,
          },
          {
            regionId: 210181,
            parentId: 2101,
            regionName: "新民市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2102,
        parentId: 21,
        regionName: "大连市",
        level: 1,
        children: [
          {
            regionId: 210202,
            parentId: 2102,
            regionName: "中山区",
            level: 2,
          },
          {
            regionId: 210203,
            parentId: 2102,
            regionName: "西岗区",
            level: 2,
          },
          {
            regionId: 210204,
            parentId: 2102,
            regionName: "沙河口区",
            level: 2,
          },
          {
            regionId: 210211,
            parentId: 2102,
            regionName: "甘井子区",
            level: 2,
          },
          {
            regionId: 210212,
            parentId: 2102,
            regionName: "旅顺口区",
            level: 2,
          },
          {
            regionId: 210213,
            parentId: 2102,
            regionName: "金州区",
            level: 2,
          },
          {
            regionId: 210214,
            parentId: 2102,
            regionName: "普兰店区",
            level: 2,
          },
          {
            regionId: 210224,
            parentId: 2102,
            regionName: "长海县",
            level: 2,
          },
          {
            regionId: 210281,
            parentId: 2102,
            regionName: "瓦房店市",
            level: 2,
          },
          {
            regionId: 210283,
            parentId: 2102,
            regionName: "庄河市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2103,
        parentId: 21,
        regionName: "鞍山市",
        level: 1,
        children: [
          {
            regionId: 210302,
            parentId: 2103,
            regionName: "铁东区",
            level: 2,
          },
          {
            regionId: 210303,
            parentId: 2103,
            regionName: "铁西区",
            level: 2,
          },
          {
            regionId: 210304,
            parentId: 2103,
            regionName: "立山区",
            level: 2,
          },
          {
            regionId: 210311,
            parentId: 2103,
            regionName: "千山区",
            level: 2,
          },
          {
            regionId: 210321,
            parentId: 2103,
            regionName: "台安县",
            level: 2,
          },
          {
            regionId: 210323,
            parentId: 2103,
            regionName: "岫岩满族自治县",
            level: 2,
          },
          {
            regionId: 210381,
            parentId: 2103,
            regionName: "海城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2104,
        parentId: 21,
        regionName: "抚顺市",
        level: 1,
        children: [
          {
            regionId: 210402,
            parentId: 2104,
            regionName: "新抚区",
            level: 2,
          },
          {
            regionId: 210403,
            parentId: 2104,
            regionName: "东洲区",
            level: 2,
          },
          {
            regionId: 210404,
            parentId: 2104,
            regionName: "望花区",
            level: 2,
          },
          {
            regionId: 210411,
            parentId: 2104,
            regionName: "顺城区",
            level: 2,
          },
          {
            regionId: 210421,
            parentId: 2104,
            regionName: "抚顺县",
            level: 2,
          },
          {
            regionId: 210422,
            parentId: 2104,
            regionName: "新宾满族自治县",
            level: 2,
          },
          {
            regionId: 210423,
            parentId: 2104,
            regionName: "清原满族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2105,
        parentId: 21,
        regionName: "本溪市",
        level: 1,
        children: [
          {
            regionId: 210502,
            parentId: 2105,
            regionName: "平山区",
            level: 2,
          },
          {
            regionId: 210503,
            parentId: 2105,
            regionName: "溪湖区",
            level: 2,
          },
          {
            regionId: 210504,
            parentId: 2105,
            regionName: "明山区",
            level: 2,
          },
          {
            regionId: 210505,
            parentId: 2105,
            regionName: "南芬区",
            level: 2,
          },
          {
            regionId: 210521,
            parentId: 2105,
            regionName: "本溪满族自治县",
            level: 2,
          },
          {
            regionId: 210522,
            parentId: 2105,
            regionName: "桓仁满族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2106,
        parentId: 21,
        regionName: "丹东市",
        level: 1,
        children: [
          {
            regionId: 210602,
            parentId: 2106,
            regionName: "元宝区",
            level: 2,
          },
          {
            regionId: 210603,
            parentId: 2106,
            regionName: "振兴区",
            level: 2,
          },
          {
            regionId: 210604,
            parentId: 2106,
            regionName: "振安区",
            level: 2,
          },
          {
            regionId: 210624,
            parentId: 2106,
            regionName: "宽甸满族自治县",
            level: 2,
          },
          {
            regionId: 210681,
            parentId: 2106,
            regionName: "东港市",
            level: 2,
          },
          {
            regionId: 210682,
            parentId: 2106,
            regionName: "凤城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2107,
        parentId: 21,
        regionName: "锦州市",
        level: 1,
        children: [
          {
            regionId: 210702,
            parentId: 2107,
            regionName: "古塔区",
            level: 2,
          },
          {
            regionId: 210703,
            parentId: 2107,
            regionName: "凌河区",
            level: 2,
          },
          {
            regionId: 210711,
            parentId: 2107,
            regionName: "太和区",
            level: 2,
          },
          {
            regionId: 210726,
            parentId: 2107,
            regionName: "黑山县",
            level: 2,
          },
          {
            regionId: 210727,
            parentId: 2107,
            regionName: "义县",
            level: 2,
          },
          {
            regionId: 210781,
            parentId: 2107,
            regionName: "凌海市",
            level: 2,
          },
          {
            regionId: 210782,
            parentId: 2107,
            regionName: "北镇市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2108,
        parentId: 21,
        regionName: "营口市",
        level: 1,
        children: [
          {
            regionId: 210802,
            parentId: 2108,
            regionName: "站前区",
            level: 2,
          },
          {
            regionId: 210803,
            parentId: 2108,
            regionName: "西市区",
            level: 2,
          },
          {
            regionId: 210804,
            parentId: 2108,
            regionName: "鲅鱼圈区",
            level: 2,
          },
          {
            regionId: 210811,
            parentId: 2108,
            regionName: "老边区",
            level: 2,
          },
          {
            regionId: 210881,
            parentId: 2108,
            regionName: "盖州市",
            level: 2,
          },
          {
            regionId: 210882,
            parentId: 2108,
            regionName: "大石桥市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2109,
        parentId: 21,
        regionName: "阜新市",
        level: 1,
        children: [
          {
            regionId: 210902,
            parentId: 2109,
            regionName: "海州区",
            level: 2,
          },
          {
            regionId: 210903,
            parentId: 2109,
            regionName: "新邱区",
            level: 2,
          },
          {
            regionId: 210904,
            parentId: 2109,
            regionName: "太平区",
            level: 2,
          },
          {
            regionId: 210905,
            parentId: 2109,
            regionName: "清河门区",
            level: 2,
          },
          {
            regionId: 210911,
            parentId: 2109,
            regionName: "细河区",
            level: 2,
          },
          {
            regionId: 210921,
            parentId: 2109,
            regionName: "阜新蒙古族自治县",
            level: 2,
          },
          {
            regionId: 210922,
            parentId: 2109,
            regionName: "彰武县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2110,
        parentId: 21,
        regionName: "辽阳市",
        level: 1,
        children: [
          {
            regionId: 211002,
            parentId: 2110,
            regionName: "白塔区",
            level: 2,
          },
          {
            regionId: 211003,
            parentId: 2110,
            regionName: "文圣区",
            level: 2,
          },
          {
            regionId: 211004,
            parentId: 2110,
            regionName: "宏伟区",
            level: 2,
          },
          {
            regionId: 211005,
            parentId: 2110,
            regionName: "弓长岭区",
            level: 2,
          },
          {
            regionId: 211011,
            parentId: 2110,
            regionName: "太子河区",
            level: 2,
          },
          {
            regionId: 211021,
            parentId: 2110,
            regionName: "辽阳县",
            level: 2,
          },
          {
            regionId: 211081,
            parentId: 2110,
            regionName: "灯塔市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2111,
        parentId: 21,
        regionName: "盘锦市",
        level: 1,
        children: [
          {
            regionId: 211102,
            parentId: 2111,
            regionName: "双台子区",
            level: 2,
          },
          {
            regionId: 211103,
            parentId: 2111,
            regionName: "兴隆台区",
            level: 2,
          },
          {
            regionId: 211104,
            parentId: 2111,
            regionName: "大洼区",
            level: 2,
          },
          {
            regionId: 211122,
            parentId: 2111,
            regionName: "盘山县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2112,
        parentId: 21,
        regionName: "铁岭市",
        level: 1,
        children: [
          {
            regionId: 211202,
            parentId: 2112,
            regionName: "银州区",
            level: 2,
          },
          {
            regionId: 211204,
            parentId: 2112,
            regionName: "清河区",
            level: 2,
          },
          {
            regionId: 211221,
            parentId: 2112,
            regionName: "铁岭县",
            level: 2,
          },
          {
            regionId: 211223,
            parentId: 2112,
            regionName: "西丰县",
            level: 2,
          },
          {
            regionId: 211224,
            parentId: 2112,
            regionName: "昌图县",
            level: 2,
          },
          {
            regionId: 211281,
            parentId: 2112,
            regionName: "调兵山市",
            level: 2,
          },
          {
            regionId: 211282,
            parentId: 2112,
            regionName: "开原市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2113,
        parentId: 21,
        regionName: "朝阳市",
        level: 1,
        children: [
          {
            regionId: 211302,
            parentId: 2113,
            regionName: "双塔区",
            level: 2,
          },
          {
            regionId: 211303,
            parentId: 2113,
            regionName: "龙城区",
            level: 2,
          },
          {
            regionId: 211321,
            parentId: 2113,
            regionName: "朝阳县",
            level: 2,
          },
          {
            regionId: 211322,
            parentId: 2113,
            regionName: "建平县",
            level: 2,
          },
          {
            regionId: 211324,
            parentId: 2113,
            regionName: "喀喇沁左翼蒙古族自治县",
            level: 2,
          },
          {
            regionId: 211381,
            parentId: 2113,
            regionName: "北票市",
            level: 2,
          },
          {
            regionId: 211382,
            parentId: 2113,
            regionName: "凌源市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2114,
        parentId: 21,
        regionName: "葫芦岛市",
        level: 1,
        children: [
          {
            regionId: 211402,
            parentId: 2114,
            regionName: "连山区",
            level: 2,
          },
          {
            regionId: 211403,
            parentId: 2114,
            regionName: "龙港区",
            level: 2,
          },
          {
            regionId: 211404,
            parentId: 2114,
            regionName: "南票区",
            level: 2,
          },
          {
            regionId: 211421,
            parentId: 2114,
            regionName: "绥中县",
            level: 2,
          },
          {
            regionId: 211422,
            parentId: 2114,
            regionName: "建昌县",
            level: 2,
          },
          {
            regionId: 211481,
            parentId: 2114,
            regionName: "兴城市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 22,
    parentId: 0,
    regionName: "吉林省",
    level: 0,
    children: [
      {
        regionId: 2201,
        parentId: 22,
        regionName: "长春市",
        level: 1,
        children: [
          {
            regionId: 220102,
            parentId: 2201,
            regionName: "南关区",
            level: 2,
          },
          {
            regionId: 220103,
            parentId: 2201,
            regionName: "宽城区",
            level: 2,
          },
          {
            regionId: 220104,
            parentId: 2201,
            regionName: "朝阳区",
            level: 2,
          },
          {
            regionId: 220105,
            parentId: 2201,
            regionName: "二道区",
            level: 2,
          },
          {
            regionId: 220106,
            parentId: 2201,
            regionName: "绿园区",
            level: 2,
          },
          {
            regionId: 220112,
            parentId: 2201,
            regionName: "双阳区",
            level: 2,
          },
          {
            regionId: 220113,
            parentId: 2201,
            regionName: "九台区",
            level: 2,
          },
          {
            regionId: 220122,
            parentId: 2201,
            regionName: "农安县",
            level: 2,
          },
          {
            regionId: 220182,
            parentId: 2201,
            regionName: "榆树市",
            level: 2,
          },
          {
            regionId: 220183,
            parentId: 2201,
            regionName: "德惠市",
            level: 2,
          },
          {
            regionId: 220184,
            parentId: 2201,
            regionName: "公主岭市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2202,
        parentId: 22,
        regionName: "吉林市",
        level: 1,
        children: [
          {
            regionId: 220202,
            parentId: 2202,
            regionName: "昌邑区",
            level: 2,
          },
          {
            regionId: 220203,
            parentId: 2202,
            regionName: "龙潭区",
            level: 2,
          },
          {
            regionId: 220204,
            parentId: 2202,
            regionName: "船营区",
            level: 2,
          },
          {
            regionId: 220211,
            parentId: 2202,
            regionName: "丰满区",
            level: 2,
          },
          {
            regionId: 220221,
            parentId: 2202,
            regionName: "永吉县",
            level: 2,
          },
          {
            regionId: 220281,
            parentId: 2202,
            regionName: "蛟河市",
            level: 2,
          },
          {
            regionId: 220282,
            parentId: 2202,
            regionName: "桦甸市",
            level: 2,
          },
          {
            regionId: 220283,
            parentId: 2202,
            regionName: "舒兰市",
            level: 2,
          },
          {
            regionId: 220284,
            parentId: 2202,
            regionName: "磐石市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2203,
        parentId: 22,
        regionName: "四平市",
        level: 1,
        children: [
          {
            regionId: 220302,
            parentId: 2203,
            regionName: "铁西区",
            level: 2,
          },
          {
            regionId: 220303,
            parentId: 2203,
            regionName: "铁东区",
            level: 2,
          },
          {
            regionId: 220322,
            parentId: 2203,
            regionName: "梨树县",
            level: 2,
          },
          {
            regionId: 220323,
            parentId: 2203,
            regionName: "伊通满族自治县",
            level: 2,
          },
          {
            regionId: 220382,
            parentId: 2203,
            regionName: "双辽市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2204,
        parentId: 22,
        regionName: "辽源市",
        level: 1,
        children: [
          {
            regionId: 220402,
            parentId: 2204,
            regionName: "龙山区",
            level: 2,
          },
          {
            regionId: 220403,
            parentId: 2204,
            regionName: "西安区",
            level: 2,
          },
          {
            regionId: 220421,
            parentId: 2204,
            regionName: "东丰县",
            level: 2,
          },
          {
            regionId: 220422,
            parentId: 2204,
            regionName: "东辽县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2205,
        parentId: 22,
        regionName: "通化市",
        level: 1,
        children: [
          {
            regionId: 220502,
            parentId: 2205,
            regionName: "东昌区",
            level: 2,
          },
          {
            regionId: 220503,
            parentId: 2205,
            regionName: "二道江区",
            level: 2,
          },
          {
            regionId: 220521,
            parentId: 2205,
            regionName: "通化县",
            level: 2,
          },
          {
            regionId: 220523,
            parentId: 2205,
            regionName: "辉南县",
            level: 2,
          },
          {
            regionId: 220524,
            parentId: 2205,
            regionName: "柳河县",
            level: 2,
          },
          {
            regionId: 220581,
            parentId: 2205,
            regionName: "梅河口市",
            level: 2,
          },
          {
            regionId: 220582,
            parentId: 2205,
            regionName: "集安市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2206,
        parentId: 22,
        regionName: "白山市",
        level: 1,
        children: [
          {
            regionId: 220602,
            parentId: 2206,
            regionName: "浑江区",
            level: 2,
          },
          {
            regionId: 220605,
            parentId: 2206,
            regionName: "江源区",
            level: 2,
          },
          {
            regionId: 220621,
            parentId: 2206,
            regionName: "抚松县",
            level: 2,
          },
          {
            regionId: 220622,
            parentId: 2206,
            regionName: "靖宇县",
            level: 2,
          },
          {
            regionId: 220623,
            parentId: 2206,
            regionName: "长白朝鲜族自治县",
            level: 2,
          },
          {
            regionId: 220681,
            parentId: 2206,
            regionName: "临江市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2207,
        parentId: 22,
        regionName: "松原市",
        level: 1,
        children: [
          {
            regionId: 220702,
            parentId: 2207,
            regionName: "宁江区",
            level: 2,
          },
          {
            regionId: 220721,
            parentId: 2207,
            regionName: "前郭尔罗斯蒙古族自治县",
            level: 2,
          },
          {
            regionId: 220722,
            parentId: 2207,
            regionName: "长岭县",
            level: 2,
          },
          {
            regionId: 220723,
            parentId: 2207,
            regionName: "乾安县",
            level: 2,
          },
          {
            regionId: 220781,
            parentId: 2207,
            regionName: "扶余市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2208,
        parentId: 22,
        regionName: "白城市",
        level: 1,
        children: [
          {
            regionId: 220802,
            parentId: 2208,
            regionName: "洮北区",
            level: 2,
          },
          {
            regionId: 220821,
            parentId: 2208,
            regionName: "镇赉县",
            level: 2,
          },
          {
            regionId: 220822,
            parentId: 2208,
            regionName: "通榆县",
            level: 2,
          },
          {
            regionId: 220881,
            parentId: 2208,
            regionName: "洮南市",
            level: 2,
          },
          {
            regionId: 220882,
            parentId: 2208,
            regionName: "大安市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2224,
        parentId: 22,
        regionName: "延边朝鲜族自治州",
        level: 1,
        children: [
          {
            regionId: 222401,
            parentId: 2224,
            regionName: "延吉市",
            level: 2,
          },
          {
            regionId: 222402,
            parentId: 2224,
            regionName: "图们市",
            level: 2,
          },
          {
            regionId: 222403,
            parentId: 2224,
            regionName: "敦化市",
            level: 2,
          },
          {
            regionId: 222404,
            parentId: 2224,
            regionName: "珲春市",
            level: 2,
          },
          {
            regionId: 222405,
            parentId: 2224,
            regionName: "龙井市",
            level: 2,
          },
          {
            regionId: 222406,
            parentId: 2224,
            regionName: "和龙市",
            level: 2,
          },
          {
            regionId: 222424,
            parentId: 2224,
            regionName: "汪清县",
            level: 2,
          },
          {
            regionId: 222426,
            parentId: 2224,
            regionName: "安图县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 23,
    parentId: 0,
    regionName: "黑龙江省",
    level: 0,
    children: [
      {
        regionId: 2301,
        parentId: 23,
        regionName: "哈尔滨市",
        level: 1,
        children: [
          {
            regionId: 230102,
            parentId: 2301,
            regionName: "道里区",
            level: 2,
          },
          {
            regionId: 230103,
            parentId: 2301,
            regionName: "南岗区",
            level: 2,
          },
          {
            regionId: 230104,
            parentId: 2301,
            regionName: "道外区",
            level: 2,
          },
          {
            regionId: 230108,
            parentId: 2301,
            regionName: "平房区",
            level: 2,
          },
          {
            regionId: 230109,
            parentId: 2301,
            regionName: "松北区",
            level: 2,
          },
          {
            regionId: 230110,
            parentId: 2301,
            regionName: "香坊区",
            level: 2,
          },
          {
            regionId: 230111,
            parentId: 2301,
            regionName: "呼兰区",
            level: 2,
          },
          {
            regionId: 230112,
            parentId: 2301,
            regionName: "阿城区",
            level: 2,
          },
          {
            regionId: 230113,
            parentId: 2301,
            regionName: "双城区",
            level: 2,
          },
          {
            regionId: 230123,
            parentId: 2301,
            regionName: "依兰县",
            level: 2,
          },
          {
            regionId: 230124,
            parentId: 2301,
            regionName: "方正县",
            level: 2,
          },
          {
            regionId: 230125,
            parentId: 2301,
            regionName: "宾县",
            level: 2,
          },
          {
            regionId: 230126,
            parentId: 2301,
            regionName: "巴彦县",
            level: 2,
          },
          {
            regionId: 230127,
            parentId: 2301,
            regionName: "木兰县",
            level: 2,
          },
          {
            regionId: 230128,
            parentId: 2301,
            regionName: "通河县",
            level: 2,
          },
          {
            regionId: 230129,
            parentId: 2301,
            regionName: "延寿县",
            level: 2,
          },
          {
            regionId: 230183,
            parentId: 2301,
            regionName: "尚志市",
            level: 2,
          },
          {
            regionId: 230184,
            parentId: 2301,
            regionName: "五常市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2302,
        parentId: 23,
        regionName: "齐齐哈尔市",
        level: 1,
        children: [
          {
            regionId: 230202,
            parentId: 2302,
            regionName: "龙沙区",
            level: 2,
          },
          {
            regionId: 230203,
            parentId: 2302,
            regionName: "建华区",
            level: 2,
          },
          {
            regionId: 230204,
            parentId: 2302,
            regionName: "铁锋区",
            level: 2,
          },
          {
            regionId: 230205,
            parentId: 2302,
            regionName: "昂昂溪区",
            level: 2,
          },
          {
            regionId: 230206,
            parentId: 2302,
            regionName: "富拉尔基区",
            level: 2,
          },
          {
            regionId: 230207,
            parentId: 2302,
            regionName: "碾子山区",
            level: 2,
          },
          {
            regionId: 230208,
            parentId: 2302,
            regionName: "梅里斯达斡尔族区",
            level: 2,
          },
          {
            regionId: 230221,
            parentId: 2302,
            regionName: "龙江县",
            level: 2,
          },
          {
            regionId: 230223,
            parentId: 2302,
            regionName: "依安县",
            level: 2,
          },
          {
            regionId: 230224,
            parentId: 2302,
            regionName: "泰来县",
            level: 2,
          },
          {
            regionId: 230225,
            parentId: 2302,
            regionName: "甘南县",
            level: 2,
          },
          {
            regionId: 230227,
            parentId: 2302,
            regionName: "富裕县",
            level: 2,
          },
          {
            regionId: 230229,
            parentId: 2302,
            regionName: "克山县",
            level: 2,
          },
          {
            regionId: 230230,
            parentId: 2302,
            regionName: "克东县",
            level: 2,
          },
          {
            regionId: 230231,
            parentId: 2302,
            regionName: "拜泉县",
            level: 2,
          },
          {
            regionId: 230281,
            parentId: 2302,
            regionName: "讷河市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2303,
        parentId: 23,
        regionName: "鸡西市",
        level: 1,
        children: [
          {
            regionId: 230302,
            parentId: 2303,
            regionName: "鸡冠区",
            level: 2,
          },
          {
            regionId: 230303,
            parentId: 2303,
            regionName: "恒山区",
            level: 2,
          },
          {
            regionId: 230304,
            parentId: 2303,
            regionName: "滴道区",
            level: 2,
          },
          {
            regionId: 230305,
            parentId: 2303,
            regionName: "梨树区",
            level: 2,
          },
          {
            regionId: 230306,
            parentId: 2303,
            regionName: "城子河区",
            level: 2,
          },
          {
            regionId: 230307,
            parentId: 2303,
            regionName: "麻山区",
            level: 2,
          },
          {
            regionId: 230321,
            parentId: 2303,
            regionName: "鸡东县",
            level: 2,
          },
          {
            regionId: 230381,
            parentId: 2303,
            regionName: "虎林市",
            level: 2,
          },
          {
            regionId: 230382,
            parentId: 2303,
            regionName: "密山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2304,
        parentId: 23,
        regionName: "鹤岗市",
        level: 1,
        children: [
          {
            regionId: 230402,
            parentId: 2304,
            regionName: "向阳区",
            level: 2,
          },
          {
            regionId: 230403,
            parentId: 2304,
            regionName: "工农区",
            level: 2,
          },
          {
            regionId: 230404,
            parentId: 2304,
            regionName: "南山区",
            level: 2,
          },
          {
            regionId: 230405,
            parentId: 2304,
            regionName: "兴安区",
            level: 2,
          },
          {
            regionId: 230406,
            parentId: 2304,
            regionName: "东山区",
            level: 2,
          },
          {
            regionId: 230407,
            parentId: 2304,
            regionName: "兴山区",
            level: 2,
          },
          {
            regionId: 230421,
            parentId: 2304,
            regionName: "萝北县",
            level: 2,
          },
          {
            regionId: 230422,
            parentId: 2304,
            regionName: "绥滨县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2305,
        parentId: 23,
        regionName: "双鸭山市",
        level: 1,
        children: [
          {
            regionId: 230502,
            parentId: 2305,
            regionName: "尖山区",
            level: 2,
          },
          {
            regionId: 230503,
            parentId: 2305,
            regionName: "岭东区",
            level: 2,
          },
          {
            regionId: 230505,
            parentId: 2305,
            regionName: "四方台区",
            level: 2,
          },
          {
            regionId: 230506,
            parentId: 2305,
            regionName: "宝山区",
            level: 2,
          },
          {
            regionId: 230521,
            parentId: 2305,
            regionName: "集贤县",
            level: 2,
          },
          {
            regionId: 230522,
            parentId: 2305,
            regionName: "友谊县",
            level: 2,
          },
          {
            regionId: 230523,
            parentId: 2305,
            regionName: "宝清县",
            level: 2,
          },
          {
            regionId: 230524,
            parentId: 2305,
            regionName: "饶河县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2306,
        parentId: 23,
        regionName: "大庆市",
        level: 1,
        children: [
          {
            regionId: 230602,
            parentId: 2306,
            regionName: "萨尔图区",
            level: 2,
          },
          {
            regionId: 230603,
            parentId: 2306,
            regionName: "龙凤区",
            level: 2,
          },
          {
            regionId: 230604,
            parentId: 2306,
            regionName: "让胡路区",
            level: 2,
          },
          {
            regionId: 230605,
            parentId: 2306,
            regionName: "红岗区",
            level: 2,
          },
          {
            regionId: 230606,
            parentId: 2306,
            regionName: "大同区",
            level: 2,
          },
          {
            regionId: 230621,
            parentId: 2306,
            regionName: "肇州县",
            level: 2,
          },
          {
            regionId: 230622,
            parentId: 2306,
            regionName: "肇源县",
            level: 2,
          },
          {
            regionId: 230623,
            parentId: 2306,
            regionName: "林甸县",
            level: 2,
          },
          {
            regionId: 230624,
            parentId: 2306,
            regionName: "杜尔伯特蒙古族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2307,
        parentId: 23,
        regionName: "伊春市",
        level: 1,
        children: [
          {
            regionId: 230717,
            parentId: 2307,
            regionName: "伊美区",
            level: 2,
          },
          {
            regionId: 230718,
            parentId: 2307,
            regionName: "乌翠区",
            level: 2,
          },
          {
            regionId: 230719,
            parentId: 2307,
            regionName: "友好区",
            level: 2,
          },
          {
            regionId: 230722,
            parentId: 2307,
            regionName: "嘉荫县",
            level: 2,
          },
          {
            regionId: 230723,
            parentId: 2307,
            regionName: "汤旺县",
            level: 2,
          },
          {
            regionId: 230724,
            parentId: 2307,
            regionName: "丰林县",
            level: 2,
          },
          {
            regionId: 230725,
            parentId: 2307,
            regionName: "大箐山县",
            level: 2,
          },
          {
            regionId: 230726,
            parentId: 2307,
            regionName: "南岔县",
            level: 2,
          },
          {
            regionId: 230751,
            parentId: 2307,
            regionName: "金林区",
            level: 2,
          },
          {
            regionId: 230781,
            parentId: 2307,
            regionName: "铁力市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2308,
        parentId: 23,
        regionName: "佳木斯市",
        level: 1,
        children: [
          {
            regionId: 230803,
            parentId: 2308,
            regionName: "向阳区",
            level: 2,
          },
          {
            regionId: 230804,
            parentId: 2308,
            regionName: "前进区",
            level: 2,
          },
          {
            regionId: 230805,
            parentId: 2308,
            regionName: "东风区",
            level: 2,
          },
          {
            regionId: 230811,
            parentId: 2308,
            regionName: "郊区",
            level: 2,
          },
          {
            regionId: 230822,
            parentId: 2308,
            regionName: "桦南县",
            level: 2,
          },
          {
            regionId: 230826,
            parentId: 2308,
            regionName: "桦川县",
            level: 2,
          },
          {
            regionId: 230828,
            parentId: 2308,
            regionName: "汤原县",
            level: 2,
          },
          {
            regionId: 230881,
            parentId: 2308,
            regionName: "同江市",
            level: 2,
          },
          {
            regionId: 230882,
            parentId: 2308,
            regionName: "富锦市",
            level: 2,
          },
          {
            regionId: 230883,
            parentId: 2308,
            regionName: "抚远市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2309,
        parentId: 23,
        regionName: "七台河市",
        level: 1,
        children: [
          {
            regionId: 230902,
            parentId: 2309,
            regionName: "新兴区",
            level: 2,
          },
          {
            regionId: 230903,
            parentId: 2309,
            regionName: "桃山区",
            level: 2,
          },
          {
            regionId: 230904,
            parentId: 2309,
            regionName: "茄子河区",
            level: 2,
          },
          {
            regionId: 230921,
            parentId: 2309,
            regionName: "勃利县",
            level: 2,
          },
        ],
      },
      {
        regionId: 2310,
        parentId: 23,
        regionName: "牡丹江市",
        level: 1,
        children: [
          {
            regionId: 231002,
            parentId: 2310,
            regionName: "东安区",
            level: 2,
          },
          {
            regionId: 231003,
            parentId: 2310,
            regionName: "阳明区",
            level: 2,
          },
          {
            regionId: 231004,
            parentId: 2310,
            regionName: "爱民区",
            level: 2,
          },
          {
            regionId: 231005,
            parentId: 2310,
            regionName: "西安区",
            level: 2,
          },
          {
            regionId: 231025,
            parentId: 2310,
            regionName: "林口县",
            level: 2,
          },
          {
            regionId: 231081,
            parentId: 2310,
            regionName: "绥芬河市",
            level: 2,
          },
          {
            regionId: 231083,
            parentId: 2310,
            regionName: "海林市",
            level: 2,
          },
          {
            regionId: 231084,
            parentId: 2310,
            regionName: "宁安市",
            level: 2,
          },
          {
            regionId: 231085,
            parentId: 2310,
            regionName: "穆棱市",
            level: 2,
          },
          {
            regionId: 231086,
            parentId: 2310,
            regionName: "东宁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2311,
        parentId: 23,
        regionName: "黑河市",
        level: 1,
        children: [
          {
            regionId: 231102,
            parentId: 2311,
            regionName: "爱辉区",
            level: 2,
          },
          {
            regionId: 231123,
            parentId: 2311,
            regionName: "逊克县",
            level: 2,
          },
          {
            regionId: 231124,
            parentId: 2311,
            regionName: "孙吴县",
            level: 2,
          },
          {
            regionId: 231181,
            parentId: 2311,
            regionName: "北安市",
            level: 2,
          },
          {
            regionId: 231182,
            parentId: 2311,
            regionName: "五大连池市",
            level: 2,
          },
          {
            regionId: 231183,
            parentId: 2311,
            regionName: "嫩江市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2312,
        parentId: 23,
        regionName: "绥化市",
        level: 1,
        children: [
          {
            regionId: 231202,
            parentId: 2312,
            regionName: "北林区",
            level: 2,
          },
          {
            regionId: 231221,
            parentId: 2312,
            regionName: "望奎县",
            level: 2,
          },
          {
            regionId: 231222,
            parentId: 2312,
            regionName: "兰西县",
            level: 2,
          },
          {
            regionId: 231223,
            parentId: 2312,
            regionName: "青冈县",
            level: 2,
          },
          {
            regionId: 231224,
            parentId: 2312,
            regionName: "庆安县",
            level: 2,
          },
          {
            regionId: 231225,
            parentId: 2312,
            regionName: "明水县",
            level: 2,
          },
          {
            regionId: 231226,
            parentId: 2312,
            regionName: "绥棱县",
            level: 2,
          },
          {
            regionId: 231281,
            parentId: 2312,
            regionName: "安达市",
            level: 2,
          },
          {
            regionId: 231282,
            parentId: 2312,
            regionName: "肇东市",
            level: 2,
          },
          {
            regionId: 231283,
            parentId: 2312,
            regionName: "海伦市",
            level: 2,
          },
        ],
      },
      {
        regionId: 2327,
        parentId: 23,
        regionName: "大兴安岭地区",
        level: 1,
        children: [
          {
            regionId: 232701,
            parentId: 2327,
            regionName: "漠河市",
            level: 2,
          },
          {
            regionId: 232718,
            parentId: 2327,
            regionName: "加格达奇区",
            level: 2,
          },
          {
            regionId: 232721,
            parentId: 2327,
            regionName: "呼玛县",
            level: 2,
          },
          {
            regionId: 232722,
            parentId: 2327,
            regionName: "塔河县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 31,
    parentId: 0,
    regionName: "上海市",
    level: 0,
    children: [
      {
        regionId: 3101,
        parentId: 31,
        regionName: "上海市",
        level: 1,
        children: [
          {
            regionId: 310101,
            parentId: 3101,
            regionName: "黄浦区",
            level: 2,
          },
          {
            regionId: 310104,
            parentId: 3101,
            regionName: "徐汇区",
            level: 2,
          },
          {
            regionId: 310105,
            parentId: 3101,
            regionName: "长宁区",
            level: 2,
          },
          {
            regionId: 310106,
            parentId: 3101,
            regionName: "静安区",
            level: 2,
          },
          {
            regionId: 310107,
            parentId: 3101,
            regionName: "普陀区",
            level: 2,
          },
          {
            regionId: 310109,
            parentId: 3101,
            regionName: "虹口区",
            level: 2,
          },
          {
            regionId: 310110,
            parentId: 3101,
            regionName: "杨浦区",
            level: 2,
          },
          {
            regionId: 310112,
            parentId: 3101,
            regionName: "闵行区",
            level: 2,
          },
          {
            regionId: 310113,
            parentId: 3101,
            regionName: "宝山区",
            level: 2,
          },
          {
            regionId: 310114,
            parentId: 3101,
            regionName: "嘉定区",
            level: 2,
          },
          {
            regionId: 310115,
            parentId: 3101,
            regionName: "浦东新区",
            level: 2,
          },
          {
            regionId: 310116,
            parentId: 3101,
            regionName: "金山区",
            level: 2,
          },
          {
            regionId: 310117,
            parentId: 3101,
            regionName: "松江区",
            level: 2,
          },
          {
            regionId: 310118,
            parentId: 3101,
            regionName: "青浦区",
            level: 2,
          },
          {
            regionId: 310120,
            parentId: 3101,
            regionName: "奉贤区",
            level: 2,
          },
          {
            regionId: 310151,
            parentId: 3101,
            regionName: "崇明区",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 32,
    parentId: 0,
    regionName: "江苏省",
    level: 0,
    children: [
      {
        regionId: 3201,
        parentId: 32,
        regionName: "南京市",
        level: 1,
        children: [
          {
            regionId: 320102,
            parentId: 3201,
            regionName: "玄武区",
            level: 2,
          },
          {
            regionId: 320104,
            parentId: 3201,
            regionName: "秦淮区",
            level: 2,
          },
          {
            regionId: 320105,
            parentId: 3201,
            regionName: "建邺区",
            level: 2,
          },
          {
            regionId: 320106,
            parentId: 3201,
            regionName: "鼓楼区",
            level: 2,
          },
          {
            regionId: 320111,
            parentId: 3201,
            regionName: "浦口区",
            level: 2,
          },
          {
            regionId: 320113,
            parentId: 3201,
            regionName: "栖霞区",
            level: 2,
          },
          {
            regionId: 320114,
            parentId: 3201,
            regionName: "雨花台区",
            level: 2,
          },
          {
            regionId: 320115,
            parentId: 3201,
            regionName: "江宁区",
            level: 2,
          },
          {
            regionId: 320116,
            parentId: 3201,
            regionName: "六合区",
            level: 2,
          },
          {
            regionId: 320117,
            parentId: 3201,
            regionName: "溧水区",
            level: 2,
          },
          {
            regionId: 320118,
            parentId: 3201,
            regionName: "高淳区",
            level: 2,
          },
        ],
      },
      {
        regionId: 3202,
        parentId: 32,
        regionName: "无锡市",
        level: 1,
        children: [
          {
            regionId: 320205,
            parentId: 3202,
            regionName: "锡山区",
            level: 2,
          },
          {
            regionId: 320206,
            parentId: 3202,
            regionName: "惠山区",
            level: 2,
          },
          {
            regionId: 320211,
            parentId: 3202,
            regionName: "滨湖区",
            level: 2,
          },
          {
            regionId: 320213,
            parentId: 3202,
            regionName: "梁溪区",
            level: 2,
          },
          {
            regionId: 320214,
            parentId: 3202,
            regionName: "新吴区",
            level: 2,
          },
          {
            regionId: 320281,
            parentId: 3202,
            regionName: "江阴市",
            level: 2,
          },
          {
            regionId: 320282,
            parentId: 3202,
            regionName: "宜兴市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3203,
        parentId: 32,
        regionName: "徐州市",
        level: 1,
        children: [
          {
            regionId: 320302,
            parentId: 3203,
            regionName: "鼓楼区",
            level: 2,
          },
          {
            regionId: 320303,
            parentId: 3203,
            regionName: "云龙区",
            level: 2,
          },
          {
            regionId: 320305,
            parentId: 3203,
            regionName: "贾汪区",
            level: 2,
          },
          {
            regionId: 320311,
            parentId: 3203,
            regionName: "泉山区",
            level: 2,
          },
          {
            regionId: 320312,
            parentId: 3203,
            regionName: "铜山区",
            level: 2,
          },
          {
            regionId: 320321,
            parentId: 3203,
            regionName: "丰县",
            level: 2,
          },
          {
            regionId: 320322,
            parentId: 3203,
            regionName: "沛县",
            level: 2,
          },
          {
            regionId: 320324,
            parentId: 3203,
            regionName: "睢宁县",
            level: 2,
          },
          {
            regionId: 320381,
            parentId: 3203,
            regionName: "新沂市",
            level: 2,
          },
          {
            regionId: 320382,
            parentId: 3203,
            regionName: "邳州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3204,
        parentId: 32,
        regionName: "常州市",
        level: 1,
        children: [
          {
            regionId: 320402,
            parentId: 3204,
            regionName: "天宁区",
            level: 2,
          },
          {
            regionId: 320404,
            parentId: 3204,
            regionName: "钟楼区",
            level: 2,
          },
          {
            regionId: 320411,
            parentId: 3204,
            regionName: "新北区",
            level: 2,
          },
          {
            regionId: 320412,
            parentId: 3204,
            regionName: "武进区",
            level: 2,
          },
          {
            regionId: 320413,
            parentId: 3204,
            regionName: "金坛区",
            level: 2,
          },
          {
            regionId: 320481,
            parentId: 3204,
            regionName: "溧阳市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3205,
        parentId: 32,
        regionName: "苏州市",
        level: 1,
        children: [
          {
            regionId: 320505,
            parentId: 3205,
            regionName: "虎丘区",
            level: 2,
          },
          {
            regionId: 320506,
            parentId: 3205,
            regionName: "吴中区",
            level: 2,
          },
          {
            regionId: 320507,
            parentId: 3205,
            regionName: "相城区",
            level: 2,
          },
          {
            regionId: 320508,
            parentId: 3205,
            regionName: "姑苏区",
            level: 2,
          },
          {
            regionId: 320509,
            parentId: 3205,
            regionName: "吴江区",
            level: 2,
          },
          {
            regionId: 320581,
            parentId: 3205,
            regionName: "常熟市",
            level: 2,
          },
          {
            regionId: 320582,
            parentId: 3205,
            regionName: "张家港市",
            level: 2,
          },
          {
            regionId: 320583,
            parentId: 3205,
            regionName: "昆山市",
            level: 2,
          },
          {
            regionId: 320585,
            parentId: 3205,
            regionName: "太仓市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3206,
        parentId: 32,
        regionName: "南通市",
        level: 1,
        children: [
          {
            regionId: 320612,
            parentId: 3206,
            regionName: "通州区",
            level: 2,
          },
          {
            regionId: 320613,
            parentId: 3206,
            regionName: "崇川区",
            level: 2,
          },
          {
            regionId: 320614,
            parentId: 3206,
            regionName: "海门区",
            level: 2,
          },
          {
            regionId: 320623,
            parentId: 3206,
            regionName: "如东县",
            level: 2,
          },
          {
            regionId: 320681,
            parentId: 3206,
            regionName: "启东市",
            level: 2,
          },
          {
            regionId: 320682,
            parentId: 3206,
            regionName: "如皋市",
            level: 2,
          },
          {
            regionId: 320685,
            parentId: 3206,
            regionName: "海安市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3207,
        parentId: 32,
        regionName: "连云港市",
        level: 1,
        children: [
          {
            regionId: 320703,
            parentId: 3207,
            regionName: "连云区",
            level: 2,
          },
          {
            regionId: 320706,
            parentId: 3207,
            regionName: "海州区",
            level: 2,
          },
          {
            regionId: 320707,
            parentId: 3207,
            regionName: "赣榆区",
            level: 2,
          },
          {
            regionId: 320722,
            parentId: 3207,
            regionName: "东海县",
            level: 2,
          },
          {
            regionId: 320723,
            parentId: 3207,
            regionName: "灌云县",
            level: 2,
          },
          {
            regionId: 320724,
            parentId: 3207,
            regionName: "灌南县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3208,
        parentId: 32,
        regionName: "淮安市",
        level: 1,
        children: [
          {
            regionId: 320803,
            parentId: 3208,
            regionName: "淮安区",
            level: 2,
          },
          {
            regionId: 320804,
            parentId: 3208,
            regionName: "淮阴区",
            level: 2,
          },
          {
            regionId: 320812,
            parentId: 3208,
            regionName: "清江浦区",
            level: 2,
          },
          {
            regionId: 320813,
            parentId: 3208,
            regionName: "洪泽区",
            level: 2,
          },
          {
            regionId: 320826,
            parentId: 3208,
            regionName: "涟水县",
            level: 2,
          },
          {
            regionId: 320830,
            parentId: 3208,
            regionName: "盱眙县",
            level: 2,
          },
          {
            regionId: 320831,
            parentId: 3208,
            regionName: "金湖县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3209,
        parentId: 32,
        regionName: "盐城市",
        level: 1,
        children: [
          {
            regionId: 320902,
            parentId: 3209,
            regionName: "亭湖区",
            level: 2,
          },
          {
            regionId: 320903,
            parentId: 3209,
            regionName: "盐都区",
            level: 2,
          },
          {
            regionId: 320904,
            parentId: 3209,
            regionName: "大丰区",
            level: 2,
          },
          {
            regionId: 320921,
            parentId: 3209,
            regionName: "响水县",
            level: 2,
          },
          {
            regionId: 320922,
            parentId: 3209,
            regionName: "滨海县",
            level: 2,
          },
          {
            regionId: 320923,
            parentId: 3209,
            regionName: "阜宁县",
            level: 2,
          },
          {
            regionId: 320924,
            parentId: 3209,
            regionName: "射阳县",
            level: 2,
          },
          {
            regionId: 320925,
            parentId: 3209,
            regionName: "建湖县",
            level: 2,
          },
          {
            regionId: 320981,
            parentId: 3209,
            regionName: "东台市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3210,
        parentId: 32,
        regionName: "扬州市",
        level: 1,
        children: [
          {
            regionId: 321002,
            parentId: 3210,
            regionName: "广陵区",
            level: 2,
          },
          {
            regionId: 321003,
            parentId: 3210,
            regionName: "邗江区",
            level: 2,
          },
          {
            regionId: 321012,
            parentId: 3210,
            regionName: "江都区",
            level: 2,
          },
          {
            regionId: 321023,
            parentId: 3210,
            regionName: "宝应县",
            level: 2,
          },
          {
            regionId: 321081,
            parentId: 3210,
            regionName: "仪征市",
            level: 2,
          },
          {
            regionId: 321084,
            parentId: 3210,
            regionName: "高邮市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3211,
        parentId: 32,
        regionName: "镇江市",
        level: 1,
        children: [
          {
            regionId: 321102,
            parentId: 3211,
            regionName: "京口区",
            level: 2,
          },
          {
            regionId: 321111,
            parentId: 3211,
            regionName: "润州区",
            level: 2,
          },
          {
            regionId: 321112,
            parentId: 3211,
            regionName: "丹徒区",
            level: 2,
          },
          {
            regionId: 321181,
            parentId: 3211,
            regionName: "丹阳市",
            level: 2,
          },
          {
            regionId: 321182,
            parentId: 3211,
            regionName: "扬中市",
            level: 2,
          },
          {
            regionId: 321183,
            parentId: 3211,
            regionName: "句容市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3212,
        parentId: 32,
        regionName: "泰州市",
        level: 1,
        children: [
          {
            regionId: 321202,
            parentId: 3212,
            regionName: "海陵区",
            level: 2,
          },
          {
            regionId: 321203,
            parentId: 3212,
            regionName: "高港区",
            level: 2,
          },
          {
            regionId: 321204,
            parentId: 3212,
            regionName: "姜堰区",
            level: 2,
          },
          {
            regionId: 321281,
            parentId: 3212,
            regionName: "兴化市",
            level: 2,
          },
          {
            regionId: 321282,
            parentId: 3212,
            regionName: "靖江市",
            level: 2,
          },
          {
            regionId: 321283,
            parentId: 3212,
            regionName: "泰兴市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3213,
        parentId: 32,
        regionName: "宿迁市",
        level: 1,
        children: [
          {
            regionId: 321302,
            parentId: 3213,
            regionName: "宿城区",
            level: 2,
          },
          {
            regionId: 321311,
            parentId: 3213,
            regionName: "宿豫区",
            level: 2,
          },
          {
            regionId: 321322,
            parentId: 3213,
            regionName: "沭阳县",
            level: 2,
          },
          {
            regionId: 321323,
            parentId: 3213,
            regionName: "泗阳县",
            level: 2,
          },
          {
            regionId: 321324,
            parentId: 3213,
            regionName: "泗洪县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 33,
    parentId: 0,
    regionName: "浙江省",
    level: 0,
    children: [
      {
        regionId: 3301,
        parentId: 33,
        regionName: "杭州市",
        level: 1,
        children: [
          {
            regionId: 330102,
            parentId: 3301,
            regionName: "上城区",
            level: 2,
          },
          {
            regionId: 330105,
            parentId: 3301,
            regionName: "拱墅区",
            level: 2,
          },
          {
            regionId: 330106,
            parentId: 3301,
            regionName: "西湖区",
            level: 2,
          },
          {
            regionId: 330108,
            parentId: 3301,
            regionName: "滨江区",
            level: 2,
          },
          {
            regionId: 330109,
            parentId: 3301,
            regionName: "萧山区",
            level: 2,
          },
          {
            regionId: 330110,
            parentId: 3301,
            regionName: "余杭区",
            level: 2,
          },
          {
            regionId: 330111,
            parentId: 3301,
            regionName: "富阳区",
            level: 2,
          },
          {
            regionId: 330112,
            parentId: 3301,
            regionName: "临安区",
            level: 2,
          },
          {
            regionId: 330113,
            parentId: 3301,
            regionName: "临平区",
            level: 2,
          },
          {
            regionId: 330114,
            parentId: 3301,
            regionName: "钱塘区",
            level: 2,
          },
          {
            regionId: 330122,
            parentId: 3301,
            regionName: "桐庐县",
            level: 2,
          },
          {
            regionId: 330127,
            parentId: 3301,
            regionName: "淳安县",
            level: 2,
          },
          {
            regionId: 330182,
            parentId: 3301,
            regionName: "建德市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3302,
        parentId: 33,
        regionName: "宁波市",
        level: 1,
        children: [
          {
            regionId: 330203,
            parentId: 3302,
            regionName: "海曙区",
            level: 2,
          },
          {
            regionId: 330205,
            parentId: 3302,
            regionName: "江北区",
            level: 2,
          },
          {
            regionId: 330206,
            parentId: 3302,
            regionName: "北仑区",
            level: 2,
          },
          {
            regionId: 330211,
            parentId: 3302,
            regionName: "镇海区",
            level: 2,
          },
          {
            regionId: 330212,
            parentId: 3302,
            regionName: "鄞州区",
            level: 2,
          },
          {
            regionId: 330213,
            parentId: 3302,
            regionName: "奉化区",
            level: 2,
          },
          {
            regionId: 330225,
            parentId: 3302,
            regionName: "象山县",
            level: 2,
          },
          {
            regionId: 330226,
            parentId: 3302,
            regionName: "宁海县",
            level: 2,
          },
          {
            regionId: 330281,
            parentId: 3302,
            regionName: "余姚市",
            level: 2,
          },
          {
            regionId: 330282,
            parentId: 3302,
            regionName: "慈溪市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3303,
        parentId: 33,
        regionName: "温州市",
        level: 1,
        children: [
          {
            regionId: 330302,
            parentId: 3303,
            regionName: "鹿城区",
            level: 2,
          },
          {
            regionId: 330303,
            parentId: 3303,
            regionName: "龙湾区",
            level: 2,
          },
          {
            regionId: 330304,
            parentId: 3303,
            regionName: "瓯海区",
            level: 2,
          },
          {
            regionId: 330305,
            parentId: 3303,
            regionName: "洞头区",
            level: 2,
          },
          {
            regionId: 330324,
            parentId: 3303,
            regionName: "永嘉县",
            level: 2,
          },
          {
            regionId: 330326,
            parentId: 3303,
            regionName: "平阳县",
            level: 2,
          },
          {
            regionId: 330327,
            parentId: 3303,
            regionName: "苍南县",
            level: 2,
          },
          {
            regionId: 330328,
            parentId: 3303,
            regionName: "文成县",
            level: 2,
          },
          {
            regionId: 330329,
            parentId: 3303,
            regionName: "泰顺县",
            level: 2,
          },
          {
            regionId: 330381,
            parentId: 3303,
            regionName: "瑞安市",
            level: 2,
          },
          {
            regionId: 330382,
            parentId: 3303,
            regionName: "乐清市",
            level: 2,
          },
          {
            regionId: 330383,
            parentId: 3303,
            regionName: "龙港市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3304,
        parentId: 33,
        regionName: "嘉兴市",
        level: 1,
        children: [
          {
            regionId: 330402,
            parentId: 3304,
            regionName: "南湖区",
            level: 2,
          },
          {
            regionId: 330411,
            parentId: 3304,
            regionName: "秀洲区",
            level: 2,
          },
          {
            regionId: 330421,
            parentId: 3304,
            regionName: "嘉善县",
            level: 2,
          },
          {
            regionId: 330424,
            parentId: 3304,
            regionName: "海盐县",
            level: 2,
          },
          {
            regionId: 330481,
            parentId: 3304,
            regionName: "海宁市",
            level: 2,
          },
          {
            regionId: 330482,
            parentId: 3304,
            regionName: "平湖市",
            level: 2,
          },
          {
            regionId: 330483,
            parentId: 3304,
            regionName: "桐乡市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3305,
        parentId: 33,
        regionName: "湖州市",
        level: 1,
        children: [
          {
            regionId: 330502,
            parentId: 3305,
            regionName: "吴兴区",
            level: 2,
          },
          {
            regionId: 330503,
            parentId: 3305,
            regionName: "南浔区",
            level: 2,
          },
          {
            regionId: 330521,
            parentId: 3305,
            regionName: "德清县",
            level: 2,
          },
          {
            regionId: 330522,
            parentId: 3305,
            regionName: "长兴县",
            level: 2,
          },
          {
            regionId: 330523,
            parentId: 3305,
            regionName: "安吉县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3306,
        parentId: 33,
        regionName: "绍兴市",
        level: 1,
        children: [
          {
            regionId: 330602,
            parentId: 3306,
            regionName: "越城区",
            level: 2,
          },
          {
            regionId: 330603,
            parentId: 3306,
            regionName: "柯桥区",
            level: 2,
          },
          {
            regionId: 330604,
            parentId: 3306,
            regionName: "上虞区",
            level: 2,
          },
          {
            regionId: 330624,
            parentId: 3306,
            regionName: "新昌县",
            level: 2,
          },
          {
            regionId: 330681,
            parentId: 3306,
            regionName: "诸暨市",
            level: 2,
          },
          {
            regionId: 330683,
            parentId: 3306,
            regionName: "嵊州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3307,
        parentId: 33,
        regionName: "金华市",
        level: 1,
        children: [
          {
            regionId: 330702,
            parentId: 3307,
            regionName: "婺城区",
            level: 2,
          },
          {
            regionId: 330703,
            parentId: 3307,
            regionName: "金东区",
            level: 2,
          },
          {
            regionId: 330723,
            parentId: 3307,
            regionName: "武义县",
            level: 2,
          },
          {
            regionId: 330726,
            parentId: 3307,
            regionName: "浦江县",
            level: 2,
          },
          {
            regionId: 330727,
            parentId: 3307,
            regionName: "磐安县",
            level: 2,
          },
          {
            regionId: 330781,
            parentId: 3307,
            regionName: "兰溪市",
            level: 2,
          },
          {
            regionId: 330782,
            parentId: 3307,
            regionName: "义乌市",
            level: 2,
          },
          {
            regionId: 330783,
            parentId: 3307,
            regionName: "东阳市",
            level: 2,
          },
          {
            regionId: 330784,
            parentId: 3307,
            regionName: "永康市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3308,
        parentId: 33,
        regionName: "衢州市",
        level: 1,
        children: [
          {
            regionId: 330802,
            parentId: 3308,
            regionName: "柯城区",
            level: 2,
          },
          {
            regionId: 330803,
            parentId: 3308,
            regionName: "衢江区",
            level: 2,
          },
          {
            regionId: 330822,
            parentId: 3308,
            regionName: "常山县",
            level: 2,
          },
          {
            regionId: 330824,
            parentId: 3308,
            regionName: "开化县",
            level: 2,
          },
          {
            regionId: 330825,
            parentId: 3308,
            regionName: "龙游县",
            level: 2,
          },
          {
            regionId: 330881,
            parentId: 3308,
            regionName: "江山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3309,
        parentId: 33,
        regionName: "舟山市",
        level: 1,
        children: [
          {
            regionId: 330902,
            parentId: 3309,
            regionName: "定海区",
            level: 2,
          },
          {
            regionId: 330903,
            parentId: 3309,
            regionName: "普陀区",
            level: 2,
          },
          {
            regionId: 330921,
            parentId: 3309,
            regionName: "岱山县",
            level: 2,
          },
          {
            regionId: 330922,
            parentId: 3309,
            regionName: "嵊泗县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3310,
        parentId: 33,
        regionName: "台州市",
        level: 1,
        children: [
          {
            regionId: 331002,
            parentId: 3310,
            regionName: "椒江区",
            level: 2,
          },
          {
            regionId: 331003,
            parentId: 3310,
            regionName: "黄岩区",
            level: 2,
          },
          {
            regionId: 331004,
            parentId: 3310,
            regionName: "路桥区",
            level: 2,
          },
          {
            regionId: 331022,
            parentId: 3310,
            regionName: "三门县",
            level: 2,
          },
          {
            regionId: 331023,
            parentId: 3310,
            regionName: "天台县",
            level: 2,
          },
          {
            regionId: 331024,
            parentId: 3310,
            regionName: "仙居县",
            level: 2,
          },
          {
            regionId: 331081,
            parentId: 3310,
            regionName: "温岭市",
            level: 2,
          },
          {
            regionId: 331082,
            parentId: 3310,
            regionName: "临海市",
            level: 2,
          },
          {
            regionId: 331083,
            parentId: 3310,
            regionName: "玉环市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3311,
        parentId: 33,
        regionName: "丽水市",
        level: 1,
        children: [
          {
            regionId: 331102,
            parentId: 3311,
            regionName: "莲都区",
            level: 2,
          },
          {
            regionId: 331121,
            parentId: 3311,
            regionName: "青田县",
            level: 2,
          },
          {
            regionId: 331122,
            parentId: 3311,
            regionName: "缙云县",
            level: 2,
          },
          {
            regionId: 331123,
            parentId: 3311,
            regionName: "遂昌县",
            level: 2,
          },
          {
            regionId: 331124,
            parentId: 3311,
            regionName: "松阳县",
            level: 2,
          },
          {
            regionId: 331125,
            parentId: 3311,
            regionName: "云和县",
            level: 2,
          },
          {
            regionId: 331126,
            parentId: 3311,
            regionName: "庆元县",
            level: 2,
          },
          {
            regionId: 331127,
            parentId: 3311,
            regionName: "景宁畲族自治县",
            level: 2,
          },
          {
            regionId: 331181,
            parentId: 3311,
            regionName: "龙泉市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 34,
    parentId: 0,
    regionName: "安徽省",
    level: 0,
    children: [
      {
        regionId: 3401,
        parentId: 34,
        regionName: "合肥市",
        level: 1,
        children: [
          {
            regionId: 340102,
            parentId: 3401,
            regionName: "瑶海区",
            level: 2,
          },
          {
            regionId: 340103,
            parentId: 3401,
            regionName: "庐阳区",
            level: 2,
          },
          {
            regionId: 340104,
            parentId: 3401,
            regionName: "蜀山区",
            level: 2,
          },
          {
            regionId: 340111,
            parentId: 3401,
            regionName: "包河区",
            level: 2,
          },
          {
            regionId: 340121,
            parentId: 3401,
            regionName: "长丰县",
            level: 2,
          },
          {
            regionId: 340122,
            parentId: 3401,
            regionName: "肥东县",
            level: 2,
          },
          {
            regionId: 340123,
            parentId: 3401,
            regionName: "肥西县",
            level: 2,
          },
          {
            regionId: 340124,
            parentId: 3401,
            regionName: "庐江县",
            level: 2,
          },
          {
            regionId: 340181,
            parentId: 3401,
            regionName: "巢湖市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3402,
        parentId: 34,
        regionName: "芜湖市",
        level: 1,
        children: [
          {
            regionId: 340202,
            parentId: 3402,
            regionName: "镜湖区",
            level: 2,
          },
          {
            regionId: 340207,
            parentId: 3402,
            regionName: "鸠江区",
            level: 2,
          },
          {
            regionId: 340209,
            parentId: 3402,
            regionName: "弋江区",
            level: 2,
          },
          {
            regionId: 340210,
            parentId: 3402,
            regionName: "湾沚区",
            level: 2,
          },
          {
            regionId: 340212,
            parentId: 3402,
            regionName: "繁昌区",
            level: 2,
          },
          {
            regionId: 340223,
            parentId: 3402,
            regionName: "南陵县",
            level: 2,
          },
          {
            regionId: 340281,
            parentId: 3402,
            regionName: "无为市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3403,
        parentId: 34,
        regionName: "蚌埠市",
        level: 1,
        children: [
          {
            regionId: 340302,
            parentId: 3403,
            regionName: "龙子湖区",
            level: 2,
          },
          {
            regionId: 340303,
            parentId: 3403,
            regionName: "蚌山区",
            level: 2,
          },
          {
            regionId: 340304,
            parentId: 3403,
            regionName: "禹会区",
            level: 2,
          },
          {
            regionId: 340311,
            parentId: 3403,
            regionName: "淮上区",
            level: 2,
          },
          {
            regionId: 340321,
            parentId: 3403,
            regionName: "怀远县",
            level: 2,
          },
          {
            regionId: 340322,
            parentId: 3403,
            regionName: "五河县",
            level: 2,
          },
          {
            regionId: 340323,
            parentId: 3403,
            regionName: "固镇县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3404,
        parentId: 34,
        regionName: "淮南市",
        level: 1,
        children: [
          {
            regionId: 340402,
            parentId: 3404,
            regionName: "大通区",
            level: 2,
          },
          {
            regionId: 340403,
            parentId: 3404,
            regionName: "田家庵区",
            level: 2,
          },
          {
            regionId: 340404,
            parentId: 3404,
            regionName: "谢家集区",
            level: 2,
          },
          {
            regionId: 340405,
            parentId: 3404,
            regionName: "八公山区",
            level: 2,
          },
          {
            regionId: 340406,
            parentId: 3404,
            regionName: "潘集区",
            level: 2,
          },
          {
            regionId: 340421,
            parentId: 3404,
            regionName: "凤台县",
            level: 2,
          },
          {
            regionId: 340422,
            parentId: 3404,
            regionName: "寿县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3405,
        parentId: 34,
        regionName: "马鞍山市",
        level: 1,
        children: [
          {
            regionId: 340503,
            parentId: 3405,
            regionName: "花山区",
            level: 2,
          },
          {
            regionId: 340504,
            parentId: 3405,
            regionName: "雨山区",
            level: 2,
          },
          {
            regionId: 340506,
            parentId: 3405,
            regionName: "博望区",
            level: 2,
          },
          {
            regionId: 340521,
            parentId: 3405,
            regionName: "当涂县",
            level: 2,
          },
          {
            regionId: 340522,
            parentId: 3405,
            regionName: "含山县",
            level: 2,
          },
          {
            regionId: 340523,
            parentId: 3405,
            regionName: "和县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3406,
        parentId: 34,
        regionName: "淮北市",
        level: 1,
        children: [
          {
            regionId: 340602,
            parentId: 3406,
            regionName: "杜集区",
            level: 2,
          },
          {
            regionId: 340603,
            parentId: 3406,
            regionName: "相山区",
            level: 2,
          },
          {
            regionId: 340604,
            parentId: 3406,
            regionName: "烈山区",
            level: 2,
          },
          {
            regionId: 340621,
            parentId: 3406,
            regionName: "濉溪县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3407,
        parentId: 34,
        regionName: "铜陵市",
        level: 1,
        children: [
          {
            regionId: 340705,
            parentId: 3407,
            regionName: "铜官区",
            level: 2,
          },
          {
            regionId: 340706,
            parentId: 3407,
            regionName: "义安区",
            level: 2,
          },
          {
            regionId: 340711,
            parentId: 3407,
            regionName: "郊区",
            level: 2,
          },
          {
            regionId: 340722,
            parentId: 3407,
            regionName: "枞阳县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3408,
        parentId: 34,
        regionName: "安庆市",
        level: 1,
        children: [
          {
            regionId: 340802,
            parentId: 3408,
            regionName: "迎江区",
            level: 2,
          },
          {
            regionId: 340803,
            parentId: 3408,
            regionName: "大观区",
            level: 2,
          },
          {
            regionId: 340811,
            parentId: 3408,
            regionName: "宜秀区",
            level: 2,
          },
          {
            regionId: 340822,
            parentId: 3408,
            regionName: "怀宁县",
            level: 2,
          },
          {
            regionId: 340825,
            parentId: 3408,
            regionName: "太湖县",
            level: 2,
          },
          {
            regionId: 340826,
            parentId: 3408,
            regionName: "宿松县",
            level: 2,
          },
          {
            regionId: 340827,
            parentId: 3408,
            regionName: "望江县",
            level: 2,
          },
          {
            regionId: 340828,
            parentId: 3408,
            regionName: "岳西县",
            level: 2,
          },
          {
            regionId: 340881,
            parentId: 3408,
            regionName: "桐城市",
            level: 2,
          },
          {
            regionId: 340882,
            parentId: 3408,
            regionName: "潜山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3410,
        parentId: 34,
        regionName: "黄山市",
        level: 1,
        children: [
          {
            regionId: 341002,
            parentId: 3410,
            regionName: "屯溪区",
            level: 2,
          },
          {
            regionId: 341003,
            parentId: 3410,
            regionName: "黄山区",
            level: 2,
          },
          {
            regionId: 341004,
            parentId: 3410,
            regionName: "徽州区",
            level: 2,
          },
          {
            regionId: 341021,
            parentId: 3410,
            regionName: "歙县",
            level: 2,
          },
          {
            regionId: 341022,
            parentId: 3410,
            regionName: "休宁县",
            level: 2,
          },
          {
            regionId: 341023,
            parentId: 3410,
            regionName: "黟县",
            level: 2,
          },
          {
            regionId: 341024,
            parentId: 3410,
            regionName: "祁门县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3411,
        parentId: 34,
        regionName: "滁州市",
        level: 1,
        children: [
          {
            regionId: 341102,
            parentId: 3411,
            regionName: "琅琊区",
            level: 2,
          },
          {
            regionId: 341103,
            parentId: 3411,
            regionName: "南谯区",
            level: 2,
          },
          {
            regionId: 341122,
            parentId: 3411,
            regionName: "来安县",
            level: 2,
          },
          {
            regionId: 341124,
            parentId: 3411,
            regionName: "全椒县",
            level: 2,
          },
          {
            regionId: 341125,
            parentId: 3411,
            regionName: "定远县",
            level: 2,
          },
          {
            regionId: 341126,
            parentId: 3411,
            regionName: "凤阳县",
            level: 2,
          },
          {
            regionId: 341181,
            parentId: 3411,
            regionName: "天长市",
            level: 2,
          },
          {
            regionId: 341182,
            parentId: 3411,
            regionName: "明光市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3412,
        parentId: 34,
        regionName: "阜阳市",
        level: 1,
        children: [
          {
            regionId: 341202,
            parentId: 3412,
            regionName: "颍州区",
            level: 2,
          },
          {
            regionId: 341203,
            parentId: 3412,
            regionName: "颍东区",
            level: 2,
          },
          {
            regionId: 341204,
            parentId: 3412,
            regionName: "颍泉区",
            level: 2,
          },
          {
            regionId: 341221,
            parentId: 3412,
            regionName: "临泉县",
            level: 2,
          },
          {
            regionId: 341222,
            parentId: 3412,
            regionName: "太和县",
            level: 2,
          },
          {
            regionId: 341225,
            parentId: 3412,
            regionName: "阜南县",
            level: 2,
          },
          {
            regionId: 341226,
            parentId: 3412,
            regionName: "颍上县",
            level: 2,
          },
          {
            regionId: 341282,
            parentId: 3412,
            regionName: "界首市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3413,
        parentId: 34,
        regionName: "宿州市",
        level: 1,
        children: [
          {
            regionId: 341302,
            parentId: 3413,
            regionName: "埇桥区",
            level: 2,
          },
          {
            regionId: 341321,
            parentId: 3413,
            regionName: "砀山县",
            level: 2,
          },
          {
            regionId: 341322,
            parentId: 3413,
            regionName: "萧县",
            level: 2,
          },
          {
            regionId: 341323,
            parentId: 3413,
            regionName: "灵璧县",
            level: 2,
          },
          {
            regionId: 341324,
            parentId: 3413,
            regionName: "泗县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3415,
        parentId: 34,
        regionName: "六安市",
        level: 1,
        children: [
          {
            regionId: 341502,
            parentId: 3415,
            regionName: "金安区",
            level: 2,
          },
          {
            regionId: 341503,
            parentId: 3415,
            regionName: "裕安区",
            level: 2,
          },
          {
            regionId: 341504,
            parentId: 3415,
            regionName: "叶集区",
            level: 2,
          },
          {
            regionId: 341522,
            parentId: 3415,
            regionName: "霍邱县",
            level: 2,
          },
          {
            regionId: 341523,
            parentId: 3415,
            regionName: "舒城县",
            level: 2,
          },
          {
            regionId: 341524,
            parentId: 3415,
            regionName: "金寨县",
            level: 2,
          },
          {
            regionId: 341525,
            parentId: 3415,
            regionName: "霍山县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3416,
        parentId: 34,
        regionName: "亳州市",
        level: 1,
        children: [
          {
            regionId: 341602,
            parentId: 3416,
            regionName: "谯城区",
            level: 2,
          },
          {
            regionId: 341621,
            parentId: 3416,
            regionName: "涡阳县",
            level: 2,
          },
          {
            regionId: 341622,
            parentId: 3416,
            regionName: "蒙城县",
            level: 2,
          },
          {
            regionId: 341623,
            parentId: 3416,
            regionName: "利辛县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3417,
        parentId: 34,
        regionName: "池州市",
        level: 1,
        children: [
          {
            regionId: 341702,
            parentId: 3417,
            regionName: "贵池区",
            level: 2,
          },
          {
            regionId: 341721,
            parentId: 3417,
            regionName: "东至县",
            level: 2,
          },
          {
            regionId: 341722,
            parentId: 3417,
            regionName: "石台县",
            level: 2,
          },
          {
            regionId: 341723,
            parentId: 3417,
            regionName: "青阳县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3418,
        parentId: 34,
        regionName: "宣城市",
        level: 1,
        children: [
          {
            regionId: 341802,
            parentId: 3418,
            regionName: "宣州区",
            level: 2,
          },
          {
            regionId: 341821,
            parentId: 3418,
            regionName: "郎溪县",
            level: 2,
          },
          {
            regionId: 341823,
            parentId: 3418,
            regionName: "泾县",
            level: 2,
          },
          {
            regionId: 341824,
            parentId: 3418,
            regionName: "绩溪县",
            level: 2,
          },
          {
            regionId: 341825,
            parentId: 3418,
            regionName: "旌德县",
            level: 2,
          },
          {
            regionId: 341881,
            parentId: 3418,
            regionName: "宁国市",
            level: 2,
          },
          {
            regionId: 341882,
            parentId: 3418,
            regionName: "广德市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 35,
    parentId: 0,
    regionName: "福建省",
    level: 0,
    children: [
      {
        regionId: 3501,
        parentId: 35,
        regionName: "福州市",
        level: 1,
        children: [
          {
            regionId: 350102,
            parentId: 3501,
            regionName: "鼓楼区",
            level: 2,
          },
          {
            regionId: 350103,
            parentId: 3501,
            regionName: "台江区",
            level: 2,
          },
          {
            regionId: 350104,
            parentId: 3501,
            regionName: "仓山区",
            level: 2,
          },
          {
            regionId: 350105,
            parentId: 3501,
            regionName: "马尾区",
            level: 2,
          },
          {
            regionId: 350111,
            parentId: 3501,
            regionName: "晋安区",
            level: 2,
          },
          {
            regionId: 350112,
            parentId: 3501,
            regionName: "长乐区",
            level: 2,
          },
          {
            regionId: 350121,
            parentId: 3501,
            regionName: "闽侯县",
            level: 2,
          },
          {
            regionId: 350122,
            parentId: 3501,
            regionName: "连江县",
            level: 2,
          },
          {
            regionId: 350123,
            parentId: 3501,
            regionName: "罗源县",
            level: 2,
          },
          {
            regionId: 350124,
            parentId: 3501,
            regionName: "闽清县",
            level: 2,
          },
          {
            regionId: 350125,
            parentId: 3501,
            regionName: "永泰县",
            level: 2,
          },
          {
            regionId: 350128,
            parentId: 3501,
            regionName: "平潭县",
            level: 2,
          },
          {
            regionId: 350181,
            parentId: 3501,
            regionName: "福清市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3502,
        parentId: 35,
        regionName: "厦门市",
        level: 1,
        children: [
          {
            regionId: 350203,
            parentId: 3502,
            regionName: "思明区",
            level: 2,
          },
          {
            regionId: 350205,
            parentId: 3502,
            regionName: "海沧区",
            level: 2,
          },
          {
            regionId: 350206,
            parentId: 3502,
            regionName: "湖里区",
            level: 2,
          },
          {
            regionId: 350211,
            parentId: 3502,
            regionName: "集美区",
            level: 2,
          },
          {
            regionId: 350212,
            parentId: 3502,
            regionName: "同安区",
            level: 2,
          },
          {
            regionId: 350213,
            parentId: 3502,
            regionName: "翔安区",
            level: 2,
          },
        ],
      },
      {
        regionId: 3503,
        parentId: 35,
        regionName: "莆田市",
        level: 1,
        children: [
          {
            regionId: 350302,
            parentId: 3503,
            regionName: "城厢区",
            level: 2,
          },
          {
            regionId: 350303,
            parentId: 3503,
            regionName: "涵江区",
            level: 2,
          },
          {
            regionId: 350304,
            parentId: 3503,
            regionName: "荔城区",
            level: 2,
          },
          {
            regionId: 350305,
            parentId: 3503,
            regionName: "秀屿区",
            level: 2,
          },
          {
            regionId: 350322,
            parentId: 3503,
            regionName: "仙游县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3504,
        parentId: 35,
        regionName: "三明市",
        level: 1,
        children: [
          {
            regionId: 350404,
            parentId: 3504,
            regionName: "三元区",
            level: 2,
          },
          {
            regionId: 350405,
            parentId: 3504,
            regionName: "沙县区",
            level: 2,
          },
          {
            regionId: 350421,
            parentId: 3504,
            regionName: "明溪县",
            level: 2,
          },
          {
            regionId: 350423,
            parentId: 3504,
            regionName: "清流县",
            level: 2,
          },
          {
            regionId: 350424,
            parentId: 3504,
            regionName: "宁化县",
            level: 2,
          },
          {
            regionId: 350425,
            parentId: 3504,
            regionName: "大田县",
            level: 2,
          },
          {
            regionId: 350426,
            parentId: 3504,
            regionName: "尤溪县",
            level: 2,
          },
          {
            regionId: 350428,
            parentId: 3504,
            regionName: "将乐县",
            level: 2,
          },
          {
            regionId: 350429,
            parentId: 3504,
            regionName: "泰宁县",
            level: 2,
          },
          {
            regionId: 350430,
            parentId: 3504,
            regionName: "建宁县",
            level: 2,
          },
          {
            regionId: 350481,
            parentId: 3504,
            regionName: "永安市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3505,
        parentId: 35,
        regionName: "泉州市",
        level: 1,
        children: [
          {
            regionId: 350502,
            parentId: 3505,
            regionName: "鲤城区",
            level: 2,
          },
          {
            regionId: 350503,
            parentId: 3505,
            regionName: "丰泽区",
            level: 2,
          },
          {
            regionId: 350504,
            parentId: 3505,
            regionName: "洛江区",
            level: 2,
          },
          {
            regionId: 350505,
            parentId: 3505,
            regionName: "泉港区",
            level: 2,
          },
          {
            regionId: 350521,
            parentId: 3505,
            regionName: "惠安县",
            level: 2,
          },
          {
            regionId: 350524,
            parentId: 3505,
            regionName: "安溪县",
            level: 2,
          },
          {
            regionId: 350525,
            parentId: 3505,
            regionName: "永春县",
            level: 2,
          },
          {
            regionId: 350526,
            parentId: 3505,
            regionName: "德化县",
            level: 2,
          },
          {
            regionId: 350527,
            parentId: 3505,
            regionName: "金门县",
            level: 2,
          },
          {
            regionId: 350581,
            parentId: 3505,
            regionName: "石狮市",
            level: 2,
          },
          {
            regionId: 350582,
            parentId: 3505,
            regionName: "晋江市",
            level: 2,
          },
          {
            regionId: 350583,
            parentId: 3505,
            regionName: "南安市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3506,
        parentId: 35,
        regionName: "漳州市",
        level: 1,
        children: [
          {
            regionId: 350602,
            parentId: 3506,
            regionName: "芗城区",
            level: 2,
          },
          {
            regionId: 350603,
            parentId: 3506,
            regionName: "龙文区",
            level: 2,
          },
          {
            regionId: 350604,
            parentId: 3506,
            regionName: "龙海区",
            level: 2,
          },
          {
            regionId: 350605,
            parentId: 3506,
            regionName: "长泰区",
            level: 2,
          },
          {
            regionId: 350622,
            parentId: 3506,
            regionName: "云霄县",
            level: 2,
          },
          {
            regionId: 350623,
            parentId: 3506,
            regionName: "漳浦县",
            level: 2,
          },
          {
            regionId: 350624,
            parentId: 3506,
            regionName: "诏安县",
            level: 2,
          },
          {
            regionId: 350626,
            parentId: 3506,
            regionName: "东山县",
            level: 2,
          },
          {
            regionId: 350627,
            parentId: 3506,
            regionName: "南靖县",
            level: 2,
          },
          {
            regionId: 350628,
            parentId: 3506,
            regionName: "平和县",
            level: 2,
          },
          {
            regionId: 350629,
            parentId: 3506,
            regionName: "华安县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3507,
        parentId: 35,
        regionName: "南平市",
        level: 1,
        children: [
          {
            regionId: 350702,
            parentId: 3507,
            regionName: "延平区",
            level: 2,
          },
          {
            regionId: 350703,
            parentId: 3507,
            regionName: "建阳区",
            level: 2,
          },
          {
            regionId: 350721,
            parentId: 3507,
            regionName: "顺昌县",
            level: 2,
          },
          {
            regionId: 350722,
            parentId: 3507,
            regionName: "浦城县",
            level: 2,
          },
          {
            regionId: 350723,
            parentId: 3507,
            regionName: "光泽县",
            level: 2,
          },
          {
            regionId: 350724,
            parentId: 3507,
            regionName: "松溪县",
            level: 2,
          },
          {
            regionId: 350725,
            parentId: 3507,
            regionName: "政和县",
            level: 2,
          },
          {
            regionId: 350781,
            parentId: 3507,
            regionName: "邵武市",
            level: 2,
          },
          {
            regionId: 350782,
            parentId: 3507,
            regionName: "武夷山市",
            level: 2,
          },
          {
            regionId: 350783,
            parentId: 3507,
            regionName: "建瓯市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3508,
        parentId: 35,
        regionName: "龙岩市",
        level: 1,
        children: [
          {
            regionId: 350802,
            parentId: 3508,
            regionName: "新罗区",
            level: 2,
          },
          {
            regionId: 350803,
            parentId: 3508,
            regionName: "永定区",
            level: 2,
          },
          {
            regionId: 350821,
            parentId: 3508,
            regionName: "长汀县",
            level: 2,
          },
          {
            regionId: 350823,
            parentId: 3508,
            regionName: "上杭县",
            level: 2,
          },
          {
            regionId: 350824,
            parentId: 3508,
            regionName: "武平县",
            level: 2,
          },
          {
            regionId: 350825,
            parentId: 3508,
            regionName: "连城县",
            level: 2,
          },
          {
            regionId: 350881,
            parentId: 3508,
            regionName: "漳平市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3509,
        parentId: 35,
        regionName: "宁德市",
        level: 1,
        children: [
          {
            regionId: 350902,
            parentId: 3509,
            regionName: "蕉城区",
            level: 2,
          },
          {
            regionId: 350921,
            parentId: 3509,
            regionName: "霞浦县",
            level: 2,
          },
          {
            regionId: 350922,
            parentId: 3509,
            regionName: "古田县",
            level: 2,
          },
          {
            regionId: 350923,
            parentId: 3509,
            regionName: "屏南县",
            level: 2,
          },
          {
            regionId: 350924,
            parentId: 3509,
            regionName: "寿宁县",
            level: 2,
          },
          {
            regionId: 350925,
            parentId: 3509,
            regionName: "周宁县",
            level: 2,
          },
          {
            regionId: 350926,
            parentId: 3509,
            regionName: "柘荣县",
            level: 2,
          },
          {
            regionId: 350981,
            parentId: 3509,
            regionName: "福安市",
            level: 2,
          },
          {
            regionId: 350982,
            parentId: 3509,
            regionName: "福鼎市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 36,
    parentId: 0,
    regionName: "江西省",
    level: 0,
    children: [
      {
        regionId: 3601,
        parentId: 36,
        regionName: "南昌市",
        level: 1,
        children: [
          {
            regionId: 360102,
            parentId: 3601,
            regionName: "东湖区",
            level: 2,
          },
          {
            regionId: 360103,
            parentId: 3601,
            regionName: "西湖区",
            level: 2,
          },
          {
            regionId: 360104,
            parentId: 3601,
            regionName: "青云谱区",
            level: 2,
          },
          {
            regionId: 360111,
            parentId: 3601,
            regionName: "青山湖区",
            level: 2,
          },
          {
            regionId: 360112,
            parentId: 3601,
            regionName: "新建区",
            level: 2,
          },
          {
            regionId: 360113,
            parentId: 3601,
            regionName: "红谷滩区",
            level: 2,
          },
          {
            regionId: 360121,
            parentId: 3601,
            regionName: "南昌县",
            level: 2,
          },
          {
            regionId: 360123,
            parentId: 3601,
            regionName: "安义县",
            level: 2,
          },
          {
            regionId: 360124,
            parentId: 3601,
            regionName: "进贤县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3602,
        parentId: 36,
        regionName: "景德镇市",
        level: 1,
        children: [
          {
            regionId: 360202,
            parentId: 3602,
            regionName: "昌江区",
            level: 2,
          },
          {
            regionId: 360203,
            parentId: 3602,
            regionName: "珠山区",
            level: 2,
          },
          {
            regionId: 360222,
            parentId: 3602,
            regionName: "浮梁县",
            level: 2,
          },
          {
            regionId: 360281,
            parentId: 3602,
            regionName: "乐平市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3603,
        parentId: 36,
        regionName: "萍乡市",
        level: 1,
        children: [
          {
            regionId: 360302,
            parentId: 3603,
            regionName: "安源区",
            level: 2,
          },
          {
            regionId: 360313,
            parentId: 3603,
            regionName: "湘东区",
            level: 2,
          },
          {
            regionId: 360321,
            parentId: 3603,
            regionName: "莲花县",
            level: 2,
          },
          {
            regionId: 360322,
            parentId: 3603,
            regionName: "上栗县",
            level: 2,
          },
          {
            regionId: 360323,
            parentId: 3603,
            regionName: "芦溪县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3604,
        parentId: 36,
        regionName: "九江市",
        level: 1,
        children: [
          {
            regionId: 360402,
            parentId: 3604,
            regionName: "濂溪区",
            level: 2,
          },
          {
            regionId: 360403,
            parentId: 3604,
            regionName: "浔阳区",
            level: 2,
          },
          {
            regionId: 360404,
            parentId: 3604,
            regionName: "柴桑区",
            level: 2,
          },
          {
            regionId: 360423,
            parentId: 3604,
            regionName: "武宁县",
            level: 2,
          },
          {
            regionId: 360424,
            parentId: 3604,
            regionName: "修水县",
            level: 2,
          },
          {
            regionId: 360425,
            parentId: 3604,
            regionName: "永修县",
            level: 2,
          },
          {
            regionId: 360426,
            parentId: 3604,
            regionName: "德安县",
            level: 2,
          },
          {
            regionId: 360428,
            parentId: 3604,
            regionName: "都昌县",
            level: 2,
          },
          {
            regionId: 360429,
            parentId: 3604,
            regionName: "湖口县",
            level: 2,
          },
          {
            regionId: 360430,
            parentId: 3604,
            regionName: "彭泽县",
            level: 2,
          },
          {
            regionId: 360481,
            parentId: 3604,
            regionName: "瑞昌市",
            level: 2,
          },
          {
            regionId: 360482,
            parentId: 3604,
            regionName: "共青城市",
            level: 2,
          },
          {
            regionId: 360483,
            parentId: 3604,
            regionName: "庐山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3605,
        parentId: 36,
        regionName: "新余市",
        level: 1,
        children: [
          {
            regionId: 360502,
            parentId: 3605,
            regionName: "渝水区",
            level: 2,
          },
          {
            regionId: 360521,
            parentId: 3605,
            regionName: "分宜县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3606,
        parentId: 36,
        regionName: "鹰潭市",
        level: 1,
        children: [
          {
            regionId: 360602,
            parentId: 3606,
            regionName: "月湖区",
            level: 2,
          },
          {
            regionId: 360603,
            parentId: 3606,
            regionName: "余江区",
            level: 2,
          },
          {
            regionId: 360681,
            parentId: 3606,
            regionName: "贵溪市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3607,
        parentId: 36,
        regionName: "赣州市",
        level: 1,
        children: [
          {
            regionId: 360702,
            parentId: 3607,
            regionName: "章贡区",
            level: 2,
          },
          {
            regionId: 360703,
            parentId: 3607,
            regionName: "南康区",
            level: 2,
          },
          {
            regionId: 360704,
            parentId: 3607,
            regionName: "赣县区",
            level: 2,
          },
          {
            regionId: 360722,
            parentId: 3607,
            regionName: "信丰县",
            level: 2,
          },
          {
            regionId: 360723,
            parentId: 3607,
            regionName: "大余县",
            level: 2,
          },
          {
            regionId: 360724,
            parentId: 3607,
            regionName: "上犹县",
            level: 2,
          },
          {
            regionId: 360725,
            parentId: 3607,
            regionName: "崇义县",
            level: 2,
          },
          {
            regionId: 360726,
            parentId: 3607,
            regionName: "安远县",
            level: 2,
          },
          {
            regionId: 360728,
            parentId: 3607,
            regionName: "定南县",
            level: 2,
          },
          {
            regionId: 360729,
            parentId: 3607,
            regionName: "全南县",
            level: 2,
          },
          {
            regionId: 360730,
            parentId: 3607,
            regionName: "宁都县",
            level: 2,
          },
          {
            regionId: 360731,
            parentId: 3607,
            regionName: "于都县",
            level: 2,
          },
          {
            regionId: 360732,
            parentId: 3607,
            regionName: "兴国县",
            level: 2,
          },
          {
            regionId: 360733,
            parentId: 3607,
            regionName: "会昌县",
            level: 2,
          },
          {
            regionId: 360734,
            parentId: 3607,
            regionName: "寻乌县",
            level: 2,
          },
          {
            regionId: 360735,
            parentId: 3607,
            regionName: "石城县",
            level: 2,
          },
          {
            regionId: 360781,
            parentId: 3607,
            regionName: "瑞金市",
            level: 2,
          },
          {
            regionId: 360783,
            parentId: 3607,
            regionName: "龙南市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3608,
        parentId: 36,
        regionName: "吉安市",
        level: 1,
        children: [
          {
            regionId: 360802,
            parentId: 3608,
            regionName: "吉州区",
            level: 2,
          },
          {
            regionId: 360803,
            parentId: 3608,
            regionName: "青原区",
            level: 2,
          },
          {
            regionId: 360821,
            parentId: 3608,
            regionName: "吉安县",
            level: 2,
          },
          {
            regionId: 360822,
            parentId: 3608,
            regionName: "吉水县",
            level: 2,
          },
          {
            regionId: 360823,
            parentId: 3608,
            regionName: "峡江县",
            level: 2,
          },
          {
            regionId: 360824,
            parentId: 3608,
            regionName: "新干县",
            level: 2,
          },
          {
            regionId: 360825,
            parentId: 3608,
            regionName: "永丰县",
            level: 2,
          },
          {
            regionId: 360826,
            parentId: 3608,
            regionName: "泰和县",
            level: 2,
          },
          {
            regionId: 360827,
            parentId: 3608,
            regionName: "遂川县",
            level: 2,
          },
          {
            regionId: 360828,
            parentId: 3608,
            regionName: "万安县",
            level: 2,
          },
          {
            regionId: 360829,
            parentId: 3608,
            regionName: "安福县",
            level: 2,
          },
          {
            regionId: 360830,
            parentId: 3608,
            regionName: "永新县",
            level: 2,
          },
          {
            regionId: 360881,
            parentId: 3608,
            regionName: "井冈山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3609,
        parentId: 36,
        regionName: "宜春市",
        level: 1,
        children: [
          {
            regionId: 360902,
            parentId: 3609,
            regionName: "袁州区",
            level: 2,
          },
          {
            regionId: 360921,
            parentId: 3609,
            regionName: "奉新县",
            level: 2,
          },
          {
            regionId: 360922,
            parentId: 3609,
            regionName: "万载县",
            level: 2,
          },
          {
            regionId: 360923,
            parentId: 3609,
            regionName: "上高县",
            level: 2,
          },
          {
            regionId: 360924,
            parentId: 3609,
            regionName: "宜丰县",
            level: 2,
          },
          {
            regionId: 360925,
            parentId: 3609,
            regionName: "靖安县",
            level: 2,
          },
          {
            regionId: 360926,
            parentId: 3609,
            regionName: "铜鼓县",
            level: 2,
          },
          {
            regionId: 360981,
            parentId: 3609,
            regionName: "丰城市",
            level: 2,
          },
          {
            regionId: 360982,
            parentId: 3609,
            regionName: "樟树市",
            level: 2,
          },
          {
            regionId: 360983,
            parentId: 3609,
            regionName: "高安市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3610,
        parentId: 36,
        regionName: "抚州市",
        level: 1,
        children: [
          {
            regionId: 361002,
            parentId: 3610,
            regionName: "临川区",
            level: 2,
          },
          {
            regionId: 361003,
            parentId: 3610,
            regionName: "东乡区",
            level: 2,
          },
          {
            regionId: 361021,
            parentId: 3610,
            regionName: "南城县",
            level: 2,
          },
          {
            regionId: 361022,
            parentId: 3610,
            regionName: "黎川县",
            level: 2,
          },
          {
            regionId: 361023,
            parentId: 3610,
            regionName: "南丰县",
            level: 2,
          },
          {
            regionId: 361024,
            parentId: 3610,
            regionName: "崇仁县",
            level: 2,
          },
          {
            regionId: 361025,
            parentId: 3610,
            regionName: "乐安县",
            level: 2,
          },
          {
            regionId: 361026,
            parentId: 3610,
            regionName: "宜黄县",
            level: 2,
          },
          {
            regionId: 361027,
            parentId: 3610,
            regionName: "金溪县",
            level: 2,
          },
          {
            regionId: 361028,
            parentId: 3610,
            regionName: "资溪县",
            level: 2,
          },
          {
            regionId: 361030,
            parentId: 3610,
            regionName: "广昌县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3611,
        parentId: 36,
        regionName: "上饶市",
        level: 1,
        children: [
          {
            regionId: 361102,
            parentId: 3611,
            regionName: "信州区",
            level: 2,
          },
          {
            regionId: 361103,
            parentId: 3611,
            regionName: "广丰区",
            level: 2,
          },
          {
            regionId: 361104,
            parentId: 3611,
            regionName: "广信区",
            level: 2,
          },
          {
            regionId: 361123,
            parentId: 3611,
            regionName: "玉山县",
            level: 2,
          },
          {
            regionId: 361124,
            parentId: 3611,
            regionName: "铅山县",
            level: 2,
          },
          {
            regionId: 361125,
            parentId: 3611,
            regionName: "横峰县",
            level: 2,
          },
          {
            regionId: 361126,
            parentId: 3611,
            regionName: "弋阳县",
            level: 2,
          },
          {
            regionId: 361127,
            parentId: 3611,
            regionName: "余干县",
            level: 2,
          },
          {
            regionId: 361128,
            parentId: 3611,
            regionName: "鄱阳县",
            level: 2,
          },
          {
            regionId: 361129,
            parentId: 3611,
            regionName: "万年县",
            level: 2,
          },
          {
            regionId: 361130,
            parentId: 3611,
            regionName: "婺源县",
            level: 2,
          },
          {
            regionId: 361181,
            parentId: 3611,
            regionName: "德兴市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 37,
    parentId: 0,
    regionName: "山东省",
    level: 0,
    children: [
      {
        regionId: 3701,
        parentId: 37,
        regionName: "济南市",
        level: 1,
        children: [
          {
            regionId: 370102,
            parentId: 3701,
            regionName: "历下区",
            level: 2,
          },
          {
            regionId: 370103,
            parentId: 3701,
            regionName: "市中区",
            level: 2,
          },
          {
            regionId: 370104,
            parentId: 3701,
            regionName: "槐荫区",
            level: 2,
          },
          {
            regionId: 370105,
            parentId: 3701,
            regionName: "天桥区",
            level: 2,
          },
          {
            regionId: 370112,
            parentId: 3701,
            regionName: "历城区",
            level: 2,
          },
          {
            regionId: 370113,
            parentId: 3701,
            regionName: "长清区",
            level: 2,
          },
          {
            regionId: 370114,
            parentId: 3701,
            regionName: "章丘区",
            level: 2,
          },
          {
            regionId: 370115,
            parentId: 3701,
            regionName: "济阳区",
            level: 2,
          },
          {
            regionId: 370116,
            parentId: 3701,
            regionName: "莱芜区",
            level: 2,
          },
          {
            regionId: 370117,
            parentId: 3701,
            regionName: "钢城区",
            level: 2,
          },
          {
            regionId: 370124,
            parentId: 3701,
            regionName: "平阴县",
            level: 2,
          },
          {
            regionId: 370126,
            parentId: 3701,
            regionName: "商河县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3702,
        parentId: 37,
        regionName: "青岛市",
        level: 1,
        children: [
          {
            regionId: 370202,
            parentId: 3702,
            regionName: "市南区",
            level: 2,
          },
          {
            regionId: 370203,
            parentId: 3702,
            regionName: "市北区",
            level: 2,
          },
          {
            regionId: 370211,
            parentId: 3702,
            regionName: "黄岛区",
            level: 2,
          },
          {
            regionId: 370212,
            parentId: 3702,
            regionName: "崂山区",
            level: 2,
          },
          {
            regionId: 370213,
            parentId: 3702,
            regionName: "李沧区",
            level: 2,
          },
          {
            regionId: 370214,
            parentId: 3702,
            regionName: "城阳区",
            level: 2,
          },
          {
            regionId: 370215,
            parentId: 3702,
            regionName: "即墨区",
            level: 2,
          },
          {
            regionId: 370281,
            parentId: 3702,
            regionName: "胶州市",
            level: 2,
          },
          {
            regionId: 370283,
            parentId: 3702,
            regionName: "平度市",
            level: 2,
          },
          {
            regionId: 370285,
            parentId: 3702,
            regionName: "莱西市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3703,
        parentId: 37,
        regionName: "淄博市",
        level: 1,
        children: [
          {
            regionId: 370302,
            parentId: 3703,
            regionName: "淄川区",
            level: 2,
          },
          {
            regionId: 370303,
            parentId: 3703,
            regionName: "张店区",
            level: 2,
          },
          {
            regionId: 370304,
            parentId: 3703,
            regionName: "博山区",
            level: 2,
          },
          {
            regionId: 370305,
            parentId: 3703,
            regionName: "临淄区",
            level: 2,
          },
          {
            regionId: 370306,
            parentId: 3703,
            regionName: "周村区",
            level: 2,
          },
          {
            regionId: 370321,
            parentId: 3703,
            regionName: "桓台县",
            level: 2,
          },
          {
            regionId: 370322,
            parentId: 3703,
            regionName: "高青县",
            level: 2,
          },
          {
            regionId: 370323,
            parentId: 3703,
            regionName: "沂源县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3704,
        parentId: 37,
        regionName: "枣庄市",
        level: 1,
        children: [
          {
            regionId: 370402,
            parentId: 3704,
            regionName: "市中区",
            level: 2,
          },
          {
            regionId: 370403,
            parentId: 3704,
            regionName: "薛城区",
            level: 2,
          },
          {
            regionId: 370404,
            parentId: 3704,
            regionName: "峄城区",
            level: 2,
          },
          {
            regionId: 370405,
            parentId: 3704,
            regionName: "台儿庄区",
            level: 2,
          },
          {
            regionId: 370406,
            parentId: 3704,
            regionName: "山亭区",
            level: 2,
          },
          {
            regionId: 370481,
            parentId: 3704,
            regionName: "滕州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3705,
        parentId: 37,
        regionName: "东营市",
        level: 1,
        children: [
          {
            regionId: 370502,
            parentId: 3705,
            regionName: "东营区",
            level: 2,
          },
          {
            regionId: 370503,
            parentId: 3705,
            regionName: "河口区",
            level: 2,
          },
          {
            regionId: 370505,
            parentId: 3705,
            regionName: "垦利区",
            level: 2,
          },
          {
            regionId: 370522,
            parentId: 3705,
            regionName: "利津县",
            level: 2,
          },
          {
            regionId: 370523,
            parentId: 3705,
            regionName: "广饶县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3706,
        parentId: 37,
        regionName: "烟台市",
        level: 1,
        children: [
          {
            regionId: 370602,
            parentId: 3706,
            regionName: "芝罘区",
            level: 2,
          },
          {
            regionId: 370611,
            parentId: 3706,
            regionName: "福山区",
            level: 2,
          },
          {
            regionId: 370612,
            parentId: 3706,
            regionName: "牟平区",
            level: 2,
          },
          {
            regionId: 370613,
            parentId: 3706,
            regionName: "莱山区",
            level: 2,
          },
          {
            regionId: 370614,
            parentId: 3706,
            regionName: "蓬莱区",
            level: 2,
          },
          {
            regionId: 370681,
            parentId: 3706,
            regionName: "龙口市",
            level: 2,
          },
          {
            regionId: 370682,
            parentId: 3706,
            regionName: "莱阳市",
            level: 2,
          },
          {
            regionId: 370683,
            parentId: 3706,
            regionName: "莱州市",
            level: 2,
          },
          {
            regionId: 370685,
            parentId: 3706,
            regionName: "招远市",
            level: 2,
          },
          {
            regionId: 370686,
            parentId: 3706,
            regionName: "栖霞市",
            level: 2,
          },
          {
            regionId: 370687,
            parentId: 3706,
            regionName: "海阳市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3707,
        parentId: 37,
        regionName: "潍坊市",
        level: 1,
        children: [
          {
            regionId: 370702,
            parentId: 3707,
            regionName: "潍城区",
            level: 2,
          },
          {
            regionId: 370703,
            parentId: 3707,
            regionName: "寒亭区",
            level: 2,
          },
          {
            regionId: 370704,
            parentId: 3707,
            regionName: "坊子区",
            level: 2,
          },
          {
            regionId: 370705,
            parentId: 3707,
            regionName: "奎文区",
            level: 2,
          },
          {
            regionId: 370724,
            parentId: 3707,
            regionName: "临朐县",
            level: 2,
          },
          {
            regionId: 370725,
            parentId: 3707,
            regionName: "昌乐县",
            level: 2,
          },
          {
            regionId: 370781,
            parentId: 3707,
            regionName: "青州市",
            level: 2,
          },
          {
            regionId: 370782,
            parentId: 3707,
            regionName: "诸城市",
            level: 2,
          },
          {
            regionId: 370783,
            parentId: 3707,
            regionName: "寿光市",
            level: 2,
          },
          {
            regionId: 370784,
            parentId: 3707,
            regionName: "安丘市",
            level: 2,
          },
          {
            regionId: 370785,
            parentId: 3707,
            regionName: "高密市",
            level: 2,
          },
          {
            regionId: 370786,
            parentId: 3707,
            regionName: "昌邑市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3708,
        parentId: 37,
        regionName: "济宁市",
        level: 1,
        children: [
          {
            regionId: 370811,
            parentId: 3708,
            regionName: "任城区",
            level: 2,
          },
          {
            regionId: 370812,
            parentId: 3708,
            regionName: "兖州区",
            level: 2,
          },
          {
            regionId: 370826,
            parentId: 3708,
            regionName: "微山县",
            level: 2,
          },
          {
            regionId: 370827,
            parentId: 3708,
            regionName: "鱼台县",
            level: 2,
          },
          {
            regionId: 370828,
            parentId: 3708,
            regionName: "金乡县",
            level: 2,
          },
          {
            regionId: 370829,
            parentId: 3708,
            regionName: "嘉祥县",
            level: 2,
          },
          {
            regionId: 370830,
            parentId: 3708,
            regionName: "汶上县",
            level: 2,
          },
          {
            regionId: 370831,
            parentId: 3708,
            regionName: "泗水县",
            level: 2,
          },
          {
            regionId: 370832,
            parentId: 3708,
            regionName: "梁山县",
            level: 2,
          },
          {
            regionId: 370881,
            parentId: 3708,
            regionName: "曲阜市",
            level: 2,
          },
          {
            regionId: 370883,
            parentId: 3708,
            regionName: "邹城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3709,
        parentId: 37,
        regionName: "泰安市",
        level: 1,
        children: [
          {
            regionId: 370902,
            parentId: 3709,
            regionName: "泰山区",
            level: 2,
          },
          {
            regionId: 370911,
            parentId: 3709,
            regionName: "岱岳区",
            level: 2,
          },
          {
            regionId: 370921,
            parentId: 3709,
            regionName: "宁阳县",
            level: 2,
          },
          {
            regionId: 370923,
            parentId: 3709,
            regionName: "东平县",
            level: 2,
          },
          {
            regionId: 370982,
            parentId: 3709,
            regionName: "新泰市",
            level: 2,
          },
          {
            regionId: 370983,
            parentId: 3709,
            regionName: "肥城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3710,
        parentId: 37,
        regionName: "威海市",
        level: 1,
        children: [
          {
            regionId: 371002,
            parentId: 3710,
            regionName: "环翠区",
            level: 2,
          },
          {
            regionId: 371003,
            parentId: 3710,
            regionName: "文登区",
            level: 2,
          },
          {
            regionId: 371082,
            parentId: 3710,
            regionName: "荣成市",
            level: 2,
          },
          {
            regionId: 371083,
            parentId: 3710,
            regionName: "乳山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3711,
        parentId: 37,
        regionName: "日照市",
        level: 1,
        children: [
          {
            regionId: 371102,
            parentId: 3711,
            regionName: "东港区",
            level: 2,
          },
          {
            regionId: 371103,
            parentId: 3711,
            regionName: "岚山区",
            level: 2,
          },
          {
            regionId: 371121,
            parentId: 3711,
            regionName: "五莲县",
            level: 2,
          },
          {
            regionId: 371122,
            parentId: 3711,
            regionName: "莒县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3713,
        parentId: 37,
        regionName: "临沂市",
        level: 1,
        children: [
          {
            regionId: 371302,
            parentId: 3713,
            regionName: "兰山区",
            level: 2,
          },
          {
            regionId: 371311,
            parentId: 3713,
            regionName: "罗庄区",
            level: 2,
          },
          {
            regionId: 371312,
            parentId: 3713,
            regionName: "河东区",
            level: 2,
          },
          {
            regionId: 371321,
            parentId: 3713,
            regionName: "沂南县",
            level: 2,
          },
          {
            regionId: 371322,
            parentId: 3713,
            regionName: "郯城县",
            level: 2,
          },
          {
            regionId: 371323,
            parentId: 3713,
            regionName: "沂水县",
            level: 2,
          },
          {
            regionId: 371324,
            parentId: 3713,
            regionName: "兰陵县",
            level: 2,
          },
          {
            regionId: 371325,
            parentId: 3713,
            regionName: "费县",
            level: 2,
          },
          {
            regionId: 371326,
            parentId: 3713,
            regionName: "平邑县",
            level: 2,
          },
          {
            regionId: 371327,
            parentId: 3713,
            regionName: "莒南县",
            level: 2,
          },
          {
            regionId: 371328,
            parentId: 3713,
            regionName: "蒙阴县",
            level: 2,
          },
          {
            regionId: 371329,
            parentId: 3713,
            regionName: "临沭县",
            level: 2,
          },
        ],
      },
      {
        regionId: 3714,
        parentId: 37,
        regionName: "德州市",
        level: 1,
        children: [
          {
            regionId: 371402,
            parentId: 3714,
            regionName: "德城区",
            level: 2,
          },
          {
            regionId: 371403,
            parentId: 3714,
            regionName: "陵城区",
            level: 2,
          },
          {
            regionId: 371422,
            parentId: 3714,
            regionName: "宁津县",
            level: 2,
          },
          {
            regionId: 371423,
            parentId: 3714,
            regionName: "庆云县",
            level: 2,
          },
          {
            regionId: 371424,
            parentId: 3714,
            regionName: "临邑县",
            level: 2,
          },
          {
            regionId: 371425,
            parentId: 3714,
            regionName: "齐河县",
            level: 2,
          },
          {
            regionId: 371426,
            parentId: 3714,
            regionName: "平原县",
            level: 2,
          },
          {
            regionId: 371427,
            parentId: 3714,
            regionName: "夏津县",
            level: 2,
          },
          {
            regionId: 371428,
            parentId: 3714,
            regionName: "武城县",
            level: 2,
          },
          {
            regionId: 371481,
            parentId: 3714,
            regionName: "乐陵市",
            level: 2,
          },
          {
            regionId: 371482,
            parentId: 3714,
            regionName: "禹城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3715,
        parentId: 37,
        regionName: "聊城市",
        level: 1,
        children: [
          {
            regionId: 371502,
            parentId: 3715,
            regionName: "东昌府区",
            level: 2,
          },
          {
            regionId: 371503,
            parentId: 3715,
            regionName: "茌平区",
            level: 2,
          },
          {
            regionId: 371521,
            parentId: 3715,
            regionName: "阳谷县",
            level: 2,
          },
          {
            regionId: 371522,
            parentId: 3715,
            regionName: "莘县",
            level: 2,
          },
          {
            regionId: 371524,
            parentId: 3715,
            regionName: "东阿县",
            level: 2,
          },
          {
            regionId: 371525,
            parentId: 3715,
            regionName: "冠县",
            level: 2,
          },
          {
            regionId: 371526,
            parentId: 3715,
            regionName: "高唐县",
            level: 2,
          },
          {
            regionId: 371581,
            parentId: 3715,
            regionName: "临清市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3716,
        parentId: 37,
        regionName: "滨州市",
        level: 1,
        children: [
          {
            regionId: 371602,
            parentId: 3716,
            regionName: "滨城区",
            level: 2,
          },
          {
            regionId: 371603,
            parentId: 3716,
            regionName: "沾化区",
            level: 2,
          },
          {
            regionId: 371621,
            parentId: 3716,
            regionName: "惠民县",
            level: 2,
          },
          {
            regionId: 371622,
            parentId: 3716,
            regionName: "阳信县",
            level: 2,
          },
          {
            regionId: 371623,
            parentId: 3716,
            regionName: "无棣县",
            level: 2,
          },
          {
            regionId: 371625,
            parentId: 3716,
            regionName: "博兴县",
            level: 2,
          },
          {
            regionId: 371681,
            parentId: 3716,
            regionName: "邹平市",
            level: 2,
          },
        ],
      },
      {
        regionId: 3717,
        parentId: 37,
        regionName: "菏泽市",
        level: 1,
        children: [
          {
            regionId: 371702,
            parentId: 3717,
            regionName: "牡丹区",
            level: 2,
          },
          {
            regionId: 371703,
            parentId: 3717,
            regionName: "定陶区",
            level: 2,
          },
          {
            regionId: 371721,
            parentId: 3717,
            regionName: "曹县",
            level: 2,
          },
          {
            regionId: 371722,
            parentId: 3717,
            regionName: "单县",
            level: 2,
          },
          {
            regionId: 371723,
            parentId: 3717,
            regionName: "成武县",
            level: 2,
          },
          {
            regionId: 371724,
            parentId: 3717,
            regionName: "巨野县",
            level: 2,
          },
          {
            regionId: 371725,
            parentId: 3717,
            regionName: "郓城县",
            level: 2,
          },
          {
            regionId: 371726,
            parentId: 3717,
            regionName: "鄄城县",
            level: 2,
          },
          {
            regionId: 371728,
            parentId: 3717,
            regionName: "东明县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 41,
    parentId: 0,
    regionName: "河南省",
    level: 0,
    children: [
      {
        regionId: 4101,
        parentId: 41,
        regionName: "郑州市",
        level: 1,
        children: [
          {
            regionId: 410102,
            parentId: 4101,
            regionName: "中原区",
            level: 2,
          },
          {
            regionId: 410103,
            parentId: 4101,
            regionName: "二七区",
            level: 2,
          },
          {
            regionId: 410104,
            parentId: 4101,
            regionName: "管城回族区",
            level: 2,
          },
          {
            regionId: 410105,
            parentId: 4101,
            regionName: "金水区",
            level: 2,
          },
          {
            regionId: 410106,
            parentId: 4101,
            regionName: "上街区",
            level: 2,
          },
          {
            regionId: 410108,
            parentId: 4101,
            regionName: "惠济区",
            level: 2,
          },
          {
            regionId: 410122,
            parentId: 4101,
            regionName: "中牟县",
            level: 2,
          },
          {
            regionId: 410181,
            parentId: 4101,
            regionName: "巩义市",
            level: 2,
          },
          {
            regionId: 410182,
            parentId: 4101,
            regionName: "荥阳市",
            level: 2,
          },
          {
            regionId: 410183,
            parentId: 4101,
            regionName: "新密市",
            level: 2,
          },
          {
            regionId: 410184,
            parentId: 4101,
            regionName: "新郑市",
            level: 2,
          },
          {
            regionId: 410185,
            parentId: 4101,
            regionName: "登封市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4102,
        parentId: 41,
        regionName: "开封市",
        level: 1,
        children: [
          {
            regionId: 410202,
            parentId: 4102,
            regionName: "龙亭区",
            level: 2,
          },
          {
            regionId: 410203,
            parentId: 4102,
            regionName: "顺河回族区",
            level: 2,
          },
          {
            regionId: 410204,
            parentId: 4102,
            regionName: "鼓楼区",
            level: 2,
          },
          {
            regionId: 410205,
            parentId: 4102,
            regionName: "禹王台区",
            level: 2,
          },
          {
            regionId: 410212,
            parentId: 4102,
            regionName: "祥符区",
            level: 2,
          },
          {
            regionId: 410221,
            parentId: 4102,
            regionName: "杞县",
            level: 2,
          },
          {
            regionId: 410222,
            parentId: 4102,
            regionName: "通许县",
            level: 2,
          },
          {
            regionId: 410223,
            parentId: 4102,
            regionName: "尉氏县",
            level: 2,
          },
          {
            regionId: 410225,
            parentId: 4102,
            regionName: "兰考县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4103,
        parentId: 41,
        regionName: "洛阳市",
        level: 1,
        children: [
          {
            regionId: 410302,
            parentId: 4103,
            regionName: "老城区",
            level: 2,
          },
          {
            regionId: 410303,
            parentId: 4103,
            regionName: "西工区",
            level: 2,
          },
          {
            regionId: 410304,
            parentId: 4103,
            regionName: "瀍河回族区",
            level: 2,
          },
          {
            regionId: 410305,
            parentId: 4103,
            regionName: "涧西区",
            level: 2,
          },
          {
            regionId: 410307,
            parentId: 4103,
            regionName: "偃师区",
            level: 2,
          },
          {
            regionId: 410308,
            parentId: 4103,
            regionName: "孟津区",
            level: 2,
          },
          {
            regionId: 410311,
            parentId: 4103,
            regionName: "洛龙区",
            level: 2,
          },
          {
            regionId: 410323,
            parentId: 4103,
            regionName: "新安县",
            level: 2,
          },
          {
            regionId: 410324,
            parentId: 4103,
            regionName: "栾川县",
            level: 2,
          },
          {
            regionId: 410325,
            parentId: 4103,
            regionName: "嵩县",
            level: 2,
          },
          {
            regionId: 410326,
            parentId: 4103,
            regionName: "汝阳县",
            level: 2,
          },
          {
            regionId: 410327,
            parentId: 4103,
            regionName: "宜阳县",
            level: 2,
          },
          {
            regionId: 410328,
            parentId: 4103,
            regionName: "洛宁县",
            level: 2,
          },
          {
            regionId: 410329,
            parentId: 4103,
            regionName: "伊川县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4104,
        parentId: 41,
        regionName: "平顶山市",
        level: 1,
        children: [
          {
            regionId: 410402,
            parentId: 4104,
            regionName: "新华区",
            level: 2,
          },
          {
            regionId: 410403,
            parentId: 4104,
            regionName: "卫东区",
            level: 2,
          },
          {
            regionId: 410404,
            parentId: 4104,
            regionName: "石龙区",
            level: 2,
          },
          {
            regionId: 410411,
            parentId: 4104,
            regionName: "湛河区",
            level: 2,
          },
          {
            regionId: 410421,
            parentId: 4104,
            regionName: "宝丰县",
            level: 2,
          },
          {
            regionId: 410422,
            parentId: 4104,
            regionName: "叶县",
            level: 2,
          },
          {
            regionId: 410423,
            parentId: 4104,
            regionName: "鲁山县",
            level: 2,
          },
          {
            regionId: 410425,
            parentId: 4104,
            regionName: "郏县",
            level: 2,
          },
          {
            regionId: 410481,
            parentId: 4104,
            regionName: "舞钢市",
            level: 2,
          },
          {
            regionId: 410482,
            parentId: 4104,
            regionName: "汝州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4105,
        parentId: 41,
        regionName: "安阳市",
        level: 1,
        children: [
          {
            regionId: 410502,
            parentId: 4105,
            regionName: "文峰区",
            level: 2,
          },
          {
            regionId: 410503,
            parentId: 4105,
            regionName: "北关区",
            level: 2,
          },
          {
            regionId: 410505,
            parentId: 4105,
            regionName: "殷都区",
            level: 2,
          },
          {
            regionId: 410506,
            parentId: 4105,
            regionName: "龙安区",
            level: 2,
          },
          {
            regionId: 410522,
            parentId: 4105,
            regionName: "安阳县",
            level: 2,
          },
          {
            regionId: 410523,
            parentId: 4105,
            regionName: "汤阴县",
            level: 2,
          },
          {
            regionId: 410526,
            parentId: 4105,
            regionName: "滑县",
            level: 2,
          },
          {
            regionId: 410527,
            parentId: 4105,
            regionName: "内黄县",
            level: 2,
          },
          {
            regionId: 410581,
            parentId: 4105,
            regionName: "林州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4106,
        parentId: 41,
        regionName: "鹤壁市",
        level: 1,
        children: [
          {
            regionId: 410602,
            parentId: 4106,
            regionName: "鹤山区",
            level: 2,
          },
          {
            regionId: 410603,
            parentId: 4106,
            regionName: "山城区",
            level: 2,
          },
          {
            regionId: 410611,
            parentId: 4106,
            regionName: "淇滨区",
            level: 2,
          },
          {
            regionId: 410621,
            parentId: 4106,
            regionName: "浚县",
            level: 2,
          },
          {
            regionId: 410622,
            parentId: 4106,
            regionName: "淇县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4107,
        parentId: 41,
        regionName: "新乡市",
        level: 1,
        children: [
          {
            regionId: 410702,
            parentId: 4107,
            regionName: "红旗区",
            level: 2,
          },
          {
            regionId: 410703,
            parentId: 4107,
            regionName: "卫滨区",
            level: 2,
          },
          {
            regionId: 410704,
            parentId: 4107,
            regionName: "凤泉区",
            level: 2,
          },
          {
            regionId: 410711,
            parentId: 4107,
            regionName: "牧野区",
            level: 2,
          },
          {
            regionId: 410721,
            parentId: 4107,
            regionName: "新乡县",
            level: 2,
          },
          {
            regionId: 410724,
            parentId: 4107,
            regionName: "获嘉县",
            level: 2,
          },
          {
            regionId: 410725,
            parentId: 4107,
            regionName: "原阳县",
            level: 2,
          },
          {
            regionId: 410726,
            parentId: 4107,
            regionName: "延津县",
            level: 2,
          },
          {
            regionId: 410727,
            parentId: 4107,
            regionName: "封丘县",
            level: 2,
          },
          {
            regionId: 410781,
            parentId: 4107,
            regionName: "卫辉市",
            level: 2,
          },
          {
            regionId: 410782,
            parentId: 4107,
            regionName: "辉县市",
            level: 2,
          },
          {
            regionId: 410783,
            parentId: 4107,
            regionName: "长垣市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4108,
        parentId: 41,
        regionName: "焦作市",
        level: 1,
        children: [
          {
            regionId: 410802,
            parentId: 4108,
            regionName: "解放区",
            level: 2,
          },
          {
            regionId: 410803,
            parentId: 4108,
            regionName: "中站区",
            level: 2,
          },
          {
            regionId: 410804,
            parentId: 4108,
            regionName: "马村区",
            level: 2,
          },
          {
            regionId: 410811,
            parentId: 4108,
            regionName: "山阳区",
            level: 2,
          },
          {
            regionId: 410821,
            parentId: 4108,
            regionName: "修武县",
            level: 2,
          },
          {
            regionId: 410822,
            parentId: 4108,
            regionName: "博爱县",
            level: 2,
          },
          {
            regionId: 410823,
            parentId: 4108,
            regionName: "武陟县",
            level: 2,
          },
          {
            regionId: 410825,
            parentId: 4108,
            regionName: "温县",
            level: 2,
          },
          {
            regionId: 410882,
            parentId: 4108,
            regionName: "沁阳市",
            level: 2,
          },
          {
            regionId: 410883,
            parentId: 4108,
            regionName: "孟州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4109,
        parentId: 41,
        regionName: "濮阳市",
        level: 1,
        children: [
          {
            regionId: 410902,
            parentId: 4109,
            regionName: "华龙区",
            level: 2,
          },
          {
            regionId: 410922,
            parentId: 4109,
            regionName: "清丰县",
            level: 2,
          },
          {
            regionId: 410923,
            parentId: 4109,
            regionName: "南乐县",
            level: 2,
          },
          {
            regionId: 410926,
            parentId: 4109,
            regionName: "范县",
            level: 2,
          },
          {
            regionId: 410927,
            parentId: 4109,
            regionName: "台前县",
            level: 2,
          },
          {
            regionId: 410928,
            parentId: 4109,
            regionName: "濮阳县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4110,
        parentId: 41,
        regionName: "许昌市",
        level: 1,
        children: [
          {
            regionId: 411002,
            parentId: 4110,
            regionName: "魏都区",
            level: 2,
          },
          {
            regionId: 411003,
            parentId: 4110,
            regionName: "建安区",
            level: 2,
          },
          {
            regionId: 411024,
            parentId: 4110,
            regionName: "鄢陵县",
            level: 2,
          },
          {
            regionId: 411025,
            parentId: 4110,
            regionName: "襄城县",
            level: 2,
          },
          {
            regionId: 411081,
            parentId: 4110,
            regionName: "禹州市",
            level: 2,
          },
          {
            regionId: 411082,
            parentId: 4110,
            regionName: "长葛市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4111,
        parentId: 41,
        regionName: "漯河市",
        level: 1,
        children: [
          {
            regionId: 411102,
            parentId: 4111,
            regionName: "源汇区",
            level: 2,
          },
          {
            regionId: 411103,
            parentId: 4111,
            regionName: "郾城区",
            level: 2,
          },
          {
            regionId: 411104,
            parentId: 4111,
            regionName: "召陵区",
            level: 2,
          },
          {
            regionId: 411121,
            parentId: 4111,
            regionName: "舞阳县",
            level: 2,
          },
          {
            regionId: 411122,
            parentId: 4111,
            regionName: "临颍县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4112,
        parentId: 41,
        regionName: "三门峡市",
        level: 1,
        children: [
          {
            regionId: 411202,
            parentId: 4112,
            regionName: "湖滨区",
            level: 2,
          },
          {
            regionId: 411203,
            parentId: 4112,
            regionName: "陕州区",
            level: 2,
          },
          {
            regionId: 411221,
            parentId: 4112,
            regionName: "渑池县",
            level: 2,
          },
          {
            regionId: 411224,
            parentId: 4112,
            regionName: "卢氏县",
            level: 2,
          },
          {
            regionId: 411281,
            parentId: 4112,
            regionName: "义马市",
            level: 2,
          },
          {
            regionId: 411282,
            parentId: 4112,
            regionName: "灵宝市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4113,
        parentId: 41,
        regionName: "南阳市",
        level: 1,
        children: [
          {
            regionId: 411302,
            parentId: 4113,
            regionName: "宛城区",
            level: 2,
          },
          {
            regionId: 411303,
            parentId: 4113,
            regionName: "卧龙区",
            level: 2,
          },
          {
            regionId: 411321,
            parentId: 4113,
            regionName: "南召县",
            level: 2,
          },
          {
            regionId: 411322,
            parentId: 4113,
            regionName: "方城县",
            level: 2,
          },
          {
            regionId: 411323,
            parentId: 4113,
            regionName: "西峡县",
            level: 2,
          },
          {
            regionId: 411324,
            parentId: 4113,
            regionName: "镇平县",
            level: 2,
          },
          {
            regionId: 411325,
            parentId: 4113,
            regionName: "内乡县",
            level: 2,
          },
          {
            regionId: 411326,
            parentId: 4113,
            regionName: "淅川县",
            level: 2,
          },
          {
            regionId: 411327,
            parentId: 4113,
            regionName: "社旗县",
            level: 2,
          },
          {
            regionId: 411328,
            parentId: 4113,
            regionName: "唐河县",
            level: 2,
          },
          {
            regionId: 411329,
            parentId: 4113,
            regionName: "新野县",
            level: 2,
          },
          {
            regionId: 411330,
            parentId: 4113,
            regionName: "桐柏县",
            level: 2,
          },
          {
            regionId: 411381,
            parentId: 4113,
            regionName: "邓州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4114,
        parentId: 41,
        regionName: "商丘市",
        level: 1,
        children: [
          {
            regionId: 411402,
            parentId: 4114,
            regionName: "梁园区",
            level: 2,
          },
          {
            regionId: 411403,
            parentId: 4114,
            regionName: "睢阳区",
            level: 2,
          },
          {
            regionId: 411421,
            parentId: 4114,
            regionName: "民权县",
            level: 2,
          },
          {
            regionId: 411422,
            parentId: 4114,
            regionName: "睢县",
            level: 2,
          },
          {
            regionId: 411423,
            parentId: 4114,
            regionName: "宁陵县",
            level: 2,
          },
          {
            regionId: 411424,
            parentId: 4114,
            regionName: "柘城县",
            level: 2,
          },
          {
            regionId: 411425,
            parentId: 4114,
            regionName: "虞城县",
            level: 2,
          },
          {
            regionId: 411426,
            parentId: 4114,
            regionName: "夏邑县",
            level: 2,
          },
          {
            regionId: 411481,
            parentId: 4114,
            regionName: "永城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4115,
        parentId: 41,
        regionName: "信阳市",
        level: 1,
        children: [
          {
            regionId: 411502,
            parentId: 4115,
            regionName: "浉河区",
            level: 2,
          },
          {
            regionId: 411503,
            parentId: 4115,
            regionName: "平桥区",
            level: 2,
          },
          {
            regionId: 411521,
            parentId: 4115,
            regionName: "罗山县",
            level: 2,
          },
          {
            regionId: 411522,
            parentId: 4115,
            regionName: "光山县",
            level: 2,
          },
          {
            regionId: 411523,
            parentId: 4115,
            regionName: "新县",
            level: 2,
          },
          {
            regionId: 411524,
            parentId: 4115,
            regionName: "商城县",
            level: 2,
          },
          {
            regionId: 411525,
            parentId: 4115,
            regionName: "固始县",
            level: 2,
          },
          {
            regionId: 411526,
            parentId: 4115,
            regionName: "潢川县",
            level: 2,
          },
          {
            regionId: 411527,
            parentId: 4115,
            regionName: "淮滨县",
            level: 2,
          },
          {
            regionId: 411528,
            parentId: 4115,
            regionName: "息县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4116,
        parentId: 41,
        regionName: "周口市",
        level: 1,
        children: [
          {
            regionId: 411602,
            parentId: 4116,
            regionName: "川汇区",
            level: 2,
          },
          {
            regionId: 411603,
            parentId: 4116,
            regionName: "淮阳区",
            level: 2,
          },
          {
            regionId: 411621,
            parentId: 4116,
            regionName: "扶沟县",
            level: 2,
          },
          {
            regionId: 411622,
            parentId: 4116,
            regionName: "西华县",
            level: 2,
          },
          {
            regionId: 411623,
            parentId: 4116,
            regionName: "商水县",
            level: 2,
          },
          {
            regionId: 411624,
            parentId: 4116,
            regionName: "沈丘县",
            level: 2,
          },
          {
            regionId: 411625,
            parentId: 4116,
            regionName: "郸城县",
            level: 2,
          },
          {
            regionId: 411627,
            parentId: 4116,
            regionName: "太康县",
            level: 2,
          },
          {
            regionId: 411628,
            parentId: 4116,
            regionName: "鹿邑县",
            level: 2,
          },
          {
            regionId: 411681,
            parentId: 4116,
            regionName: "项城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4117,
        parentId: 41,
        regionName: "驻马店市",
        level: 1,
        children: [
          {
            regionId: 411702,
            parentId: 4117,
            regionName: "驿城区",
            level: 2,
          },
          {
            regionId: 411721,
            parentId: 4117,
            regionName: "西平县",
            level: 2,
          },
          {
            regionId: 411722,
            parentId: 4117,
            regionName: "上蔡县",
            level: 2,
          },
          {
            regionId: 411723,
            parentId: 4117,
            regionName: "平舆县",
            level: 2,
          },
          {
            regionId: 411724,
            parentId: 4117,
            regionName: "正阳县",
            level: 2,
          },
          {
            regionId: 411725,
            parentId: 4117,
            regionName: "确山县",
            level: 2,
          },
          {
            regionId: 411726,
            parentId: 4117,
            regionName: "泌阳县",
            level: 2,
          },
          {
            regionId: 411727,
            parentId: 4117,
            regionName: "汝南县",
            level: 2,
          },
          {
            regionId: 411728,
            parentId: 4117,
            regionName: "遂平县",
            level: 2,
          },
          {
            regionId: 411729,
            parentId: 4117,
            regionName: "新蔡县",
            level: 2,
          },
        ],
      },
      {
        regionId: 419001,
        parentId: 41,
        regionName: "济源市",
        level: 1,
        children: [
          {
            regionId: 419001000,
            parentId: 419001,
            regionName: "济源市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 42,
    parentId: 0,
    regionName: "湖北省",
    level: 0,
    children: [
      {
        regionId: 4201,
        parentId: 42,
        regionName: "武汉市",
        level: 1,
        children: [
          {
            regionId: 420102,
            parentId: 4201,
            regionName: "江岸区",
            level: 2,
          },
          {
            regionId: 420103,
            parentId: 4201,
            regionName: "江汉区",
            level: 2,
          },
          {
            regionId: 420104,
            parentId: 4201,
            regionName: "硚口区",
            level: 2,
          },
          {
            regionId: 420105,
            parentId: 4201,
            regionName: "汉阳区",
            level: 2,
          },
          {
            regionId: 420106,
            parentId: 4201,
            regionName: "武昌区",
            level: 2,
          },
          {
            regionId: 420107,
            parentId: 4201,
            regionName: "青山区",
            level: 2,
          },
          {
            regionId: 420111,
            parentId: 4201,
            regionName: "洪山区",
            level: 2,
          },
          {
            regionId: 420112,
            parentId: 4201,
            regionName: "东西湖区",
            level: 2,
          },
          {
            regionId: 420113,
            parentId: 4201,
            regionName: "汉南区",
            level: 2,
          },
          {
            regionId: 420114,
            parentId: 4201,
            regionName: "蔡甸区",
            level: 2,
          },
          {
            regionId: 420115,
            parentId: 4201,
            regionName: "江夏区",
            level: 2,
          },
          {
            regionId: 420116,
            parentId: 4201,
            regionName: "黄陂区",
            level: 2,
          },
          {
            regionId: 420117,
            parentId: 4201,
            regionName: "新洲区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4202,
        parentId: 42,
        regionName: "黄石市",
        level: 1,
        children: [
          {
            regionId: 420202,
            parentId: 4202,
            regionName: "黄石港区",
            level: 2,
          },
          {
            regionId: 420203,
            parentId: 4202,
            regionName: "西塞山区",
            level: 2,
          },
          {
            regionId: 420204,
            parentId: 4202,
            regionName: "下陆区",
            level: 2,
          },
          {
            regionId: 420205,
            parentId: 4202,
            regionName: "铁山区",
            level: 2,
          },
          {
            regionId: 420222,
            parentId: 4202,
            regionName: "阳新县",
            level: 2,
          },
          {
            regionId: 420281,
            parentId: 4202,
            regionName: "大冶市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4203,
        parentId: 42,
        regionName: "十堰市",
        level: 1,
        children: [
          {
            regionId: 420302,
            parentId: 4203,
            regionName: "茅箭区",
            level: 2,
          },
          {
            regionId: 420303,
            parentId: 4203,
            regionName: "张湾区",
            level: 2,
          },
          {
            regionId: 420304,
            parentId: 4203,
            regionName: "郧阳区",
            level: 2,
          },
          {
            regionId: 420322,
            parentId: 4203,
            regionName: "郧西县",
            level: 2,
          },
          {
            regionId: 420323,
            parentId: 4203,
            regionName: "竹山县",
            level: 2,
          },
          {
            regionId: 420324,
            parentId: 4203,
            regionName: "竹溪县",
            level: 2,
          },
          {
            regionId: 420325,
            parentId: 4203,
            regionName: "房县",
            level: 2,
          },
          {
            regionId: 420381,
            parentId: 4203,
            regionName: "丹江口市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4205,
        parentId: 42,
        regionName: "宜昌市",
        level: 1,
        children: [
          {
            regionId: 420502,
            parentId: 4205,
            regionName: "西陵区",
            level: 2,
          },
          {
            regionId: 420503,
            parentId: 4205,
            regionName: "伍家岗区",
            level: 2,
          },
          {
            regionId: 420504,
            parentId: 4205,
            regionName: "点军区",
            level: 2,
          },
          {
            regionId: 420505,
            parentId: 4205,
            regionName: "猇亭区",
            level: 2,
          },
          {
            regionId: 420506,
            parentId: 4205,
            regionName: "夷陵区",
            level: 2,
          },
          {
            regionId: 420525,
            parentId: 4205,
            regionName: "远安县",
            level: 2,
          },
          {
            regionId: 420526,
            parentId: 4205,
            regionName: "兴山县",
            level: 2,
          },
          {
            regionId: 420527,
            parentId: 4205,
            regionName: "秭归县",
            level: 2,
          },
          {
            regionId: 420528,
            parentId: 4205,
            regionName: "长阳土家族自治县",
            level: 2,
          },
          {
            regionId: 420529,
            parentId: 4205,
            regionName: "五峰土家族自治县",
            level: 2,
          },
          {
            regionId: 420581,
            parentId: 4205,
            regionName: "宜都市",
            level: 2,
          },
          {
            regionId: 420582,
            parentId: 4205,
            regionName: "当阳市",
            level: 2,
          },
          {
            regionId: 420583,
            parentId: 4205,
            regionName: "枝江市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4206,
        parentId: 42,
        regionName: "襄阳市",
        level: 1,
        children: [
          {
            regionId: 420602,
            parentId: 4206,
            regionName: "襄城区",
            level: 2,
          },
          {
            regionId: 420606,
            parentId: 4206,
            regionName: "樊城区",
            level: 2,
          },
          {
            regionId: 420607,
            parentId: 4206,
            regionName: "襄州区",
            level: 2,
          },
          {
            regionId: 420624,
            parentId: 4206,
            regionName: "南漳县",
            level: 2,
          },
          {
            regionId: 420625,
            parentId: 4206,
            regionName: "谷城县",
            level: 2,
          },
          {
            regionId: 420626,
            parentId: 4206,
            regionName: "保康县",
            level: 2,
          },
          {
            regionId: 420682,
            parentId: 4206,
            regionName: "老河口市",
            level: 2,
          },
          {
            regionId: 420683,
            parentId: 4206,
            regionName: "枣阳市",
            level: 2,
          },
          {
            regionId: 420684,
            parentId: 4206,
            regionName: "宜城市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4207,
        parentId: 42,
        regionName: "鄂州市",
        level: 1,
        children: [
          {
            regionId: 420702,
            parentId: 4207,
            regionName: "梁子湖区",
            level: 2,
          },
          {
            regionId: 420703,
            parentId: 4207,
            regionName: "华容区",
            level: 2,
          },
          {
            regionId: 420704,
            parentId: 4207,
            regionName: "鄂城区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4208,
        parentId: 42,
        regionName: "荆门市",
        level: 1,
        children: [
          {
            regionId: 420802,
            parentId: 4208,
            regionName: "东宝区",
            level: 2,
          },
          {
            regionId: 420804,
            parentId: 4208,
            regionName: "掇刀区",
            level: 2,
          },
          {
            regionId: 420822,
            parentId: 4208,
            regionName: "沙洋县",
            level: 2,
          },
          {
            regionId: 420881,
            parentId: 4208,
            regionName: "钟祥市",
            level: 2,
          },
          {
            regionId: 420882,
            parentId: 4208,
            regionName: "京山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4209,
        parentId: 42,
        regionName: "孝感市",
        level: 1,
        children: [
          {
            regionId: 420902,
            parentId: 4209,
            regionName: "孝南区",
            level: 2,
          },
          {
            regionId: 420921,
            parentId: 4209,
            regionName: "孝昌县",
            level: 2,
          },
          {
            regionId: 420922,
            parentId: 4209,
            regionName: "大悟县",
            level: 2,
          },
          {
            regionId: 420923,
            parentId: 4209,
            regionName: "云梦县",
            level: 2,
          },
          {
            regionId: 420981,
            parentId: 4209,
            regionName: "应城市",
            level: 2,
          },
          {
            regionId: 420982,
            parentId: 4209,
            regionName: "安陆市",
            level: 2,
          },
          {
            regionId: 420984,
            parentId: 4209,
            regionName: "汉川市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4210,
        parentId: 42,
        regionName: "荆州市",
        level: 1,
        children: [
          {
            regionId: 421002,
            parentId: 4210,
            regionName: "沙市区",
            level: 2,
          },
          {
            regionId: 421003,
            parentId: 4210,
            regionName: "荆州区",
            level: 2,
          },
          {
            regionId: 421022,
            parentId: 4210,
            regionName: "公安县",
            level: 2,
          },
          {
            regionId: 421024,
            parentId: 4210,
            regionName: "江陵县",
            level: 2,
          },
          {
            regionId: 421081,
            parentId: 4210,
            regionName: "石首市",
            level: 2,
          },
          {
            regionId: 421083,
            parentId: 4210,
            regionName: "洪湖市",
            level: 2,
          },
          {
            regionId: 421087,
            parentId: 4210,
            regionName: "松滋市",
            level: 2,
          },
          {
            regionId: 421088,
            parentId: 4210,
            regionName: "监利市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4211,
        parentId: 42,
        regionName: "黄冈市",
        level: 1,
        children: [
          {
            regionId: 421102,
            parentId: 4211,
            regionName: "黄州区",
            level: 2,
          },
          {
            regionId: 421121,
            parentId: 4211,
            regionName: "团风县",
            level: 2,
          },
          {
            regionId: 421122,
            parentId: 4211,
            regionName: "红安县",
            level: 2,
          },
          {
            regionId: 421123,
            parentId: 4211,
            regionName: "罗田县",
            level: 2,
          },
          {
            regionId: 421124,
            parentId: 4211,
            regionName: "英山县",
            level: 2,
          },
          {
            regionId: 421125,
            parentId: 4211,
            regionName: "浠水县",
            level: 2,
          },
          {
            regionId: 421126,
            parentId: 4211,
            regionName: "蕲春县",
            level: 2,
          },
          {
            regionId: 421127,
            parentId: 4211,
            regionName: "黄梅县",
            level: 2,
          },
          {
            regionId: 421181,
            parentId: 4211,
            regionName: "麻城市",
            level: 2,
          },
          {
            regionId: 421182,
            parentId: 4211,
            regionName: "武穴市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4212,
        parentId: 42,
        regionName: "咸宁市",
        level: 1,
        children: [
          {
            regionId: 421202,
            parentId: 4212,
            regionName: "咸安区",
            level: 2,
          },
          {
            regionId: 421221,
            parentId: 4212,
            regionName: "嘉鱼县",
            level: 2,
          },
          {
            regionId: 421222,
            parentId: 4212,
            regionName: "通城县",
            level: 2,
          },
          {
            regionId: 421223,
            parentId: 4212,
            regionName: "崇阳县",
            level: 2,
          },
          {
            regionId: 421224,
            parentId: 4212,
            regionName: "通山县",
            level: 2,
          },
          {
            regionId: 421281,
            parentId: 4212,
            regionName: "赤壁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4213,
        parentId: 42,
        regionName: "随州市",
        level: 1,
        children: [
          {
            regionId: 421303,
            parentId: 4213,
            regionName: "曾都区",
            level: 2,
          },
          {
            regionId: 421321,
            parentId: 4213,
            regionName: "随县",
            level: 2,
          },
          {
            regionId: 421381,
            parentId: 4213,
            regionName: "广水市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4228,
        parentId: 42,
        regionName: "恩施土家族苗族自治州",
        level: 1,
        children: [
          {
            regionId: 422801,
            parentId: 4228,
            regionName: "恩施市",
            level: 2,
          },
          {
            regionId: 422802,
            parentId: 4228,
            regionName: "利川市",
            level: 2,
          },
          {
            regionId: 422822,
            parentId: 4228,
            regionName: "建始县",
            level: 2,
          },
          {
            regionId: 422823,
            parentId: 4228,
            regionName: "巴东县",
            level: 2,
          },
          {
            regionId: 422825,
            parentId: 4228,
            regionName: "宣恩县",
            level: 2,
          },
          {
            regionId: 422826,
            parentId: 4228,
            regionName: "咸丰县",
            level: 2,
          },
          {
            regionId: 422827,
            parentId: 4228,
            regionName: "来凤县",
            level: 2,
          },
          {
            regionId: 422828,
            parentId: 4228,
            regionName: "鹤峰县",
            level: 2,
          },
        ],
      },
      {
        regionId: 429004,
        parentId: 42,
        regionName: "仙桃市",
        level: 1,
        children: [
          {
            regionId: 429004000,
            parentId: 429004,
            regionName: "仙桃市",
            level: 2,
          },
        ],
      },
      {
        regionId: 429005,
        parentId: 42,
        regionName: "潜江市",
        level: 1,
        children: [
          {
            regionId: 429005000,
            parentId: 429005,
            regionName: "潜江市",
            level: 2,
          },
        ],
      },
      {
        regionId: 429006,
        parentId: 42,
        regionName: "天门市",
        level: 1,
        children: [
          {
            regionId: 429006000,
            parentId: 429006,
            regionName: "天门市",
            level: 2,
          },
        ],
      },
      {
        regionId: 429021,
        parentId: 42,
        regionName: "神农架林区",
        level: 1,
        children: [
          {
            regionId: 429021000,
            parentId: 429021,
            regionName: "神农架林区",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 43,
    parentId: 0,
    regionName: "湖南省",
    level: 0,
    children: [
      {
        regionId: 4301,
        parentId: 43,
        regionName: "长沙市",
        level: 1,
        children: [
          {
            regionId: 430102,
            parentId: 4301,
            regionName: "芙蓉区",
            level: 2,
          },
          {
            regionId: 430103,
            parentId: 4301,
            regionName: "天心区",
            level: 2,
          },
          {
            regionId: 430104,
            parentId: 4301,
            regionName: "岳麓区",
            level: 2,
          },
          {
            regionId: 430105,
            parentId: 4301,
            regionName: "开福区",
            level: 2,
          },
          {
            regionId: 430111,
            parentId: 4301,
            regionName: "雨花区",
            level: 2,
          },
          {
            regionId: 430112,
            parentId: 4301,
            regionName: "望城区",
            level: 2,
          },
          {
            regionId: 430121,
            parentId: 4301,
            regionName: "长沙县",
            level: 2,
          },
          {
            regionId: 430181,
            parentId: 4301,
            regionName: "浏阳市",
            level: 2,
          },
          {
            regionId: 430182,
            parentId: 4301,
            regionName: "宁乡市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4302,
        parentId: 43,
        regionName: "株洲市",
        level: 1,
        children: [
          {
            regionId: 430202,
            parentId: 4302,
            regionName: "荷塘区",
            level: 2,
          },
          {
            regionId: 430203,
            parentId: 4302,
            regionName: "芦淞区",
            level: 2,
          },
          {
            regionId: 430204,
            parentId: 4302,
            regionName: "石峰区",
            level: 2,
          },
          {
            regionId: 430211,
            parentId: 4302,
            regionName: "天元区",
            level: 2,
          },
          {
            regionId: 430212,
            parentId: 4302,
            regionName: "渌口区",
            level: 2,
          },
          {
            regionId: 430223,
            parentId: 4302,
            regionName: "攸县",
            level: 2,
          },
          {
            regionId: 430224,
            parentId: 4302,
            regionName: "茶陵县",
            level: 2,
          },
          {
            regionId: 430225,
            parentId: 4302,
            regionName: "炎陵县",
            level: 2,
          },
          {
            regionId: 430281,
            parentId: 4302,
            regionName: "醴陵市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4303,
        parentId: 43,
        regionName: "湘潭市",
        level: 1,
        children: [
          {
            regionId: 430302,
            parentId: 4303,
            regionName: "雨湖区",
            level: 2,
          },
          {
            regionId: 430304,
            parentId: 4303,
            regionName: "岳塘区",
            level: 2,
          },
          {
            regionId: 430321,
            parentId: 4303,
            regionName: "湘潭县",
            level: 2,
          },
          {
            regionId: 430381,
            parentId: 4303,
            regionName: "湘乡市",
            level: 2,
          },
          {
            regionId: 430382,
            parentId: 4303,
            regionName: "韶山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4304,
        parentId: 43,
        regionName: "衡阳市",
        level: 1,
        children: [
          {
            regionId: 430405,
            parentId: 4304,
            regionName: "珠晖区",
            level: 2,
          },
          {
            regionId: 430406,
            parentId: 4304,
            regionName: "雁峰区",
            level: 2,
          },
          {
            regionId: 430407,
            parentId: 4304,
            regionName: "石鼓区",
            level: 2,
          },
          {
            regionId: 430408,
            parentId: 4304,
            regionName: "蒸湘区",
            level: 2,
          },
          {
            regionId: 430412,
            parentId: 4304,
            regionName: "南岳区",
            level: 2,
          },
          {
            regionId: 430421,
            parentId: 4304,
            regionName: "衡阳县",
            level: 2,
          },
          {
            regionId: 430422,
            parentId: 4304,
            regionName: "衡南县",
            level: 2,
          },
          {
            regionId: 430423,
            parentId: 4304,
            regionName: "衡山县",
            level: 2,
          },
          {
            regionId: 430424,
            parentId: 4304,
            regionName: "衡东县",
            level: 2,
          },
          {
            regionId: 430426,
            parentId: 4304,
            regionName: "祁东县",
            level: 2,
          },
          {
            regionId: 430481,
            parentId: 4304,
            regionName: "耒阳市",
            level: 2,
          },
          {
            regionId: 430482,
            parentId: 4304,
            regionName: "常宁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4305,
        parentId: 43,
        regionName: "邵阳市",
        level: 1,
        children: [
          {
            regionId: 430502,
            parentId: 4305,
            regionName: "双清区",
            level: 2,
          },
          {
            regionId: 430503,
            parentId: 4305,
            regionName: "大祥区",
            level: 2,
          },
          {
            regionId: 430511,
            parentId: 4305,
            regionName: "北塔区",
            level: 2,
          },
          {
            regionId: 430522,
            parentId: 4305,
            regionName: "新邵县",
            level: 2,
          },
          {
            regionId: 430523,
            parentId: 4305,
            regionName: "邵阳县",
            level: 2,
          },
          {
            regionId: 430524,
            parentId: 4305,
            regionName: "隆回县",
            level: 2,
          },
          {
            regionId: 430525,
            parentId: 4305,
            regionName: "洞口县",
            level: 2,
          },
          {
            regionId: 430527,
            parentId: 4305,
            regionName: "绥宁县",
            level: 2,
          },
          {
            regionId: 430528,
            parentId: 4305,
            regionName: "新宁县",
            level: 2,
          },
          {
            regionId: 430529,
            parentId: 4305,
            regionName: "城步苗族自治县",
            level: 2,
          },
          {
            regionId: 430581,
            parentId: 4305,
            regionName: "武冈市",
            level: 2,
          },
          {
            regionId: 430582,
            parentId: 4305,
            regionName: "邵东市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4306,
        parentId: 43,
        regionName: "岳阳市",
        level: 1,
        children: [
          {
            regionId: 430602,
            parentId: 4306,
            regionName: "岳阳楼区",
            level: 2,
          },
          {
            regionId: 430603,
            parentId: 4306,
            regionName: "云溪区",
            level: 2,
          },
          {
            regionId: 430611,
            parentId: 4306,
            regionName: "君山区",
            level: 2,
          },
          {
            regionId: 430621,
            parentId: 4306,
            regionName: "岳阳县",
            level: 2,
          },
          {
            regionId: 430623,
            parentId: 4306,
            regionName: "华容县",
            level: 2,
          },
          {
            regionId: 430624,
            parentId: 4306,
            regionName: "湘阴县",
            level: 2,
          },
          {
            regionId: 430626,
            parentId: 4306,
            regionName: "平江县",
            level: 2,
          },
          {
            regionId: 430681,
            parentId: 4306,
            regionName: "汨罗市",
            level: 2,
          },
          {
            regionId: 430682,
            parentId: 4306,
            regionName: "临湘市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4307,
        parentId: 43,
        regionName: "常德市",
        level: 1,
        children: [
          {
            regionId: 430702,
            parentId: 4307,
            regionName: "武陵区",
            level: 2,
          },
          {
            regionId: 430703,
            parentId: 4307,
            regionName: "鼎城区",
            level: 2,
          },
          {
            regionId: 430721,
            parentId: 4307,
            regionName: "安乡县",
            level: 2,
          },
          {
            regionId: 430722,
            parentId: 4307,
            regionName: "汉寿县",
            level: 2,
          },
          {
            regionId: 430723,
            parentId: 4307,
            regionName: "澧县",
            level: 2,
          },
          {
            regionId: 430724,
            parentId: 4307,
            regionName: "临澧县",
            level: 2,
          },
          {
            regionId: 430725,
            parentId: 4307,
            regionName: "桃源县",
            level: 2,
          },
          {
            regionId: 430726,
            parentId: 4307,
            regionName: "石门县",
            level: 2,
          },
          {
            regionId: 430781,
            parentId: 4307,
            regionName: "津市市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4308,
        parentId: 43,
        regionName: "张家界市",
        level: 1,
        children: [
          {
            regionId: 430802,
            parentId: 4308,
            regionName: "永定区",
            level: 2,
          },
          {
            regionId: 430811,
            parentId: 4308,
            regionName: "武陵源区",
            level: 2,
          },
          {
            regionId: 430821,
            parentId: 4308,
            regionName: "慈利县",
            level: 2,
          },
          {
            regionId: 430822,
            parentId: 4308,
            regionName: "桑植县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4309,
        parentId: 43,
        regionName: "益阳市",
        level: 1,
        children: [
          {
            regionId: 430902,
            parentId: 4309,
            regionName: "资阳区",
            level: 2,
          },
          {
            regionId: 430903,
            parentId: 4309,
            regionName: "赫山区",
            level: 2,
          },
          {
            regionId: 430921,
            parentId: 4309,
            regionName: "南县",
            level: 2,
          },
          {
            regionId: 430922,
            parentId: 4309,
            regionName: "桃江县",
            level: 2,
          },
          {
            regionId: 430923,
            parentId: 4309,
            regionName: "安化县",
            level: 2,
          },
          {
            regionId: 430981,
            parentId: 4309,
            regionName: "沅江市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4310,
        parentId: 43,
        regionName: "郴州市",
        level: 1,
        children: [
          {
            regionId: 431002,
            parentId: 4310,
            regionName: "北湖区",
            level: 2,
          },
          {
            regionId: 431003,
            parentId: 4310,
            regionName: "苏仙区",
            level: 2,
          },
          {
            regionId: 431021,
            parentId: 4310,
            regionName: "桂阳县",
            level: 2,
          },
          {
            regionId: 431022,
            parentId: 4310,
            regionName: "宜章县",
            level: 2,
          },
          {
            regionId: 431023,
            parentId: 4310,
            regionName: "永兴县",
            level: 2,
          },
          {
            regionId: 431024,
            parentId: 4310,
            regionName: "嘉禾县",
            level: 2,
          },
          {
            regionId: 431025,
            parentId: 4310,
            regionName: "临武县",
            level: 2,
          },
          {
            regionId: 431026,
            parentId: 4310,
            regionName: "汝城县",
            level: 2,
          },
          {
            regionId: 431027,
            parentId: 4310,
            regionName: "桂东县",
            level: 2,
          },
          {
            regionId: 431028,
            parentId: 4310,
            regionName: "安仁县",
            level: 2,
          },
          {
            regionId: 431081,
            parentId: 4310,
            regionName: "资兴市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4311,
        parentId: 43,
        regionName: "永州市",
        level: 1,
        children: [
          {
            regionId: 431102,
            parentId: 4311,
            regionName: "零陵区",
            level: 2,
          },
          {
            regionId: 431103,
            parentId: 4311,
            regionName: "冷水滩区",
            level: 2,
          },
          {
            regionId: 431122,
            parentId: 4311,
            regionName: "东安县",
            level: 2,
          },
          {
            regionId: 431123,
            parentId: 4311,
            regionName: "双牌县",
            level: 2,
          },
          {
            regionId: 431124,
            parentId: 4311,
            regionName: "道县",
            level: 2,
          },
          {
            regionId: 431125,
            parentId: 4311,
            regionName: "江永县",
            level: 2,
          },
          {
            regionId: 431126,
            parentId: 4311,
            regionName: "宁远县",
            level: 2,
          },
          {
            regionId: 431127,
            parentId: 4311,
            regionName: "蓝山县",
            level: 2,
          },
          {
            regionId: 431128,
            parentId: 4311,
            regionName: "新田县",
            level: 2,
          },
          {
            regionId: 431129,
            parentId: 4311,
            regionName: "江华瑶族自治县",
            level: 2,
          },
          {
            regionId: 431181,
            parentId: 4311,
            regionName: "祁阳市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4312,
        parentId: 43,
        regionName: "怀化市",
        level: 1,
        children: [
          {
            regionId: 431202,
            parentId: 4312,
            regionName: "鹤城区",
            level: 2,
          },
          {
            regionId: 431221,
            parentId: 4312,
            regionName: "中方县",
            level: 2,
          },
          {
            regionId: 431222,
            parentId: 4312,
            regionName: "沅陵县",
            level: 2,
          },
          {
            regionId: 431223,
            parentId: 4312,
            regionName: "辰溪县",
            level: 2,
          },
          {
            regionId: 431224,
            parentId: 4312,
            regionName: "溆浦县",
            level: 2,
          },
          {
            regionId: 431225,
            parentId: 4312,
            regionName: "会同县",
            level: 2,
          },
          {
            regionId: 431226,
            parentId: 4312,
            regionName: "麻阳苗族自治县",
            level: 2,
          },
          {
            regionId: 431227,
            parentId: 4312,
            regionName: "新晃侗族自治县",
            level: 2,
          },
          {
            regionId: 431228,
            parentId: 4312,
            regionName: "芷江侗族自治县",
            level: 2,
          },
          {
            regionId: 431229,
            parentId: 4312,
            regionName: "靖州苗族侗族自治县",
            level: 2,
          },
          {
            regionId: 431230,
            parentId: 4312,
            regionName: "通道侗族自治县",
            level: 2,
          },
          {
            regionId: 431281,
            parentId: 4312,
            regionName: "洪江市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4313,
        parentId: 43,
        regionName: "娄底市",
        level: 1,
        children: [
          {
            regionId: 431302,
            parentId: 4313,
            regionName: "娄星区",
            level: 2,
          },
          {
            regionId: 431321,
            parentId: 4313,
            regionName: "双峰县",
            level: 2,
          },
          {
            regionId: 431322,
            parentId: 4313,
            regionName: "新化县",
            level: 2,
          },
          {
            regionId: 431381,
            parentId: 4313,
            regionName: "冷水江市",
            level: 2,
          },
          {
            regionId: 431382,
            parentId: 4313,
            regionName: "涟源市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4331,
        parentId: 43,
        regionName: "湘西土家族苗族自治州",
        level: 1,
        children: [
          {
            regionId: 433101,
            parentId: 4331,
            regionName: "吉首市",
            level: 2,
          },
          {
            regionId: 433122,
            parentId: 4331,
            regionName: "泸溪县",
            level: 2,
          },
          {
            regionId: 433123,
            parentId: 4331,
            regionName: "凤凰县",
            level: 2,
          },
          {
            regionId: 433124,
            parentId: 4331,
            regionName: "花垣县",
            level: 2,
          },
          {
            regionId: 433125,
            parentId: 4331,
            regionName: "保靖县",
            level: 2,
          },
          {
            regionId: 433126,
            parentId: 4331,
            regionName: "古丈县",
            level: 2,
          },
          {
            regionId: 433127,
            parentId: 4331,
            regionName: "永顺县",
            level: 2,
          },
          {
            regionId: 433130,
            parentId: 4331,
            regionName: "龙山县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 44,
    parentId: 0,
    regionName: "广东省",
    level: 0,
    children: [
      {
        regionId: 4401,
        parentId: 44,
        regionName: "广州市",
        level: 1,
        children: [
          {
            regionId: 440103,
            parentId: 4401,
            regionName: "荔湾区",
            level: 2,
          },
          {
            regionId: 440104,
            parentId: 4401,
            regionName: "越秀区",
            level: 2,
          },
          {
            regionId: 440105,
            parentId: 4401,
            regionName: "海珠区",
            level: 2,
          },
          {
            regionId: 440106,
            parentId: 4401,
            regionName: "天河区",
            level: 2,
          },
          {
            regionId: 440111,
            parentId: 4401,
            regionName: "白云区",
            level: 2,
          },
          {
            regionId: 440112,
            parentId: 4401,
            regionName: "黄埔区",
            level: 2,
          },
          {
            regionId: 440113,
            parentId: 4401,
            regionName: "番禺区",
            level: 2,
          },
          {
            regionId: 440114,
            parentId: 4401,
            regionName: "花都区",
            level: 2,
          },
          {
            regionId: 440115,
            parentId: 4401,
            regionName: "南沙区",
            level: 2,
          },
          {
            regionId: 440117,
            parentId: 4401,
            regionName: "从化区",
            level: 2,
          },
          {
            regionId: 440118,
            parentId: 4401,
            regionName: "增城区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4402,
        parentId: 44,
        regionName: "韶关市",
        level: 1,
        children: [
          {
            regionId: 440203,
            parentId: 4402,
            regionName: "武江区",
            level: 2,
          },
          {
            regionId: 440204,
            parentId: 4402,
            regionName: "浈江区",
            level: 2,
          },
          {
            regionId: 440205,
            parentId: 4402,
            regionName: "曲江区",
            level: 2,
          },
          {
            regionId: 440222,
            parentId: 4402,
            regionName: "始兴县",
            level: 2,
          },
          {
            regionId: 440224,
            parentId: 4402,
            regionName: "仁化县",
            level: 2,
          },
          {
            regionId: 440229,
            parentId: 4402,
            regionName: "翁源县",
            level: 2,
          },
          {
            regionId: 440232,
            parentId: 4402,
            regionName: "乳源瑶族自治县",
            level: 2,
          },
          {
            regionId: 440233,
            parentId: 4402,
            regionName: "新丰县",
            level: 2,
          },
          {
            regionId: 440281,
            parentId: 4402,
            regionName: "乐昌市",
            level: 2,
          },
          {
            regionId: 440282,
            parentId: 4402,
            regionName: "南雄市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4403,
        parentId: 44,
        regionName: "深圳市",
        level: 1,
        children: [
          {
            regionId: 440303,
            parentId: 4403,
            regionName: "罗湖区",
            level: 2,
          },
          {
            regionId: 440304,
            parentId: 4403,
            regionName: "福田区",
            level: 2,
          },
          {
            regionId: 440305,
            parentId: 4403,
            regionName: "南山区",
            level: 2,
          },
          {
            regionId: 440306,
            parentId: 4403,
            regionName: "宝安区",
            level: 2,
          },
          {
            regionId: 440307,
            parentId: 4403,
            regionName: "龙岗区",
            level: 2,
          },
          {
            regionId: 440308,
            parentId: 4403,
            regionName: "盐田区",
            level: 2,
          },
          {
            regionId: 440309,
            parentId: 4403,
            regionName: "龙华区",
            level: 2,
          },
          {
            regionId: 440310,
            parentId: 4403,
            regionName: "坪山区",
            level: 2,
          },
          {
            regionId: 440311,
            parentId: 4403,
            regionName: "光明区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4404,
        parentId: 44,
        regionName: "珠海市",
        level: 1,
        children: [
          {
            regionId: 440402,
            parentId: 4404,
            regionName: "香洲区",
            level: 2,
          },
          {
            regionId: 440403,
            parentId: 4404,
            regionName: "斗门区",
            level: 2,
          },
          {
            regionId: 440404,
            parentId: 4404,
            regionName: "金湾区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4405,
        parentId: 44,
        regionName: "汕头市",
        level: 1,
        children: [
          {
            regionId: 440507,
            parentId: 4405,
            regionName: "龙湖区",
            level: 2,
          },
          {
            regionId: 440511,
            parentId: 4405,
            regionName: "金平区",
            level: 2,
          },
          {
            regionId: 440512,
            parentId: 4405,
            regionName: "濠江区",
            level: 2,
          },
          {
            regionId: 440513,
            parentId: 4405,
            regionName: "潮阳区",
            level: 2,
          },
          {
            regionId: 440514,
            parentId: 4405,
            regionName: "潮南区",
            level: 2,
          },
          {
            regionId: 440515,
            parentId: 4405,
            regionName: "澄海区",
            level: 2,
          },
          {
            regionId: 440523,
            parentId: 4405,
            regionName: "南澳县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4406,
        parentId: 44,
        regionName: "佛山市",
        level: 1,
        children: [
          {
            regionId: 440604,
            parentId: 4406,
            regionName: "禅城区",
            level: 2,
          },
          {
            regionId: 440605,
            parentId: 4406,
            regionName: "南海区",
            level: 2,
          },
          {
            regionId: 440606,
            parentId: 4406,
            regionName: "顺德区",
            level: 2,
          },
          {
            regionId: 440607,
            parentId: 4406,
            regionName: "三水区",
            level: 2,
          },
          {
            regionId: 440608,
            parentId: 4406,
            regionName: "高明区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4407,
        parentId: 44,
        regionName: "江门市",
        level: 1,
        children: [
          {
            regionId: 440703,
            parentId: 4407,
            regionName: "蓬江区",
            level: 2,
          },
          {
            regionId: 440704,
            parentId: 4407,
            regionName: "江海区",
            level: 2,
          },
          {
            regionId: 440705,
            parentId: 4407,
            regionName: "新会区",
            level: 2,
          },
          {
            regionId: 440781,
            parentId: 4407,
            regionName: "台山市",
            level: 2,
          },
          {
            regionId: 440783,
            parentId: 4407,
            regionName: "开平市",
            level: 2,
          },
          {
            regionId: 440784,
            parentId: 4407,
            regionName: "鹤山市",
            level: 2,
          },
          {
            regionId: 440785,
            parentId: 4407,
            regionName: "恩平市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4408,
        parentId: 44,
        regionName: "湛江市",
        level: 1,
        children: [
          {
            regionId: 440802,
            parentId: 4408,
            regionName: "赤坎区",
            level: 2,
          },
          {
            regionId: 440803,
            parentId: 4408,
            regionName: "霞山区",
            level: 2,
          },
          {
            regionId: 440804,
            parentId: 4408,
            regionName: "坡头区",
            level: 2,
          },
          {
            regionId: 440811,
            parentId: 4408,
            regionName: "麻章区",
            level: 2,
          },
          {
            regionId: 440823,
            parentId: 4408,
            regionName: "遂溪县",
            level: 2,
          },
          {
            regionId: 440825,
            parentId: 4408,
            regionName: "徐闻县",
            level: 2,
          },
          {
            regionId: 440881,
            parentId: 4408,
            regionName: "廉江市",
            level: 2,
          },
          {
            regionId: 440882,
            parentId: 4408,
            regionName: "雷州市",
            level: 2,
          },
          {
            regionId: 440883,
            parentId: 4408,
            regionName: "吴川市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4409,
        parentId: 44,
        regionName: "茂名市",
        level: 1,
        children: [
          {
            regionId: 440902,
            parentId: 4409,
            regionName: "茂南区",
            level: 2,
          },
          {
            regionId: 440904,
            parentId: 4409,
            regionName: "电白区",
            level: 2,
          },
          {
            regionId: 440981,
            parentId: 4409,
            regionName: "高州市",
            level: 2,
          },
          {
            regionId: 440982,
            parentId: 4409,
            regionName: "化州市",
            level: 2,
          },
          {
            regionId: 440983,
            parentId: 4409,
            regionName: "信宜市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4412,
        parentId: 44,
        regionName: "肇庆市",
        level: 1,
        children: [
          {
            regionId: 441202,
            parentId: 4412,
            regionName: "端州区",
            level: 2,
          },
          {
            regionId: 441203,
            parentId: 4412,
            regionName: "鼎湖区",
            level: 2,
          },
          {
            regionId: 441204,
            parentId: 4412,
            regionName: "高要区",
            level: 2,
          },
          {
            regionId: 441223,
            parentId: 4412,
            regionName: "广宁县",
            level: 2,
          },
          {
            regionId: 441224,
            parentId: 4412,
            regionName: "怀集县",
            level: 2,
          },
          {
            regionId: 441225,
            parentId: 4412,
            regionName: "封开县",
            level: 2,
          },
          {
            regionId: 441226,
            parentId: 4412,
            regionName: "德庆县",
            level: 2,
          },
          {
            regionId: 441284,
            parentId: 4412,
            regionName: "四会市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4413,
        parentId: 44,
        regionName: "惠州市",
        level: 1,
        children: [
          {
            regionId: 441302,
            parentId: 4413,
            regionName: "惠城区",
            level: 2,
          },
          {
            regionId: 441303,
            parentId: 4413,
            regionName: "惠阳区",
            level: 2,
          },
          {
            regionId: 441322,
            parentId: 4413,
            regionName: "博罗县",
            level: 2,
          },
          {
            regionId: 441323,
            parentId: 4413,
            regionName: "惠东县",
            level: 2,
          },
          {
            regionId: 441324,
            parentId: 4413,
            regionName: "龙门县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4414,
        parentId: 44,
        regionName: "梅州市",
        level: 1,
        children: [
          {
            regionId: 441402,
            parentId: 4414,
            regionName: "梅江区",
            level: 2,
          },
          {
            regionId: 441403,
            parentId: 4414,
            regionName: "梅县区",
            level: 2,
          },
          {
            regionId: 441422,
            parentId: 4414,
            regionName: "大埔县",
            level: 2,
          },
          {
            regionId: 441423,
            parentId: 4414,
            regionName: "丰顺县",
            level: 2,
          },
          {
            regionId: 441424,
            parentId: 4414,
            regionName: "五华县",
            level: 2,
          },
          {
            regionId: 441426,
            parentId: 4414,
            regionName: "平远县",
            level: 2,
          },
          {
            regionId: 441427,
            parentId: 4414,
            regionName: "蕉岭县",
            level: 2,
          },
          {
            regionId: 441481,
            parentId: 4414,
            regionName: "兴宁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4415,
        parentId: 44,
        regionName: "汕尾市",
        level: 1,
        children: [
          {
            regionId: 441502,
            parentId: 4415,
            regionName: "城区",
            level: 2,
          },
          {
            regionId: 441521,
            parentId: 4415,
            regionName: "海丰县",
            level: 2,
          },
          {
            regionId: 441523,
            parentId: 4415,
            regionName: "陆河县",
            level: 2,
          },
          {
            regionId: 441581,
            parentId: 4415,
            regionName: "陆丰市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4416,
        parentId: 44,
        regionName: "河源市",
        level: 1,
        children: [
          {
            regionId: 441602,
            parentId: 4416,
            regionName: "源城区",
            level: 2,
          },
          {
            regionId: 441621,
            parentId: 4416,
            regionName: "紫金县",
            level: 2,
          },
          {
            regionId: 441622,
            parentId: 4416,
            regionName: "龙川县",
            level: 2,
          },
          {
            regionId: 441623,
            parentId: 4416,
            regionName: "连平县",
            level: 2,
          },
          {
            regionId: 441624,
            parentId: 4416,
            regionName: "和平县",
            level: 2,
          },
          {
            regionId: 441625,
            parentId: 4416,
            regionName: "东源县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4417,
        parentId: 44,
        regionName: "阳江市",
        level: 1,
        children: [
          {
            regionId: 441702,
            parentId: 4417,
            regionName: "江城区",
            level: 2,
          },
          {
            regionId: 441704,
            parentId: 4417,
            regionName: "阳东区",
            level: 2,
          },
          {
            regionId: 441721,
            parentId: 4417,
            regionName: "阳西县",
            level: 2,
          },
          {
            regionId: 441781,
            parentId: 4417,
            regionName: "阳春市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4418,
        parentId: 44,
        regionName: "清远市",
        level: 1,
        children: [
          {
            regionId: 441802,
            parentId: 4418,
            regionName: "清城区",
            level: 2,
          },
          {
            regionId: 441803,
            parentId: 4418,
            regionName: "清新区",
            level: 2,
          },
          {
            regionId: 441821,
            parentId: 4418,
            regionName: "佛冈县",
            level: 2,
          },
          {
            regionId: 441823,
            parentId: 4418,
            regionName: "阳山县",
            level: 2,
          },
          {
            regionId: 441825,
            parentId: 4418,
            regionName: "连山壮族瑶族自治县",
            level: 2,
          },
          {
            regionId: 441826,
            parentId: 4418,
            regionName: "连南瑶族自治县",
            level: 2,
          },
          {
            regionId: 441881,
            parentId: 4418,
            regionName: "英德市",
            level: 2,
          },
          {
            regionId: 441882,
            parentId: 4418,
            regionName: "连州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4419,
        parentId: 44,
        regionName: "东莞市",
        level: 1,
        children: [
          {
            regionId: 441900,
            parentId: 4419,
            regionName: "东莞市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4420,
        parentId: 44,
        regionName: "中山市",
        level: 1,
        children: [
          {
            regionId: 442000,
            parentId: 4420,
            regionName: "中山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4451,
        parentId: 44,
        regionName: "潮州市",
        level: 1,
        children: [
          {
            regionId: 445102,
            parentId: 4451,
            regionName: "湘桥区",
            level: 2,
          },
          {
            regionId: 445103,
            parentId: 4451,
            regionName: "潮安区",
            level: 2,
          },
          {
            regionId: 445122,
            parentId: 4451,
            regionName: "饶平县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4452,
        parentId: 44,
        regionName: "揭阳市",
        level: 1,
        children: [
          {
            regionId: 445202,
            parentId: 4452,
            regionName: "榕城区",
            level: 2,
          },
          {
            regionId: 445203,
            parentId: 4452,
            regionName: "揭东区",
            level: 2,
          },
          {
            regionId: 445222,
            parentId: 4452,
            regionName: "揭西县",
            level: 2,
          },
          {
            regionId: 445224,
            parentId: 4452,
            regionName: "惠来县",
            level: 2,
          },
          {
            regionId: 445281,
            parentId: 4452,
            regionName: "普宁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4453,
        parentId: 44,
        regionName: "云浮市",
        level: 1,
        children: [
          {
            regionId: 445302,
            parentId: 4453,
            regionName: "云城区",
            level: 2,
          },
          {
            regionId: 445303,
            parentId: 4453,
            regionName: "云安区",
            level: 2,
          },
          {
            regionId: 445321,
            parentId: 4453,
            regionName: "新兴县",
            level: 2,
          },
          {
            regionId: 445322,
            parentId: 4453,
            regionName: "郁南县",
            level: 2,
          },
          {
            regionId: 445381,
            parentId: 4453,
            regionName: "罗定市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 45,
    parentId: 0,
    regionName: "广西壮族自治区",
    level: 0,
    children: [
      {
        regionId: 4501,
        parentId: 45,
        regionName: "南宁市",
        level: 1,
        children: [
          {
            regionId: 450102,
            parentId: 4501,
            regionName: "兴宁区",
            level: 2,
          },
          {
            regionId: 450103,
            parentId: 4501,
            regionName: "青秀区",
            level: 2,
          },
          {
            regionId: 450105,
            parentId: 4501,
            regionName: "江南区",
            level: 2,
          },
          {
            regionId: 450107,
            parentId: 4501,
            regionName: "西乡塘区",
            level: 2,
          },
          {
            regionId: 450108,
            parentId: 4501,
            regionName: "良庆区",
            level: 2,
          },
          {
            regionId: 450109,
            parentId: 4501,
            regionName: "邕宁区",
            level: 2,
          },
          {
            regionId: 450110,
            parentId: 4501,
            regionName: "武鸣区",
            level: 2,
          },
          {
            regionId: 450123,
            parentId: 4501,
            regionName: "隆安县",
            level: 2,
          },
          {
            regionId: 450124,
            parentId: 4501,
            regionName: "马山县",
            level: 2,
          },
          {
            regionId: 450125,
            parentId: 4501,
            regionName: "上林县",
            level: 2,
          },
          {
            regionId: 450126,
            parentId: 4501,
            regionName: "宾阳县",
            level: 2,
          },
          {
            regionId: 450181,
            parentId: 4501,
            regionName: "横州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4502,
        parentId: 45,
        regionName: "柳州市",
        level: 1,
        children: [
          {
            regionId: 450202,
            parentId: 4502,
            regionName: "城中区",
            level: 2,
          },
          {
            regionId: 450203,
            parentId: 4502,
            regionName: "鱼峰区",
            level: 2,
          },
          {
            regionId: 450204,
            parentId: 4502,
            regionName: "柳南区",
            level: 2,
          },
          {
            regionId: 450205,
            parentId: 4502,
            regionName: "柳北区",
            level: 2,
          },
          {
            regionId: 450206,
            parentId: 4502,
            regionName: "柳江区",
            level: 2,
          },
          {
            regionId: 450222,
            parentId: 4502,
            regionName: "柳城县",
            level: 2,
          },
          {
            regionId: 450223,
            parentId: 4502,
            regionName: "鹿寨县",
            level: 2,
          },
          {
            regionId: 450224,
            parentId: 4502,
            regionName: "融安县",
            level: 2,
          },
          {
            regionId: 450225,
            parentId: 4502,
            regionName: "融水苗族自治县",
            level: 2,
          },
          {
            regionId: 450226,
            parentId: 4502,
            regionName: "三江侗族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4503,
        parentId: 45,
        regionName: "桂林市",
        level: 1,
        children: [
          {
            regionId: 450302,
            parentId: 4503,
            regionName: "秀峰区",
            level: 2,
          },
          {
            regionId: 450303,
            parentId: 4503,
            regionName: "叠彩区",
            level: 2,
          },
          {
            regionId: 450304,
            parentId: 4503,
            regionName: "象山区",
            level: 2,
          },
          {
            regionId: 450305,
            parentId: 4503,
            regionName: "七星区",
            level: 2,
          },
          {
            regionId: 450311,
            parentId: 4503,
            regionName: "雁山区",
            level: 2,
          },
          {
            regionId: 450312,
            parentId: 4503,
            regionName: "临桂区",
            level: 2,
          },
          {
            regionId: 450321,
            parentId: 4503,
            regionName: "阳朔县",
            level: 2,
          },
          {
            regionId: 450323,
            parentId: 4503,
            regionName: "灵川县",
            level: 2,
          },
          {
            regionId: 450324,
            parentId: 4503,
            regionName: "全州县",
            level: 2,
          },
          {
            regionId: 450325,
            parentId: 4503,
            regionName: "兴安县",
            level: 2,
          },
          {
            regionId: 450326,
            parentId: 4503,
            regionName: "永福县",
            level: 2,
          },
          {
            regionId: 450327,
            parentId: 4503,
            regionName: "灌阳县",
            level: 2,
          },
          {
            regionId: 450328,
            parentId: 4503,
            regionName: "龙胜各族自治县",
            level: 2,
          },
          {
            regionId: 450329,
            parentId: 4503,
            regionName: "资源县",
            level: 2,
          },
          {
            regionId: 450330,
            parentId: 4503,
            regionName: "平乐县",
            level: 2,
          },
          {
            regionId: 450332,
            parentId: 4503,
            regionName: "恭城瑶族自治县",
            level: 2,
          },
          {
            regionId: 450381,
            parentId: 4503,
            regionName: "荔浦市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4504,
        parentId: 45,
        regionName: "梧州市",
        level: 1,
        children: [
          {
            regionId: 450403,
            parentId: 4504,
            regionName: "万秀区",
            level: 2,
          },
          {
            regionId: 450405,
            parentId: 4504,
            regionName: "长洲区",
            level: 2,
          },
          {
            regionId: 450406,
            parentId: 4504,
            regionName: "龙圩区",
            level: 2,
          },
          {
            regionId: 450421,
            parentId: 4504,
            regionName: "苍梧县",
            level: 2,
          },
          {
            regionId: 450422,
            parentId: 4504,
            regionName: "藤县",
            level: 2,
          },
          {
            regionId: 450423,
            parentId: 4504,
            regionName: "蒙山县",
            level: 2,
          },
          {
            regionId: 450481,
            parentId: 4504,
            regionName: "岑溪市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4505,
        parentId: 45,
        regionName: "北海市",
        level: 1,
        children: [
          {
            regionId: 450502,
            parentId: 4505,
            regionName: "海城区",
            level: 2,
          },
          {
            regionId: 450503,
            parentId: 4505,
            regionName: "银海区",
            level: 2,
          },
          {
            regionId: 450512,
            parentId: 4505,
            regionName: "铁山港区",
            level: 2,
          },
          {
            regionId: 450521,
            parentId: 4505,
            regionName: "合浦县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4506,
        parentId: 45,
        regionName: "防城港市",
        level: 1,
        children: [
          {
            regionId: 450602,
            parentId: 4506,
            regionName: "港口区",
            level: 2,
          },
          {
            regionId: 450603,
            parentId: 4506,
            regionName: "防城区",
            level: 2,
          },
          {
            regionId: 450621,
            parentId: 4506,
            regionName: "上思县",
            level: 2,
          },
          {
            regionId: 450681,
            parentId: 4506,
            regionName: "东兴市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4507,
        parentId: 45,
        regionName: "钦州市",
        level: 1,
        children: [
          {
            regionId: 450702,
            parentId: 4507,
            regionName: "钦南区",
            level: 2,
          },
          {
            regionId: 450703,
            parentId: 4507,
            regionName: "钦北区",
            level: 2,
          },
          {
            regionId: 450721,
            parentId: 4507,
            regionName: "灵山县",
            level: 2,
          },
          {
            regionId: 450722,
            parentId: 4507,
            regionName: "浦北县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4508,
        parentId: 45,
        regionName: "贵港市",
        level: 1,
        children: [
          {
            regionId: 450802,
            parentId: 4508,
            regionName: "港北区",
            level: 2,
          },
          {
            regionId: 450803,
            parentId: 4508,
            regionName: "港南区",
            level: 2,
          },
          {
            regionId: 450804,
            parentId: 4508,
            regionName: "覃塘区",
            level: 2,
          },
          {
            regionId: 450821,
            parentId: 4508,
            regionName: "平南县",
            level: 2,
          },
          {
            regionId: 450881,
            parentId: 4508,
            regionName: "桂平市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4509,
        parentId: 45,
        regionName: "玉林市",
        level: 1,
        children: [
          {
            regionId: 450902,
            parentId: 4509,
            regionName: "玉州区",
            level: 2,
          },
          {
            regionId: 450903,
            parentId: 4509,
            regionName: "福绵区",
            level: 2,
          },
          {
            regionId: 450921,
            parentId: 4509,
            regionName: "容县",
            level: 2,
          },
          {
            regionId: 450922,
            parentId: 4509,
            regionName: "陆川县",
            level: 2,
          },
          {
            regionId: 450923,
            parentId: 4509,
            regionName: "博白县",
            level: 2,
          },
          {
            regionId: 450924,
            parentId: 4509,
            regionName: "兴业县",
            level: 2,
          },
          {
            regionId: 450981,
            parentId: 4509,
            regionName: "北流市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4510,
        parentId: 45,
        regionName: "百色市",
        level: 1,
        children: [
          {
            regionId: 451002,
            parentId: 4510,
            regionName: "右江区",
            level: 2,
          },
          {
            regionId: 451003,
            parentId: 4510,
            regionName: "田阳区",
            level: 2,
          },
          {
            regionId: 451022,
            parentId: 4510,
            regionName: "田东县",
            level: 2,
          },
          {
            regionId: 451024,
            parentId: 4510,
            regionName: "德保县",
            level: 2,
          },
          {
            regionId: 451026,
            parentId: 4510,
            regionName: "那坡县",
            level: 2,
          },
          {
            regionId: 451027,
            parentId: 4510,
            regionName: "凌云县",
            level: 2,
          },
          {
            regionId: 451028,
            parentId: 4510,
            regionName: "乐业县",
            level: 2,
          },
          {
            regionId: 451029,
            parentId: 4510,
            regionName: "田林县",
            level: 2,
          },
          {
            regionId: 451030,
            parentId: 4510,
            regionName: "西林县",
            level: 2,
          },
          {
            regionId: 451031,
            parentId: 4510,
            regionName: "隆林各族自治县",
            level: 2,
          },
          {
            regionId: 451081,
            parentId: 4510,
            regionName: "靖西市",
            level: 2,
          },
          {
            regionId: 451082,
            parentId: 4510,
            regionName: "平果市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4511,
        parentId: 45,
        regionName: "贺州市",
        level: 1,
        children: [
          {
            regionId: 451102,
            parentId: 4511,
            regionName: "八步区",
            level: 2,
          },
          {
            regionId: 451103,
            parentId: 4511,
            regionName: "平桂区",
            level: 2,
          },
          {
            regionId: 451121,
            parentId: 4511,
            regionName: "昭平县",
            level: 2,
          },
          {
            regionId: 451122,
            parentId: 4511,
            regionName: "钟山县",
            level: 2,
          },
          {
            regionId: 451123,
            parentId: 4511,
            regionName: "富川瑶族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4512,
        parentId: 45,
        regionName: "河池市",
        level: 1,
        children: [
          {
            regionId: 451202,
            parentId: 4512,
            regionName: "金城江区",
            level: 2,
          },
          {
            regionId: 451203,
            parentId: 4512,
            regionName: "宜州区",
            level: 2,
          },
          {
            regionId: 451221,
            parentId: 4512,
            regionName: "南丹县",
            level: 2,
          },
          {
            regionId: 451222,
            parentId: 4512,
            regionName: "天峨县",
            level: 2,
          },
          {
            regionId: 451223,
            parentId: 4512,
            regionName: "凤山县",
            level: 2,
          },
          {
            regionId: 451224,
            parentId: 4512,
            regionName: "东兰县",
            level: 2,
          },
          {
            regionId: 451225,
            parentId: 4512,
            regionName: "罗城仫佬族自治县",
            level: 2,
          },
          {
            regionId: 451226,
            parentId: 4512,
            regionName: "环江毛南族自治县",
            level: 2,
          },
          {
            regionId: 451227,
            parentId: 4512,
            regionName: "巴马瑶族自治县",
            level: 2,
          },
          {
            regionId: 451228,
            parentId: 4512,
            regionName: "都安瑶族自治县",
            level: 2,
          },
          {
            regionId: 451229,
            parentId: 4512,
            regionName: "大化瑶族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 4513,
        parentId: 45,
        regionName: "来宾市",
        level: 1,
        children: [
          {
            regionId: 451302,
            parentId: 4513,
            regionName: "兴宾区",
            level: 2,
          },
          {
            regionId: 451321,
            parentId: 4513,
            regionName: "忻城县",
            level: 2,
          },
          {
            regionId: 451322,
            parentId: 4513,
            regionName: "象州县",
            level: 2,
          },
          {
            regionId: 451323,
            parentId: 4513,
            regionName: "武宣县",
            level: 2,
          },
          {
            regionId: 451324,
            parentId: 4513,
            regionName: "金秀瑶族自治县",
            level: 2,
          },
          {
            regionId: 451381,
            parentId: 4513,
            regionName: "合山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 4514,
        parentId: 45,
        regionName: "崇左市",
        level: 1,
        children: [
          {
            regionId: 451402,
            parentId: 4514,
            regionName: "江州区",
            level: 2,
          },
          {
            regionId: 451421,
            parentId: 4514,
            regionName: "扶绥县",
            level: 2,
          },
          {
            regionId: 451422,
            parentId: 4514,
            regionName: "宁明县",
            level: 2,
          },
          {
            regionId: 451423,
            parentId: 4514,
            regionName: "龙州县",
            level: 2,
          },
          {
            regionId: 451424,
            parentId: 4514,
            regionName: "大新县",
            level: 2,
          },
          {
            regionId: 451425,
            parentId: 4514,
            regionName: "天等县",
            level: 2,
          },
          {
            regionId: 451481,
            parentId: 4514,
            regionName: "凭祥市",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 46,
    parentId: 0,
    regionName: "海南省",
    level: 0,
    children: [
      {
        regionId: 4601,
        parentId: 46,
        regionName: "海口市",
        level: 1,
        children: [
          {
            regionId: 460105,
            parentId: 4601,
            regionName: "秀英区",
            level: 2,
          },
          {
            regionId: 460106,
            parentId: 4601,
            regionName: "龙华区",
            level: 2,
          },
          {
            regionId: 460107,
            parentId: 4601,
            regionName: "琼山区",
            level: 2,
          },
          {
            regionId: 460108,
            parentId: 4601,
            regionName: "美兰区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4602,
        parentId: 46,
        regionName: "三亚市",
        level: 1,
        children: [
          {
            regionId: 460202,
            parentId: 4602,
            regionName: "海棠区",
            level: 2,
          },
          {
            regionId: 460203,
            parentId: 4602,
            regionName: "吉阳区",
            level: 2,
          },
          {
            regionId: 460204,
            parentId: 4602,
            regionName: "天涯区",
            level: 2,
          },
          {
            regionId: 460205,
            parentId: 4602,
            regionName: "崖州区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4603,
        parentId: 46,
        regionName: "三沙市",
        level: 1,
        children: [
          {
            regionId: 460301,
            parentId: 4603,
            regionName: "西沙区",
            level: 2,
          },
          {
            regionId: 460302,
            parentId: 4603,
            regionName: "南沙区",
            level: 2,
          },
        ],
      },
      {
        regionId: 4604,
        parentId: 46,
        regionName: "儋州市",
        level: 1,
        children: [
          {
            regionId: 460400,
            parentId: 4604,
            regionName: "儋州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 469001,
        parentId: 46,
        regionName: "五指山市",
        level: 1,
        children: [
          {
            regionId: 469001000,
            parentId: 469001,
            regionName: "五指山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 469002,
        parentId: 46,
        regionName: "琼海市",
        level: 1,
        children: [
          {
            regionId: 469002000,
            parentId: 469002,
            regionName: "琼海市",
            level: 2,
          },
        ],
      },
      {
        regionId: 469005,
        parentId: 46,
        regionName: "文昌市",
        level: 1,
        children: [
          {
            regionId: 469005000,
            parentId: 469005,
            regionName: "文昌市",
            level: 2,
          },
        ],
      },
      {
        regionId: 469006,
        parentId: 46,
        regionName: "万宁市",
        level: 1,
        children: [
          {
            regionId: 469006000,
            parentId: 469006,
            regionName: "万宁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 469007,
        parentId: 46,
        regionName: "东方市",
        level: 1,
        children: [
          {
            regionId: 469007000,
            parentId: 469007,
            regionName: "东方市",
            level: 2,
          },
        ],
      },
      {
        regionId: 469021,
        parentId: 46,
        regionName: "定安县",
        level: 1,
        children: [
          {
            regionId: 469021000,
            parentId: 469021,
            regionName: "定安县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469022,
        parentId: 46,
        regionName: "屯昌县",
        level: 1,
        children: [
          {
            regionId: 469022000,
            parentId: 469022,
            regionName: "屯昌县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469023,
        parentId: 46,
        regionName: "澄迈县",
        level: 1,
        children: [
          {
            regionId: 469023000,
            parentId: 469023,
            regionName: "澄迈县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469024,
        parentId: 46,
        regionName: "临高县",
        level: 1,
        children: [
          {
            regionId: 469024000,
            parentId: 469024,
            regionName: "临高县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469025,
        parentId: 46,
        regionName: "白沙黎族自治县",
        level: 1,
        children: [
          {
            regionId: 469025000,
            parentId: 469025,
            regionName: "白沙黎族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469026,
        parentId: 46,
        regionName: "昌江黎族自治县",
        level: 1,
        children: [
          {
            regionId: 469026000,
            parentId: 469026,
            regionName: "昌江黎族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469027,
        parentId: 46,
        regionName: "乐东黎族自治县",
        level: 1,
        children: [
          {
            regionId: 469027000,
            parentId: 469027,
            regionName: "乐东黎族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469028,
        parentId: 46,
        regionName: "陵水黎族自治县",
        level: 1,
        children: [
          {
            regionId: 469028000,
            parentId: 469028,
            regionName: "陵水黎族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469029,
        parentId: 46,
        regionName: "保亭黎族苗族自治县",
        level: 1,
        children: [
          {
            regionId: 469029000,
            parentId: 469029,
            regionName: "保亭黎族苗族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 469030,
        parentId: 46,
        regionName: "琼中黎族苗族自治县",
        level: 1,
        children: [
          {
            regionId: 469030000,
            parentId: 469030,
            regionName: "琼中黎族苗族自治县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 50,
    parentId: 0,
    regionName: "重庆市",
    level: 0,
    children: [
      {
        regionId: 5001,
        parentId: 50,
        regionName: "重庆城区",
        level: 1,
        children: [
          {
            regionId: 500101,
            parentId: 5001,
            regionName: "万州区",
            level: 2,
          },
          {
            regionId: 500102,
            parentId: 5001,
            regionName: "涪陵区",
            level: 2,
          },
          {
            regionId: 500103,
            parentId: 5001,
            regionName: "渝中区",
            level: 2,
          },
          {
            regionId: 500104,
            parentId: 5001,
            regionName: "大渡口区",
            level: 2,
          },
          {
            regionId: 500105,
            parentId: 5001,
            regionName: "江北区",
            level: 2,
          },
          {
            regionId: 500106,
            parentId: 5001,
            regionName: "沙坪坝区",
            level: 2,
          },
          {
            regionId: 500107,
            parentId: 5001,
            regionName: "九龙坡区",
            level: 2,
          },
          {
            regionId: 500108,
            parentId: 5001,
            regionName: "南岸区",
            level: 2,
          },
          {
            regionId: 500109,
            parentId: 5001,
            regionName: "北碚区",
            level: 2,
          },
          {
            regionId: 500110,
            parentId: 5001,
            regionName: "綦江区",
            level: 2,
          },
          {
            regionId: 500111,
            parentId: 5001,
            regionName: "大足区",
            level: 2,
          },
          {
            regionId: 500112,
            parentId: 5001,
            regionName: "渝北区",
            level: 2,
          },
          {
            regionId: 500113,
            parentId: 5001,
            regionName: "巴南区",
            level: 2,
          },
          {
            regionId: 500114,
            parentId: 5001,
            regionName: "黔江区",
            level: 2,
          },
          {
            regionId: 500115,
            parentId: 5001,
            regionName: "长寿区",
            level: 2,
          },
          {
            regionId: 500116,
            parentId: 5001,
            regionName: "江津区",
            level: 2,
          },
          {
            regionId: 500117,
            parentId: 5001,
            regionName: "合川区",
            level: 2,
          },
          {
            regionId: 500118,
            parentId: 5001,
            regionName: "永川区",
            level: 2,
          },
          {
            regionId: 500119,
            parentId: 5001,
            regionName: "南川区",
            level: 2,
          },
          {
            regionId: 500120,
            parentId: 5001,
            regionName: "璧山区",
            level: 2,
          },
          {
            regionId: 500151,
            parentId: 5001,
            regionName: "铜梁区",
            level: 2,
          },
          {
            regionId: 500152,
            parentId: 5001,
            regionName: "潼南区",
            level: 2,
          },
          {
            regionId: 500153,
            parentId: 5001,
            regionName: "荣昌区",
            level: 2,
          },
          {
            regionId: 500154,
            parentId: 5001,
            regionName: "开州区",
            level: 2,
          },
          {
            regionId: 500155,
            parentId: 5001,
            regionName: "梁平区",
            level: 2,
          },
          {
            regionId: 500156,
            parentId: 5001,
            regionName: "武隆区",
            level: 2,
          },
        ],
      },
      {
        regionId: 5002,
        parentId: 50,
        regionName: "重庆郊县",
        level: 1,
        children: [
          {
            regionId: 500229,
            parentId: 5002,
            regionName: "城口县",
            level: 2,
          },
          {
            regionId: 500230,
            parentId: 5002,
            regionName: "丰都县",
            level: 2,
          },
          {
            regionId: 500231,
            parentId: 5002,
            regionName: "垫江县",
            level: 2,
          },
          {
            regionId: 500233,
            parentId: 5002,
            regionName: "忠县",
            level: 2,
          },
          {
            regionId: 500235,
            parentId: 5002,
            regionName: "云阳县",
            level: 2,
          },
          {
            regionId: 500236,
            parentId: 5002,
            regionName: "奉节县",
            level: 2,
          },
          {
            regionId: 500237,
            parentId: 5002,
            regionName: "巫山县",
            level: 2,
          },
          {
            regionId: 500238,
            parentId: 5002,
            regionName: "巫溪县",
            level: 2,
          },
          {
            regionId: 500240,
            parentId: 5002,
            regionName: "石柱土家族自治县",
            level: 2,
          },
          {
            regionId: 500241,
            parentId: 5002,
            regionName: "秀山土家族苗族自治县",
            level: 2,
          },
          {
            regionId: 500242,
            parentId: 5002,
            regionName: "酉阳土家族苗族自治县",
            level: 2,
          },
          {
            regionId: 500243,
            parentId: 5002,
            regionName: "彭水苗族土家族自治县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 51,
    parentId: 0,
    regionName: "四川省",
    level: 0,
    children: [
      {
        regionId: 5101,
        parentId: 51,
        regionName: "成都市",
        level: 1,
        children: [
          {
            regionId: 510104,
            parentId: 5101,
            regionName: "锦江区",
            level: 2,
          },
          {
            regionId: 510105,
            parentId: 5101,
            regionName: "青羊区",
            level: 2,
          },
          {
            regionId: 510106,
            parentId: 5101,
            regionName: "金牛区",
            level: 2,
          },
          {
            regionId: 510107,
            parentId: 5101,
            regionName: "武侯区",
            level: 2,
          },
          {
            regionId: 510108,
            parentId: 5101,
            regionName: "成华区",
            level: 2,
          },
          {
            regionId: 510112,
            parentId: 5101,
            regionName: "龙泉驿区",
            level: 2,
          },
          {
            regionId: 510113,
            parentId: 5101,
            regionName: "青白江区",
            level: 2,
          },
          {
            regionId: 510114,
            parentId: 5101,
            regionName: "新都区",
            level: 2,
          },
          {
            regionId: 510115,
            parentId: 5101,
            regionName: "温江区",
            level: 2,
          },
          {
            regionId: 510116,
            parentId: 5101,
            regionName: "双流区",
            level: 2,
          },
          {
            regionId: 510117,
            parentId: 5101,
            regionName: "郫都区",
            level: 2,
          },
          {
            regionId: 510118,
            parentId: 5101,
            regionName: "新津区",
            level: 2,
          },
          {
            regionId: 510121,
            parentId: 5101,
            regionName: "金堂县",
            level: 2,
          },
          {
            regionId: 510129,
            parentId: 5101,
            regionName: "大邑县",
            level: 2,
          },
          {
            regionId: 510131,
            parentId: 5101,
            regionName: "蒲江县",
            level: 2,
          },
          {
            regionId: 510181,
            parentId: 5101,
            regionName: "都江堰市",
            level: 2,
          },
          {
            regionId: 510182,
            parentId: 5101,
            regionName: "彭州市",
            level: 2,
          },
          {
            regionId: 510183,
            parentId: 5101,
            regionName: "邛崃市",
            level: 2,
          },
          {
            regionId: 510184,
            parentId: 5101,
            regionName: "崇州市",
            level: 2,
          },
          {
            regionId: 510185,
            parentId: 5101,
            regionName: "简阳市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5103,
        parentId: 51,
        regionName: "自贡市",
        level: 1,
        children: [
          {
            regionId: 510302,
            parentId: 5103,
            regionName: "自流井区",
            level: 2,
          },
          {
            regionId: 510303,
            parentId: 5103,
            regionName: "贡井区",
            level: 2,
          },
          {
            regionId: 510304,
            parentId: 5103,
            regionName: "大安区",
            level: 2,
          },
          {
            regionId: 510311,
            parentId: 5103,
            regionName: "沿滩区",
            level: 2,
          },
          {
            regionId: 510321,
            parentId: 5103,
            regionName: "荣县",
            level: 2,
          },
          {
            regionId: 510322,
            parentId: 5103,
            regionName: "富顺县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5104,
        parentId: 51,
        regionName: "攀枝花市",
        level: 1,
        children: [
          {
            regionId: 510402,
            parentId: 5104,
            regionName: "东区",
            level: 2,
          },
          {
            regionId: 510403,
            parentId: 5104,
            regionName: "西区",
            level: 2,
          },
          {
            regionId: 510411,
            parentId: 5104,
            regionName: "仁和区",
            level: 2,
          },
          {
            regionId: 510421,
            parentId: 5104,
            regionName: "米易县",
            level: 2,
          },
          {
            regionId: 510422,
            parentId: 5104,
            regionName: "盐边县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5105,
        parentId: 51,
        regionName: "泸州市",
        level: 1,
        children: [
          {
            regionId: 510502,
            parentId: 5105,
            regionName: "江阳区",
            level: 2,
          },
          {
            regionId: 510503,
            parentId: 5105,
            regionName: "纳溪区",
            level: 2,
          },
          {
            regionId: 510504,
            parentId: 5105,
            regionName: "龙马潭区",
            level: 2,
          },
          {
            regionId: 510521,
            parentId: 5105,
            regionName: "泸县",
            level: 2,
          },
          {
            regionId: 510522,
            parentId: 5105,
            regionName: "合江县",
            level: 2,
          },
          {
            regionId: 510524,
            parentId: 5105,
            regionName: "叙永县",
            level: 2,
          },
          {
            regionId: 510525,
            parentId: 5105,
            regionName: "古蔺县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5106,
        parentId: 51,
        regionName: "德阳市",
        level: 1,
        children: [
          {
            regionId: 510603,
            parentId: 5106,
            regionName: "旌阳区",
            level: 2,
          },
          {
            regionId: 510604,
            parentId: 5106,
            regionName: "罗江区",
            level: 2,
          },
          {
            regionId: 510623,
            parentId: 5106,
            regionName: "中江县",
            level: 2,
          },
          {
            regionId: 510681,
            parentId: 5106,
            regionName: "广汉市",
            level: 2,
          },
          {
            regionId: 510682,
            parentId: 5106,
            regionName: "什邡市",
            level: 2,
          },
          {
            regionId: 510683,
            parentId: 5106,
            regionName: "绵竹市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5107,
        parentId: 51,
        regionName: "绵阳市",
        level: 1,
        children: [
          {
            regionId: 510703,
            parentId: 5107,
            regionName: "涪城区",
            level: 2,
          },
          {
            regionId: 510704,
            parentId: 5107,
            regionName: "游仙区",
            level: 2,
          },
          {
            regionId: 510705,
            parentId: 5107,
            regionName: "安州区",
            level: 2,
          },
          {
            regionId: 510722,
            parentId: 5107,
            regionName: "三台县",
            level: 2,
          },
          {
            regionId: 510723,
            parentId: 5107,
            regionName: "盐亭县",
            level: 2,
          },
          {
            regionId: 510725,
            parentId: 5107,
            regionName: "梓潼县",
            level: 2,
          },
          {
            regionId: 510726,
            parentId: 5107,
            regionName: "北川羌族自治县",
            level: 2,
          },
          {
            regionId: 510727,
            parentId: 5107,
            regionName: "平武县",
            level: 2,
          },
          {
            regionId: 510781,
            parentId: 5107,
            regionName: "江油市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5108,
        parentId: 51,
        regionName: "广元市",
        level: 1,
        children: [
          {
            regionId: 510802,
            parentId: 5108,
            regionName: "利州区",
            level: 2,
          },
          {
            regionId: 510811,
            parentId: 5108,
            regionName: "昭化区",
            level: 2,
          },
          {
            regionId: 510812,
            parentId: 5108,
            regionName: "朝天区",
            level: 2,
          },
          {
            regionId: 510821,
            parentId: 5108,
            regionName: "旺苍县",
            level: 2,
          },
          {
            regionId: 510822,
            parentId: 5108,
            regionName: "青川县",
            level: 2,
          },
          {
            regionId: 510823,
            parentId: 5108,
            regionName: "剑阁县",
            level: 2,
          },
          {
            regionId: 510824,
            parentId: 5108,
            regionName: "苍溪县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5109,
        parentId: 51,
        regionName: "遂宁市",
        level: 1,
        children: [
          {
            regionId: 510903,
            parentId: 5109,
            regionName: "船山区",
            level: 2,
          },
          {
            regionId: 510904,
            parentId: 5109,
            regionName: "安居区",
            level: 2,
          },
          {
            regionId: 510921,
            parentId: 5109,
            regionName: "蓬溪县",
            level: 2,
          },
          {
            regionId: 510923,
            parentId: 5109,
            regionName: "大英县",
            level: 2,
          },
          {
            regionId: 510981,
            parentId: 5109,
            regionName: "射洪市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5110,
        parentId: 51,
        regionName: "内江市",
        level: 1,
        children: [
          {
            regionId: 511002,
            parentId: 5110,
            regionName: "市中区",
            level: 2,
          },
          {
            regionId: 511011,
            parentId: 5110,
            regionName: "东兴区",
            level: 2,
          },
          {
            regionId: 511024,
            parentId: 5110,
            regionName: "威远县",
            level: 2,
          },
          {
            regionId: 511025,
            parentId: 5110,
            regionName: "资中县",
            level: 2,
          },
          {
            regionId: 511083,
            parentId: 5110,
            regionName: "隆昌市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5111,
        parentId: 51,
        regionName: "乐山市",
        level: 1,
        children: [
          {
            regionId: 511102,
            parentId: 5111,
            regionName: "市中区",
            level: 2,
          },
          {
            regionId: 511111,
            parentId: 5111,
            regionName: "沙湾区",
            level: 2,
          },
          {
            regionId: 511112,
            parentId: 5111,
            regionName: "五通桥区",
            level: 2,
          },
          {
            regionId: 511113,
            parentId: 5111,
            regionName: "金口河区",
            level: 2,
          },
          {
            regionId: 511123,
            parentId: 5111,
            regionName: "犍为县",
            level: 2,
          },
          {
            regionId: 511124,
            parentId: 5111,
            regionName: "井研县",
            level: 2,
          },
          {
            regionId: 511126,
            parentId: 5111,
            regionName: "夹江县",
            level: 2,
          },
          {
            regionId: 511129,
            parentId: 5111,
            regionName: "沐川县",
            level: 2,
          },
          {
            regionId: 511132,
            parentId: 5111,
            regionName: "峨边彝族自治县",
            level: 2,
          },
          {
            regionId: 511133,
            parentId: 5111,
            regionName: "马边彝族自治县",
            level: 2,
          },
          {
            regionId: 511181,
            parentId: 5111,
            regionName: "峨眉山市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5113,
        parentId: 51,
        regionName: "南充市",
        level: 1,
        children: [
          {
            regionId: 511302,
            parentId: 5113,
            regionName: "顺庆区",
            level: 2,
          },
          {
            regionId: 511303,
            parentId: 5113,
            regionName: "高坪区",
            level: 2,
          },
          {
            regionId: 511304,
            parentId: 5113,
            regionName: "嘉陵区",
            level: 2,
          },
          {
            regionId: 511321,
            parentId: 5113,
            regionName: "南部县",
            level: 2,
          },
          {
            regionId: 511322,
            parentId: 5113,
            regionName: "营山县",
            level: 2,
          },
          {
            regionId: 511323,
            parentId: 5113,
            regionName: "蓬安县",
            level: 2,
          },
          {
            regionId: 511324,
            parentId: 5113,
            regionName: "仪陇县",
            level: 2,
          },
          {
            regionId: 511325,
            parentId: 5113,
            regionName: "西充县",
            level: 2,
          },
          {
            regionId: 511381,
            parentId: 5113,
            regionName: "阆中市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5114,
        parentId: 51,
        regionName: "眉山市",
        level: 1,
        children: [
          {
            regionId: 511402,
            parentId: 5114,
            regionName: "东坡区",
            level: 2,
          },
          {
            regionId: 511403,
            parentId: 5114,
            regionName: "彭山区",
            level: 2,
          },
          {
            regionId: 511421,
            parentId: 5114,
            regionName: "仁寿县",
            level: 2,
          },
          {
            regionId: 511423,
            parentId: 5114,
            regionName: "洪雅县",
            level: 2,
          },
          {
            regionId: 511424,
            parentId: 5114,
            regionName: "丹棱县",
            level: 2,
          },
          {
            regionId: 511425,
            parentId: 5114,
            regionName: "青神县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5115,
        parentId: 51,
        regionName: "宜宾市",
        level: 1,
        children: [
          {
            regionId: 511502,
            parentId: 5115,
            regionName: "翠屏区",
            level: 2,
          },
          {
            regionId: 511503,
            parentId: 5115,
            regionName: "南溪区",
            level: 2,
          },
          {
            regionId: 511504,
            parentId: 5115,
            regionName: "叙州区",
            level: 2,
          },
          {
            regionId: 511523,
            parentId: 5115,
            regionName: "江安县",
            level: 2,
          },
          {
            regionId: 511524,
            parentId: 5115,
            regionName: "长宁县",
            level: 2,
          },
          {
            regionId: 511525,
            parentId: 5115,
            regionName: "高县",
            level: 2,
          },
          {
            regionId: 511526,
            parentId: 5115,
            regionName: "珙县",
            level: 2,
          },
          {
            regionId: 511527,
            parentId: 5115,
            regionName: "筠连县",
            level: 2,
          },
          {
            regionId: 511528,
            parentId: 5115,
            regionName: "兴文县",
            level: 2,
          },
          {
            regionId: 511529,
            parentId: 5115,
            regionName: "屏山县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5116,
        parentId: 51,
        regionName: "广安市",
        level: 1,
        children: [
          {
            regionId: 511602,
            parentId: 5116,
            regionName: "广安区",
            level: 2,
          },
          {
            regionId: 511603,
            parentId: 5116,
            regionName: "前锋区",
            level: 2,
          },
          {
            regionId: 511621,
            parentId: 5116,
            regionName: "岳池县",
            level: 2,
          },
          {
            regionId: 511622,
            parentId: 5116,
            regionName: "武胜县",
            level: 2,
          },
          {
            regionId: 511623,
            parentId: 5116,
            regionName: "邻水县",
            level: 2,
          },
          {
            regionId: 511681,
            parentId: 5116,
            regionName: "华蓥市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5117,
        parentId: 51,
        regionName: "达州市",
        level: 1,
        children: [
          {
            regionId: 511702,
            parentId: 5117,
            regionName: "通川区",
            level: 2,
          },
          {
            regionId: 511703,
            parentId: 5117,
            regionName: "达川区",
            level: 2,
          },
          {
            regionId: 511722,
            parentId: 5117,
            regionName: "宣汉县",
            level: 2,
          },
          {
            regionId: 511723,
            parentId: 5117,
            regionName: "开江县",
            level: 2,
          },
          {
            regionId: 511724,
            parentId: 5117,
            regionName: "大竹县",
            level: 2,
          },
          {
            regionId: 511725,
            parentId: 5117,
            regionName: "渠县",
            level: 2,
          },
          {
            regionId: 511781,
            parentId: 5117,
            regionName: "万源市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5118,
        parentId: 51,
        regionName: "雅安市",
        level: 1,
        children: [
          {
            regionId: 511802,
            parentId: 5118,
            regionName: "雨城区",
            level: 2,
          },
          {
            regionId: 511803,
            parentId: 5118,
            regionName: "名山区",
            level: 2,
          },
          {
            regionId: 511822,
            parentId: 5118,
            regionName: "荥经县",
            level: 2,
          },
          {
            regionId: 511823,
            parentId: 5118,
            regionName: "汉源县",
            level: 2,
          },
          {
            regionId: 511824,
            parentId: 5118,
            regionName: "石棉县",
            level: 2,
          },
          {
            regionId: 511825,
            parentId: 5118,
            regionName: "天全县",
            level: 2,
          },
          {
            regionId: 511826,
            parentId: 5118,
            regionName: "芦山县",
            level: 2,
          },
          {
            regionId: 511827,
            parentId: 5118,
            regionName: "宝兴县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5119,
        parentId: 51,
        regionName: "巴中市",
        level: 1,
        children: [
          {
            regionId: 511902,
            parentId: 5119,
            regionName: "巴州区",
            level: 2,
          },
          {
            regionId: 511903,
            parentId: 5119,
            regionName: "恩阳区",
            level: 2,
          },
          {
            regionId: 511921,
            parentId: 5119,
            regionName: "通江县",
            level: 2,
          },
          {
            regionId: 511922,
            parentId: 5119,
            regionName: "南江县",
            level: 2,
          },
          {
            regionId: 511923,
            parentId: 5119,
            regionName: "平昌县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5120,
        parentId: 51,
        regionName: "资阳市",
        level: 1,
        children: [
          {
            regionId: 512002,
            parentId: 5120,
            regionName: "雁江区",
            level: 2,
          },
          {
            regionId: 512021,
            parentId: 5120,
            regionName: "安岳县",
            level: 2,
          },
          {
            regionId: 512022,
            parentId: 5120,
            regionName: "乐至县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5132,
        parentId: 51,
        regionName: "阿坝藏族羌族自治州",
        level: 1,
        children: [
          {
            regionId: 513201,
            parentId: 5132,
            regionName: "马尔康市",
            level: 2,
          },
          {
            regionId: 513221,
            parentId: 5132,
            regionName: "汶川县",
            level: 2,
          },
          {
            regionId: 513222,
            parentId: 5132,
            regionName: "理县",
            level: 2,
          },
          {
            regionId: 513223,
            parentId: 5132,
            regionName: "茂县",
            level: 2,
          },
          {
            regionId: 513224,
            parentId: 5132,
            regionName: "松潘县",
            level: 2,
          },
          {
            regionId: 513225,
            parentId: 5132,
            regionName: "九寨沟县",
            level: 2,
          },
          {
            regionId: 513226,
            parentId: 5132,
            regionName: "金川县",
            level: 2,
          },
          {
            regionId: 513227,
            parentId: 5132,
            regionName: "小金县",
            level: 2,
          },
          {
            regionId: 513228,
            parentId: 5132,
            regionName: "黑水县",
            level: 2,
          },
          {
            regionId: 513230,
            parentId: 5132,
            regionName: "壤塘县",
            level: 2,
          },
          {
            regionId: 513231,
            parentId: 5132,
            regionName: "阿坝县",
            level: 2,
          },
          {
            regionId: 513232,
            parentId: 5132,
            regionName: "若尔盖县",
            level: 2,
          },
          {
            regionId: 513233,
            parentId: 5132,
            regionName: "红原县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5133,
        parentId: 51,
        regionName: "甘孜藏族自治州",
        level: 1,
        children: [
          {
            regionId: 513301,
            parentId: 5133,
            regionName: "康定市",
            level: 2,
          },
          {
            regionId: 513322,
            parentId: 5133,
            regionName: "泸定县",
            level: 2,
          },
          {
            regionId: 513323,
            parentId: 5133,
            regionName: "丹巴县",
            level: 2,
          },
          {
            regionId: 513324,
            parentId: 5133,
            regionName: "九龙县",
            level: 2,
          },
          {
            regionId: 513325,
            parentId: 5133,
            regionName: "雅江县",
            level: 2,
          },
          {
            regionId: 513326,
            parentId: 5133,
            regionName: "道孚县",
            level: 2,
          },
          {
            regionId: 513327,
            parentId: 5133,
            regionName: "炉霍县",
            level: 2,
          },
          {
            regionId: 513328,
            parentId: 5133,
            regionName: "甘孜县",
            level: 2,
          },
          {
            regionId: 513329,
            parentId: 5133,
            regionName: "新龙县",
            level: 2,
          },
          {
            regionId: 513330,
            parentId: 5133,
            regionName: "德格县",
            level: 2,
          },
          {
            regionId: 513331,
            parentId: 5133,
            regionName: "白玉县",
            level: 2,
          },
          {
            regionId: 513332,
            parentId: 5133,
            regionName: "石渠县",
            level: 2,
          },
          {
            regionId: 513333,
            parentId: 5133,
            regionName: "色达县",
            level: 2,
          },
          {
            regionId: 513334,
            parentId: 5133,
            regionName: "理塘县",
            level: 2,
          },
          {
            regionId: 513335,
            parentId: 5133,
            regionName: "巴塘县",
            level: 2,
          },
          {
            regionId: 513336,
            parentId: 5133,
            regionName: "乡城县",
            level: 2,
          },
          {
            regionId: 513337,
            parentId: 5133,
            regionName: "稻城县",
            level: 2,
          },
          {
            regionId: 513338,
            parentId: 5133,
            regionName: "得荣县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5134,
        parentId: 51,
        regionName: "凉山彝族自治州",
        level: 1,
        children: [
          {
            regionId: 513401,
            parentId: 5134,
            regionName: "西昌市",
            level: 2,
          },
          {
            regionId: 513402,
            parentId: 5134,
            regionName: "会理市",
            level: 2,
          },
          {
            regionId: 513422,
            parentId: 5134,
            regionName: "木里藏族自治县",
            level: 2,
          },
          {
            regionId: 513423,
            parentId: 5134,
            regionName: "盐源县",
            level: 2,
          },
          {
            regionId: 513424,
            parentId: 5134,
            regionName: "德昌县",
            level: 2,
          },
          {
            regionId: 513426,
            parentId: 5134,
            regionName: "会东县",
            level: 2,
          },
          {
            regionId: 513427,
            parentId: 5134,
            regionName: "宁南县",
            level: 2,
          },
          {
            regionId: 513428,
            parentId: 5134,
            regionName: "普格县",
            level: 2,
          },
          {
            regionId: 513429,
            parentId: 5134,
            regionName: "布拖县",
            level: 2,
          },
          {
            regionId: 513430,
            parentId: 5134,
            regionName: "金阳县",
            level: 2,
          },
          {
            regionId: 513431,
            parentId: 5134,
            regionName: "昭觉县",
            level: 2,
          },
          {
            regionId: 513432,
            parentId: 5134,
            regionName: "喜德县",
            level: 2,
          },
          {
            regionId: 513433,
            parentId: 5134,
            regionName: "冕宁县",
            level: 2,
          },
          {
            regionId: 513434,
            parentId: 5134,
            regionName: "越西县",
            level: 2,
          },
          {
            regionId: 513435,
            parentId: 5134,
            regionName: "甘洛县",
            level: 2,
          },
          {
            regionId: 513436,
            parentId: 5134,
            regionName: "美姑县",
            level: 2,
          },
          {
            regionId: 513437,
            parentId: 5134,
            regionName: "雷波县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 52,
    parentId: 0,
    regionName: "贵州省",
    level: 0,
    children: [
      {
        regionId: 5201,
        parentId: 52,
        regionName: "贵阳市",
        level: 1,
        children: [
          {
            regionId: 520102,
            parentId: 5201,
            regionName: "南明区",
            level: 2,
          },
          {
            regionId: 520103,
            parentId: 5201,
            regionName: "云岩区",
            level: 2,
          },
          {
            regionId: 520111,
            parentId: 5201,
            regionName: "花溪区",
            level: 2,
          },
          {
            regionId: 520112,
            parentId: 5201,
            regionName: "乌当区",
            level: 2,
          },
          {
            regionId: 520113,
            parentId: 5201,
            regionName: "白云区",
            level: 2,
          },
          {
            regionId: 520115,
            parentId: 5201,
            regionName: "观山湖区",
            level: 2,
          },
          {
            regionId: 520121,
            parentId: 5201,
            regionName: "开阳县",
            level: 2,
          },
          {
            regionId: 520122,
            parentId: 5201,
            regionName: "息烽县",
            level: 2,
          },
          {
            regionId: 520123,
            parentId: 5201,
            regionName: "修文县",
            level: 2,
          },
          {
            regionId: 520181,
            parentId: 5201,
            regionName: "清镇市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5202,
        parentId: 52,
        regionName: "六盘水市",
        level: 1,
        children: [
          {
            regionId: 520201,
            parentId: 5202,
            regionName: "钟山区",
            level: 2,
          },
          {
            regionId: 520203,
            parentId: 5202,
            regionName: "六枝特区",
            level: 2,
          },
          {
            regionId: 520204,
            parentId: 5202,
            regionName: "水城区",
            level: 2,
          },
          {
            regionId: 520281,
            parentId: 5202,
            regionName: "盘州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5203,
        parentId: 52,
        regionName: "遵义市",
        level: 1,
        children: [
          {
            regionId: 520302,
            parentId: 5203,
            regionName: "红花岗区",
            level: 2,
          },
          {
            regionId: 520303,
            parentId: 5203,
            regionName: "汇川区",
            level: 2,
          },
          {
            regionId: 520304,
            parentId: 5203,
            regionName: "播州区",
            level: 2,
          },
          {
            regionId: 520322,
            parentId: 5203,
            regionName: "桐梓县",
            level: 2,
          },
          {
            regionId: 520323,
            parentId: 5203,
            regionName: "绥阳县",
            level: 2,
          },
          {
            regionId: 520324,
            parentId: 5203,
            regionName: "正安县",
            level: 2,
          },
          {
            regionId: 520325,
            parentId: 5203,
            regionName: "道真仡佬族苗族自治县",
            level: 2,
          },
          {
            regionId: 520326,
            parentId: 5203,
            regionName: "务川仡佬族苗族自治县",
            level: 2,
          },
          {
            regionId: 520327,
            parentId: 5203,
            regionName: "凤冈县",
            level: 2,
          },
          {
            regionId: 520328,
            parentId: 5203,
            regionName: "湄潭县",
            level: 2,
          },
          {
            regionId: 520329,
            parentId: 5203,
            regionName: "余庆县",
            level: 2,
          },
          {
            regionId: 520330,
            parentId: 5203,
            regionName: "习水县",
            level: 2,
          },
          {
            regionId: 520381,
            parentId: 5203,
            regionName: "赤水市",
            level: 2,
          },
          {
            regionId: 520382,
            parentId: 5203,
            regionName: "仁怀市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5204,
        parentId: 52,
        regionName: "安顺市",
        level: 1,
        children: [
          {
            regionId: 520402,
            parentId: 5204,
            regionName: "西秀区",
            level: 2,
          },
          {
            regionId: 520403,
            parentId: 5204,
            regionName: "平坝区",
            level: 2,
          },
          {
            regionId: 520422,
            parentId: 5204,
            regionName: "普定县",
            level: 2,
          },
          {
            regionId: 520423,
            parentId: 5204,
            regionName: "镇宁布依族苗族自治县",
            level: 2,
          },
          {
            regionId: 520424,
            parentId: 5204,
            regionName: "关岭布依族苗族自治县",
            level: 2,
          },
          {
            regionId: 520425,
            parentId: 5204,
            regionName: "紫云苗族布依族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5205,
        parentId: 52,
        regionName: "毕节市",
        level: 1,
        children: [
          {
            regionId: 520502,
            parentId: 5205,
            regionName: "七星关区",
            level: 2,
          },
          {
            regionId: 520521,
            parentId: 5205,
            regionName: "大方县",
            level: 2,
          },
          {
            regionId: 520523,
            parentId: 5205,
            regionName: "金沙县",
            level: 2,
          },
          {
            regionId: 520524,
            parentId: 5205,
            regionName: "织金县",
            level: 2,
          },
          {
            regionId: 520525,
            parentId: 5205,
            regionName: "纳雍县",
            level: 2,
          },
          {
            regionId: 520526,
            parentId: 5205,
            regionName: "威宁彝族回族苗族自治县",
            level: 2,
          },
          {
            regionId: 520527,
            parentId: 5205,
            regionName: "赫章县",
            level: 2,
          },
          {
            regionId: 520581,
            parentId: 5205,
            regionName: "黔西市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5206,
        parentId: 52,
        regionName: "铜仁市",
        level: 1,
        children: [
          {
            regionId: 520602,
            parentId: 5206,
            regionName: "碧江区",
            level: 2,
          },
          {
            regionId: 520603,
            parentId: 5206,
            regionName: "万山区",
            level: 2,
          },
          {
            regionId: 520621,
            parentId: 5206,
            regionName: "江口县",
            level: 2,
          },
          {
            regionId: 520622,
            parentId: 5206,
            regionName: "玉屏侗族自治县",
            level: 2,
          },
          {
            regionId: 520623,
            parentId: 5206,
            regionName: "石阡县",
            level: 2,
          },
          {
            regionId: 520624,
            parentId: 5206,
            regionName: "思南县",
            level: 2,
          },
          {
            regionId: 520625,
            parentId: 5206,
            regionName: "印江土家族苗族自治县",
            level: 2,
          },
          {
            regionId: 520626,
            parentId: 5206,
            regionName: "德江县",
            level: 2,
          },
          {
            regionId: 520627,
            parentId: 5206,
            regionName: "沿河土家族自治县",
            level: 2,
          },
          {
            regionId: 520628,
            parentId: 5206,
            regionName: "松桃苗族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5223,
        parentId: 52,
        regionName: "黔西南布依族苗族自治州",
        level: 1,
        children: [
          {
            regionId: 522301,
            parentId: 5223,
            regionName: "兴义市",
            level: 2,
          },
          {
            regionId: 522302,
            parentId: 5223,
            regionName: "兴仁市",
            level: 2,
          },
          {
            regionId: 522323,
            parentId: 5223,
            regionName: "普安县",
            level: 2,
          },
          {
            regionId: 522324,
            parentId: 5223,
            regionName: "晴隆县",
            level: 2,
          },
          {
            regionId: 522325,
            parentId: 5223,
            regionName: "贞丰县",
            level: 2,
          },
          {
            regionId: 522326,
            parentId: 5223,
            regionName: "望谟县",
            level: 2,
          },
          {
            regionId: 522327,
            parentId: 5223,
            regionName: "册亨县",
            level: 2,
          },
          {
            regionId: 522328,
            parentId: 5223,
            regionName: "安龙县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5226,
        parentId: 52,
        regionName: "黔东南苗族侗族自治州",
        level: 1,
        children: [
          {
            regionId: 522601,
            parentId: 5226,
            regionName: "凯里市",
            level: 2,
          },
          {
            regionId: 522622,
            parentId: 5226,
            regionName: "黄平县",
            level: 2,
          },
          {
            regionId: 522623,
            parentId: 5226,
            regionName: "施秉县",
            level: 2,
          },
          {
            regionId: 522624,
            parentId: 5226,
            regionName: "三穗县",
            level: 2,
          },
          {
            regionId: 522625,
            parentId: 5226,
            regionName: "镇远县",
            level: 2,
          },
          {
            regionId: 522626,
            parentId: 5226,
            regionName: "岑巩县",
            level: 2,
          },
          {
            regionId: 522627,
            parentId: 5226,
            regionName: "天柱县",
            level: 2,
          },
          {
            regionId: 522628,
            parentId: 5226,
            regionName: "锦屏县",
            level: 2,
          },
          {
            regionId: 522629,
            parentId: 5226,
            regionName: "剑河县",
            level: 2,
          },
          {
            regionId: 522630,
            parentId: 5226,
            regionName: "台江县",
            level: 2,
          },
          {
            regionId: 522631,
            parentId: 5226,
            regionName: "黎平县",
            level: 2,
          },
          {
            regionId: 522632,
            parentId: 5226,
            regionName: "榕江县",
            level: 2,
          },
          {
            regionId: 522633,
            parentId: 5226,
            regionName: "从江县",
            level: 2,
          },
          {
            regionId: 522634,
            parentId: 5226,
            regionName: "雷山县",
            level: 2,
          },
          {
            regionId: 522635,
            parentId: 5226,
            regionName: "麻江县",
            level: 2,
          },
          {
            regionId: 522636,
            parentId: 5226,
            regionName: "丹寨县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5227,
        parentId: 52,
        regionName: "黔南布依族苗族自治州",
        level: 1,
        children: [
          {
            regionId: 522701,
            parentId: 5227,
            regionName: "都匀市",
            level: 2,
          },
          {
            regionId: 522702,
            parentId: 5227,
            regionName: "福泉市",
            level: 2,
          },
          {
            regionId: 522722,
            parentId: 5227,
            regionName: "荔波县",
            level: 2,
          },
          {
            regionId: 522723,
            parentId: 5227,
            regionName: "贵定县",
            level: 2,
          },
          {
            regionId: 522725,
            parentId: 5227,
            regionName: "瓮安县",
            level: 2,
          },
          {
            regionId: 522726,
            parentId: 5227,
            regionName: "独山县",
            level: 2,
          },
          {
            regionId: 522727,
            parentId: 5227,
            regionName: "平塘县",
            level: 2,
          },
          {
            regionId: 522728,
            parentId: 5227,
            regionName: "罗甸县",
            level: 2,
          },
          {
            regionId: 522729,
            parentId: 5227,
            regionName: "长顺县",
            level: 2,
          },
          {
            regionId: 522730,
            parentId: 5227,
            regionName: "龙里县",
            level: 2,
          },
          {
            regionId: 522731,
            parentId: 5227,
            regionName: "惠水县",
            level: 2,
          },
          {
            regionId: 522732,
            parentId: 5227,
            regionName: "三都水族自治县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 53,
    parentId: 0,
    regionName: "云南省",
    level: 0,
    children: [
      {
        regionId: 5301,
        parentId: 53,
        regionName: "昆明市",
        level: 1,
        children: [
          {
            regionId: 530102,
            parentId: 5301,
            regionName: "五华区",
            level: 2,
          },
          {
            regionId: 530103,
            parentId: 5301,
            regionName: "盘龙区",
            level: 2,
          },
          {
            regionId: 530111,
            parentId: 5301,
            regionName: "官渡区",
            level: 2,
          },
          {
            regionId: 530112,
            parentId: 5301,
            regionName: "西山区",
            level: 2,
          },
          {
            regionId: 530113,
            parentId: 5301,
            regionName: "东川区",
            level: 2,
          },
          {
            regionId: 530114,
            parentId: 5301,
            regionName: "呈贡区",
            level: 2,
          },
          {
            regionId: 530115,
            parentId: 5301,
            regionName: "晋宁区",
            level: 2,
          },
          {
            regionId: 530124,
            parentId: 5301,
            regionName: "富民县",
            level: 2,
          },
          {
            regionId: 530125,
            parentId: 5301,
            regionName: "宜良县",
            level: 2,
          },
          {
            regionId: 530126,
            parentId: 5301,
            regionName: "石林彝族自治县",
            level: 2,
          },
          {
            regionId: 530127,
            parentId: 5301,
            regionName: "嵩明县",
            level: 2,
          },
          {
            regionId: 530128,
            parentId: 5301,
            regionName: "禄劝彝族苗族自治县",
            level: 2,
          },
          {
            regionId: 530129,
            parentId: 5301,
            regionName: "寻甸回族彝族自治县",
            level: 2,
          },
          {
            regionId: 530181,
            parentId: 5301,
            regionName: "安宁市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5303,
        parentId: 53,
        regionName: "曲靖市",
        level: 1,
        children: [
          {
            regionId: 530302,
            parentId: 5303,
            regionName: "麒麟区",
            level: 2,
          },
          {
            regionId: 530303,
            parentId: 5303,
            regionName: "沾益区",
            level: 2,
          },
          {
            regionId: 530304,
            parentId: 5303,
            regionName: "马龙区",
            level: 2,
          },
          {
            regionId: 530322,
            parentId: 5303,
            regionName: "陆良县",
            level: 2,
          },
          {
            regionId: 530323,
            parentId: 5303,
            regionName: "师宗县",
            level: 2,
          },
          {
            regionId: 530324,
            parentId: 5303,
            regionName: "罗平县",
            level: 2,
          },
          {
            regionId: 530325,
            parentId: 5303,
            regionName: "富源县",
            level: 2,
          },
          {
            regionId: 530326,
            parentId: 5303,
            regionName: "会泽县",
            level: 2,
          },
          {
            regionId: 530381,
            parentId: 5303,
            regionName: "宣威市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5304,
        parentId: 53,
        regionName: "玉溪市",
        level: 1,
        children: [
          {
            regionId: 530402,
            parentId: 5304,
            regionName: "红塔区",
            level: 2,
          },
          {
            regionId: 530403,
            parentId: 5304,
            regionName: "江川区",
            level: 2,
          },
          {
            regionId: 530423,
            parentId: 5304,
            regionName: "通海县",
            level: 2,
          },
          {
            regionId: 530424,
            parentId: 5304,
            regionName: "华宁县",
            level: 2,
          },
          {
            regionId: 530425,
            parentId: 5304,
            regionName: "易门县",
            level: 2,
          },
          {
            regionId: 530426,
            parentId: 5304,
            regionName: "峨山彝族自治县",
            level: 2,
          },
          {
            regionId: 530427,
            parentId: 5304,
            regionName: "新平彝族傣族自治县",
            level: 2,
          },
          {
            regionId: 530428,
            parentId: 5304,
            regionName: "元江哈尼族彝族傣族自治县",
            level: 2,
          },
          {
            regionId: 530481,
            parentId: 5304,
            regionName: "澄江市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5305,
        parentId: 53,
        regionName: "保山市",
        level: 1,
        children: [
          {
            regionId: 530502,
            parentId: 5305,
            regionName: "隆阳区",
            level: 2,
          },
          {
            regionId: 530521,
            parentId: 5305,
            regionName: "施甸县",
            level: 2,
          },
          {
            regionId: 530523,
            parentId: 5305,
            regionName: "龙陵县",
            level: 2,
          },
          {
            regionId: 530524,
            parentId: 5305,
            regionName: "昌宁县",
            level: 2,
          },
          {
            regionId: 530581,
            parentId: 5305,
            regionName: "腾冲市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5306,
        parentId: 53,
        regionName: "昭通市",
        level: 1,
        children: [
          {
            regionId: 530602,
            parentId: 5306,
            regionName: "昭阳区",
            level: 2,
          },
          {
            regionId: 530621,
            parentId: 5306,
            regionName: "鲁甸县",
            level: 2,
          },
          {
            regionId: 530622,
            parentId: 5306,
            regionName: "巧家县",
            level: 2,
          },
          {
            regionId: 530623,
            parentId: 5306,
            regionName: "盐津县",
            level: 2,
          },
          {
            regionId: 530624,
            parentId: 5306,
            regionName: "大关县",
            level: 2,
          },
          {
            regionId: 530625,
            parentId: 5306,
            regionName: "永善县",
            level: 2,
          },
          {
            regionId: 530626,
            parentId: 5306,
            regionName: "绥江县",
            level: 2,
          },
          {
            regionId: 530627,
            parentId: 5306,
            regionName: "镇雄县",
            level: 2,
          },
          {
            regionId: 530628,
            parentId: 5306,
            regionName: "彝良县",
            level: 2,
          },
          {
            regionId: 530629,
            parentId: 5306,
            regionName: "威信县",
            level: 2,
          },
          {
            regionId: 530681,
            parentId: 5306,
            regionName: "水富市",
            level: 2,
          },
        ],
      },
      {
        regionId: 5307,
        parentId: 53,
        regionName: "丽江市",
        level: 1,
        children: [
          {
            regionId: 530702,
            parentId: 5307,
            regionName: "古城区",
            level: 2,
          },
          {
            regionId: 530721,
            parentId: 5307,
            regionName: "玉龙纳西族自治县",
            level: 2,
          },
          {
            regionId: 530722,
            parentId: 5307,
            regionName: "永胜县",
            level: 2,
          },
          {
            regionId: 530723,
            parentId: 5307,
            regionName: "华坪县",
            level: 2,
          },
          {
            regionId: 530724,
            parentId: 5307,
            regionName: "宁蒗彝族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5308,
        parentId: 53,
        regionName: "普洱市",
        level: 1,
        children: [
          {
            regionId: 530802,
            parentId: 5308,
            regionName: "思茅区",
            level: 2,
          },
          {
            regionId: 530821,
            parentId: 5308,
            regionName: "宁洱哈尼族彝族自治县",
            level: 2,
          },
          {
            regionId: 530822,
            parentId: 5308,
            regionName: "墨江哈尼族自治县",
            level: 2,
          },
          {
            regionId: 530823,
            parentId: 5308,
            regionName: "景东彝族自治县",
            level: 2,
          },
          {
            regionId: 530824,
            parentId: 5308,
            regionName: "景谷傣族彝族自治县",
            level: 2,
          },
          {
            regionId: 530825,
            parentId: 5308,
            regionName: "镇沅彝族哈尼族拉祜族自治县",
            level: 2,
          },
          {
            regionId: 530826,
            parentId: 5308,
            regionName: "江城哈尼族彝族自治县",
            level: 2,
          },
          {
            regionId: 530827,
            parentId: 5308,
            regionName: "孟连傣族拉祜族佤族自治县",
            level: 2,
          },
          {
            regionId: 530828,
            parentId: 5308,
            regionName: "澜沧拉祜族自治县",
            level: 2,
          },
          {
            regionId: 530829,
            parentId: 5308,
            regionName: "西盟佤族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5309,
        parentId: 53,
        regionName: "临沧市",
        level: 1,
        children: [
          {
            regionId: 530902,
            parentId: 5309,
            regionName: "临翔区",
            level: 2,
          },
          {
            regionId: 530921,
            parentId: 5309,
            regionName: "凤庆县",
            level: 2,
          },
          {
            regionId: 530922,
            parentId: 5309,
            regionName: "云县",
            level: 2,
          },
          {
            regionId: 530923,
            parentId: 5309,
            regionName: "永德县",
            level: 2,
          },
          {
            regionId: 530924,
            parentId: 5309,
            regionName: "镇康县",
            level: 2,
          },
          {
            regionId: 530925,
            parentId: 5309,
            regionName: "双江拉祜族佤族布朗族傣族自治县",
            level: 2,
          },
          {
            regionId: 530926,
            parentId: 5309,
            regionName: "耿马傣族佤族自治县",
            level: 2,
          },
          {
            regionId: 530927,
            parentId: 5309,
            regionName: "沧源佤族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5323,
        parentId: 53,
        regionName: "楚雄彝族自治州",
        level: 1,
        children: [
          {
            regionId: 532301,
            parentId: 5323,
            regionName: "楚雄市",
            level: 2,
          },
          {
            regionId: 532302,
            parentId: 5323,
            regionName: "禄丰市",
            level: 2,
          },
          {
            regionId: 532322,
            parentId: 5323,
            regionName: "双柏县",
            level: 2,
          },
          {
            regionId: 532323,
            parentId: 5323,
            regionName: "牟定县",
            level: 2,
          },
          {
            regionId: 532324,
            parentId: 5323,
            regionName: "南华县",
            level: 2,
          },
          {
            regionId: 532325,
            parentId: 5323,
            regionName: "姚安县",
            level: 2,
          },
          {
            regionId: 532326,
            parentId: 5323,
            regionName: "大姚县",
            level: 2,
          },
          {
            regionId: 532327,
            parentId: 5323,
            regionName: "永仁县",
            level: 2,
          },
          {
            regionId: 532328,
            parentId: 5323,
            regionName: "元谋县",
            level: 2,
          },
          {
            regionId: 532329,
            parentId: 5323,
            regionName: "武定县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5325,
        parentId: 53,
        regionName: "红河哈尼族彝族自治州",
        level: 1,
        children: [
          {
            regionId: 532501,
            parentId: 5325,
            regionName: "个旧市",
            level: 2,
          },
          {
            regionId: 532502,
            parentId: 5325,
            regionName: "开远市",
            level: 2,
          },
          {
            regionId: 532503,
            parentId: 5325,
            regionName: "蒙自市",
            level: 2,
          },
          {
            regionId: 532504,
            parentId: 5325,
            regionName: "弥勒市",
            level: 2,
          },
          {
            regionId: 532523,
            parentId: 5325,
            regionName: "屏边苗族自治县",
            level: 2,
          },
          {
            regionId: 532524,
            parentId: 5325,
            regionName: "建水县",
            level: 2,
          },
          {
            regionId: 532525,
            parentId: 5325,
            regionName: "石屏县",
            level: 2,
          },
          {
            regionId: 532527,
            parentId: 5325,
            regionName: "泸西县",
            level: 2,
          },
          {
            regionId: 532528,
            parentId: 5325,
            regionName: "元阳县",
            level: 2,
          },
          {
            regionId: 532529,
            parentId: 5325,
            regionName: "红河县",
            level: 2,
          },
          {
            regionId: 532530,
            parentId: 5325,
            regionName: "金平苗族瑶族傣族自治县",
            level: 2,
          },
          {
            regionId: 532531,
            parentId: 5325,
            regionName: "绿春县",
            level: 2,
          },
          {
            regionId: 532532,
            parentId: 5325,
            regionName: "河口瑶族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5326,
        parentId: 53,
        regionName: "文山壮族苗族自治州",
        level: 1,
        children: [
          {
            regionId: 532601,
            parentId: 5326,
            regionName: "文山市",
            level: 2,
          },
          {
            regionId: 532622,
            parentId: 5326,
            regionName: "砚山县",
            level: 2,
          },
          {
            regionId: 532623,
            parentId: 5326,
            regionName: "西畴县",
            level: 2,
          },
          {
            regionId: 532624,
            parentId: 5326,
            regionName: "麻栗坡县",
            level: 2,
          },
          {
            regionId: 532625,
            parentId: 5326,
            regionName: "马关县",
            level: 2,
          },
          {
            regionId: 532626,
            parentId: 5326,
            regionName: "丘北县",
            level: 2,
          },
          {
            regionId: 532627,
            parentId: 5326,
            regionName: "广南县",
            level: 2,
          },
          {
            regionId: 532628,
            parentId: 5326,
            regionName: "富宁县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5328,
        parentId: 53,
        regionName: "西双版纳傣族自治州",
        level: 1,
        children: [
          {
            regionId: 532801,
            parentId: 5328,
            regionName: "景洪市",
            level: 2,
          },
          {
            regionId: 532822,
            parentId: 5328,
            regionName: "勐海县",
            level: 2,
          },
          {
            regionId: 532823,
            parentId: 5328,
            regionName: "勐腊县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5329,
        parentId: 53,
        regionName: "大理白族自治州",
        level: 1,
        children: [
          {
            regionId: 532901,
            parentId: 5329,
            regionName: "大理市",
            level: 2,
          },
          {
            regionId: 532922,
            parentId: 5329,
            regionName: "漾濞彝族自治县",
            level: 2,
          },
          {
            regionId: 532923,
            parentId: 5329,
            regionName: "祥云县",
            level: 2,
          },
          {
            regionId: 532924,
            parentId: 5329,
            regionName: "宾川县",
            level: 2,
          },
          {
            regionId: 532925,
            parentId: 5329,
            regionName: "弥渡县",
            level: 2,
          },
          {
            regionId: 532926,
            parentId: 5329,
            regionName: "南涧彝族自治县",
            level: 2,
          },
          {
            regionId: 532927,
            parentId: 5329,
            regionName: "巍山彝族回族自治县",
            level: 2,
          },
          {
            regionId: 532928,
            parentId: 5329,
            regionName: "永平县",
            level: 2,
          },
          {
            regionId: 532929,
            parentId: 5329,
            regionName: "云龙县",
            level: 2,
          },
          {
            regionId: 532930,
            parentId: 5329,
            regionName: "洱源县",
            level: 2,
          },
          {
            regionId: 532931,
            parentId: 5329,
            regionName: "剑川县",
            level: 2,
          },
          {
            regionId: 532932,
            parentId: 5329,
            regionName: "鹤庆县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5331,
        parentId: 53,
        regionName: "德宏傣族景颇族自治州",
        level: 1,
        children: [
          {
            regionId: 533102,
            parentId: 5331,
            regionName: "瑞丽市",
            level: 2,
          },
          {
            regionId: 533103,
            parentId: 5331,
            regionName: "芒市",
            level: 2,
          },
          {
            regionId: 533122,
            parentId: 5331,
            regionName: "梁河县",
            level: 2,
          },
          {
            regionId: 533123,
            parentId: 5331,
            regionName: "盈江县",
            level: 2,
          },
          {
            regionId: 533124,
            parentId: 5331,
            regionName: "陇川县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5333,
        parentId: 53,
        regionName: "怒江傈僳族自治州",
        level: 1,
        children: [
          {
            regionId: 533301,
            parentId: 5333,
            regionName: "泸水市",
            level: 2,
          },
          {
            regionId: 533323,
            parentId: 5333,
            regionName: "福贡县",
            level: 2,
          },
          {
            regionId: 533324,
            parentId: 5333,
            regionName: "贡山独龙族怒族自治县",
            level: 2,
          },
          {
            regionId: 533325,
            parentId: 5333,
            regionName: "兰坪白族普米族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5334,
        parentId: 53,
        regionName: "迪庆藏族自治州",
        level: 1,
        children: [
          {
            regionId: 533401,
            parentId: 5334,
            regionName: "香格里拉市",
            level: 2,
          },
          {
            regionId: 533422,
            parentId: 5334,
            regionName: "德钦县",
            level: 2,
          },
          {
            regionId: 533423,
            parentId: 5334,
            regionName: "维西傈僳族自治县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 54,
    parentId: 0,
    regionName: "西藏自治区",
    level: 0,
    children: [
      {
        regionId: 5401,
        parentId: 54,
        regionName: "拉萨市",
        level: 1,
        children: [
          {
            regionId: 540102,
            parentId: 5401,
            regionName: "城关区",
            level: 2,
          },
          {
            regionId: 540103,
            parentId: 5401,
            regionName: "堆龙德庆区",
            level: 2,
          },
          {
            regionId: 540104,
            parentId: 5401,
            regionName: "达孜区",
            level: 2,
          },
          {
            regionId: 540121,
            parentId: 5401,
            regionName: "林周县",
            level: 2,
          },
          {
            regionId: 540122,
            parentId: 5401,
            regionName: "当雄县",
            level: 2,
          },
          {
            regionId: 540123,
            parentId: 5401,
            regionName: "尼木县",
            level: 2,
          },
          {
            regionId: 540124,
            parentId: 5401,
            regionName: "曲水县",
            level: 2,
          },
          {
            regionId: 540127,
            parentId: 5401,
            regionName: "墨竹工卡县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5402,
        parentId: 54,
        regionName: "日喀则市",
        level: 1,
        children: [
          {
            regionId: 540202,
            parentId: 5402,
            regionName: "桑珠孜区",
            level: 2,
          },
          {
            regionId: 540221,
            parentId: 5402,
            regionName: "南木林县",
            level: 2,
          },
          {
            regionId: 540222,
            parentId: 5402,
            regionName: "江孜县",
            level: 2,
          },
          {
            regionId: 540223,
            parentId: 5402,
            regionName: "定日县",
            level: 2,
          },
          {
            regionId: 540224,
            parentId: 5402,
            regionName: "萨迦县",
            level: 2,
          },
          {
            regionId: 540225,
            parentId: 5402,
            regionName: "拉孜县",
            level: 2,
          },
          {
            regionId: 540226,
            parentId: 5402,
            regionName: "昂仁县",
            level: 2,
          },
          {
            regionId: 540227,
            parentId: 5402,
            regionName: "谢通门县",
            level: 2,
          },
          {
            regionId: 540228,
            parentId: 5402,
            regionName: "白朗县",
            level: 2,
          },
          {
            regionId: 540229,
            parentId: 5402,
            regionName: "仁布县",
            level: 2,
          },
          {
            regionId: 540230,
            parentId: 5402,
            regionName: "康马县",
            level: 2,
          },
          {
            regionId: 540231,
            parentId: 5402,
            regionName: "定结县",
            level: 2,
          },
          {
            regionId: 540232,
            parentId: 5402,
            regionName: "仲巴县",
            level: 2,
          },
          {
            regionId: 540233,
            parentId: 5402,
            regionName: "亚东县",
            level: 2,
          },
          {
            regionId: 540234,
            parentId: 5402,
            regionName: "吉隆县",
            level: 2,
          },
          {
            regionId: 540235,
            parentId: 5402,
            regionName: "聂拉木县",
            level: 2,
          },
          {
            regionId: 540236,
            parentId: 5402,
            regionName: "萨嘎县",
            level: 2,
          },
          {
            regionId: 540237,
            parentId: 5402,
            regionName: "岗巴县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5403,
        parentId: 54,
        regionName: "昌都市",
        level: 1,
        children: [
          {
            regionId: 540302,
            parentId: 5403,
            regionName: "卡若区",
            level: 2,
          },
          {
            regionId: 540321,
            parentId: 5403,
            regionName: "江达县",
            level: 2,
          },
          {
            regionId: 540322,
            parentId: 5403,
            regionName: "贡觉县",
            level: 2,
          },
          {
            regionId: 540323,
            parentId: 5403,
            regionName: "类乌齐县",
            level: 2,
          },
          {
            regionId: 540324,
            parentId: 5403,
            regionName: "丁青县",
            level: 2,
          },
          {
            regionId: 540325,
            parentId: 5403,
            regionName: "察雅县",
            level: 2,
          },
          {
            regionId: 540326,
            parentId: 5403,
            regionName: "八宿县",
            level: 2,
          },
          {
            regionId: 540327,
            parentId: 5403,
            regionName: "左贡县",
            level: 2,
          },
          {
            regionId: 540328,
            parentId: 5403,
            regionName: "芒康县",
            level: 2,
          },
          {
            regionId: 540329,
            parentId: 5403,
            regionName: "洛隆县",
            level: 2,
          },
          {
            regionId: 540330,
            parentId: 5403,
            regionName: "边坝县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5404,
        parentId: 54,
        regionName: "林芝市",
        level: 1,
        children: [
          {
            regionId: 540402,
            parentId: 5404,
            regionName: "巴宜区",
            level: 2,
          },
          {
            regionId: 540421,
            parentId: 5404,
            regionName: "工布江达县",
            level: 2,
          },
          {
            regionId: 540422,
            parentId: 5404,
            regionName: "米林市",
            level: 2,
          },
          {
            regionId: 540423,
            parentId: 5404,
            regionName: "墨脱县",
            level: 2,
          },
          {
            regionId: 540424,
            parentId: 5404,
            regionName: "波密县",
            level: 2,
          },
          {
            regionId: 540425,
            parentId: 5404,
            regionName: "察隅县",
            level: 2,
          },
          {
            regionId: 540426,
            parentId: 5404,
            regionName: "朗县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5405,
        parentId: 54,
        regionName: "山南市",
        level: 1,
        children: [
          {
            regionId: 540502,
            parentId: 5405,
            regionName: "乃东区",
            level: 2,
          },
          {
            regionId: 540521,
            parentId: 5405,
            regionName: "扎囊县",
            level: 2,
          },
          {
            regionId: 540522,
            parentId: 5405,
            regionName: "贡嘎县",
            level: 2,
          },
          {
            regionId: 540523,
            parentId: 5405,
            regionName: "桑日县",
            level: 2,
          },
          {
            regionId: 540524,
            parentId: 5405,
            regionName: "琼结县",
            level: 2,
          },
          {
            regionId: 540525,
            parentId: 5405,
            regionName: "曲松县",
            level: 2,
          },
          {
            regionId: 540526,
            parentId: 5405,
            regionName: "措美县",
            level: 2,
          },
          {
            regionId: 540527,
            parentId: 5405,
            regionName: "洛扎县",
            level: 2,
          },
          {
            regionId: 540528,
            parentId: 5405,
            regionName: "加查县",
            level: 2,
          },
          {
            regionId: 540529,
            parentId: 5405,
            regionName: "隆子县",
            level: 2,
          },
          {
            regionId: 540530,
            parentId: 5405,
            regionName: "错那市",
            level: 2,
          },
          {
            regionId: 540531,
            parentId: 5405,
            regionName: "浪卡子县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5406,
        parentId: 54,
        regionName: "那曲市",
        level: 1,
        children: [
          {
            regionId: 540602,
            parentId: 5406,
            regionName: "色尼区",
            level: 2,
          },
          {
            regionId: 540621,
            parentId: 5406,
            regionName: "嘉黎县",
            level: 2,
          },
          {
            regionId: 540622,
            parentId: 5406,
            regionName: "比如县",
            level: 2,
          },
          {
            regionId: 540623,
            parentId: 5406,
            regionName: "聂荣县",
            level: 2,
          },
          {
            regionId: 540624,
            parentId: 5406,
            regionName: "安多县",
            level: 2,
          },
          {
            regionId: 540625,
            parentId: 5406,
            regionName: "申扎县",
            level: 2,
          },
          {
            regionId: 540626,
            parentId: 5406,
            regionName: "索县",
            level: 2,
          },
          {
            regionId: 540627,
            parentId: 5406,
            regionName: "班戈县",
            level: 2,
          },
          {
            regionId: 540628,
            parentId: 5406,
            regionName: "巴青县",
            level: 2,
          },
          {
            regionId: 540629,
            parentId: 5406,
            regionName: "尼玛县",
            level: 2,
          },
          {
            regionId: 540630,
            parentId: 5406,
            regionName: "双湖县",
            level: 2,
          },
        ],
      },
      {
        regionId: 5425,
        parentId: 54,
        regionName: "阿里地区",
        level: 1,
        children: [
          {
            regionId: 542521,
            parentId: 5425,
            regionName: "普兰县",
            level: 2,
          },
          {
            regionId: 542522,
            parentId: 5425,
            regionName: "札达县",
            level: 2,
          },
          {
            regionId: 542523,
            parentId: 5425,
            regionName: "噶尔县",
            level: 2,
          },
          {
            regionId: 542524,
            parentId: 5425,
            regionName: "日土县",
            level: 2,
          },
          {
            regionId: 542525,
            parentId: 5425,
            regionName: "革吉县",
            level: 2,
          },
          {
            regionId: 542526,
            parentId: 5425,
            regionName: "改则县",
            level: 2,
          },
          {
            regionId: 542527,
            parentId: 5425,
            regionName: "措勤县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 61,
    parentId: 0,
    regionName: "陕西省",
    level: 0,
    children: [
      {
        regionId: 6101,
        parentId: 61,
        regionName: "西安市",
        level: 1,
        children: [
          {
            regionId: 610102,
            parentId: 6101,
            regionName: "新城区",
            level: 2,
          },
          {
            regionId: 610103,
            parentId: 6101,
            regionName: "碑林区",
            level: 2,
          },
          {
            regionId: 610104,
            parentId: 6101,
            regionName: "莲湖区",
            level: 2,
          },
          {
            regionId: 610111,
            parentId: 6101,
            regionName: "灞桥区",
            level: 2,
          },
          {
            regionId: 610112,
            parentId: 6101,
            regionName: "未央区",
            level: 2,
          },
          {
            regionId: 610113,
            parentId: 6101,
            regionName: "雁塔区",
            level: 2,
          },
          {
            regionId: 610114,
            parentId: 6101,
            regionName: "阎良区",
            level: 2,
          },
          {
            regionId: 610115,
            parentId: 6101,
            regionName: "临潼区",
            level: 2,
          },
          {
            regionId: 610116,
            parentId: 6101,
            regionName: "长安区",
            level: 2,
          },
          {
            regionId: 610117,
            parentId: 6101,
            regionName: "高陵区",
            level: 2,
          },
          {
            regionId: 610118,
            parentId: 6101,
            regionName: "鄠邑区",
            level: 2,
          },
          {
            regionId: 610122,
            parentId: 6101,
            regionName: "蓝田县",
            level: 2,
          },
          {
            regionId: 610124,
            parentId: 6101,
            regionName: "周至县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6102,
        parentId: 61,
        regionName: "铜川市",
        level: 1,
        children: [
          {
            regionId: 610202,
            parentId: 6102,
            regionName: "王益区",
            level: 2,
          },
          {
            regionId: 610203,
            parentId: 6102,
            regionName: "印台区",
            level: 2,
          },
          {
            regionId: 610204,
            parentId: 6102,
            regionName: "耀州区",
            level: 2,
          },
          {
            regionId: 610222,
            parentId: 6102,
            regionName: "宜君县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6103,
        parentId: 61,
        regionName: "宝鸡市",
        level: 1,
        children: [
          {
            regionId: 610302,
            parentId: 6103,
            regionName: "渭滨区",
            level: 2,
          },
          {
            regionId: 610303,
            parentId: 6103,
            regionName: "金台区",
            level: 2,
          },
          {
            regionId: 610304,
            parentId: 6103,
            regionName: "陈仓区",
            level: 2,
          },
          {
            regionId: 610305,
            parentId: 6103,
            regionName: "凤翔区",
            level: 2,
          },
          {
            regionId: 610323,
            parentId: 6103,
            regionName: "岐山县",
            level: 2,
          },
          {
            regionId: 610324,
            parentId: 6103,
            regionName: "扶风县",
            level: 2,
          },
          {
            regionId: 610326,
            parentId: 6103,
            regionName: "眉县",
            level: 2,
          },
          {
            regionId: 610327,
            parentId: 6103,
            regionName: "陇县",
            level: 2,
          },
          {
            regionId: 610328,
            parentId: 6103,
            regionName: "千阳县",
            level: 2,
          },
          {
            regionId: 610329,
            parentId: 6103,
            regionName: "麟游县",
            level: 2,
          },
          {
            regionId: 610330,
            parentId: 6103,
            regionName: "凤县",
            level: 2,
          },
          {
            regionId: 610331,
            parentId: 6103,
            regionName: "太白县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6104,
        parentId: 61,
        regionName: "咸阳市",
        level: 1,
        children: [
          {
            regionId: 610402,
            parentId: 6104,
            regionName: "秦都区",
            level: 2,
          },
          {
            regionId: 610403,
            parentId: 6104,
            regionName: "杨陵区",
            level: 2,
          },
          {
            regionId: 610404,
            parentId: 6104,
            regionName: "渭城区",
            level: 2,
          },
          {
            regionId: 610422,
            parentId: 6104,
            regionName: "三原县",
            level: 2,
          },
          {
            regionId: 610423,
            parentId: 6104,
            regionName: "泾阳县",
            level: 2,
          },
          {
            regionId: 610424,
            parentId: 6104,
            regionName: "乾县",
            level: 2,
          },
          {
            regionId: 610425,
            parentId: 6104,
            regionName: "礼泉县",
            level: 2,
          },
          {
            regionId: 610426,
            parentId: 6104,
            regionName: "永寿县",
            level: 2,
          },
          {
            regionId: 610428,
            parentId: 6104,
            regionName: "长武县",
            level: 2,
          },
          {
            regionId: 610429,
            parentId: 6104,
            regionName: "旬邑县",
            level: 2,
          },
          {
            regionId: 610430,
            parentId: 6104,
            regionName: "淳化县",
            level: 2,
          },
          {
            regionId: 610431,
            parentId: 6104,
            regionName: "武功县",
            level: 2,
          },
          {
            regionId: 610481,
            parentId: 6104,
            regionName: "兴平市",
            level: 2,
          },
          {
            regionId: 610482,
            parentId: 6104,
            regionName: "彬州市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6105,
        parentId: 61,
        regionName: "渭南市",
        level: 1,
        children: [
          {
            regionId: 610502,
            parentId: 6105,
            regionName: "临渭区",
            level: 2,
          },
          {
            regionId: 610503,
            parentId: 6105,
            regionName: "华州区",
            level: 2,
          },
          {
            regionId: 610522,
            parentId: 6105,
            regionName: "潼关县",
            level: 2,
          },
          {
            regionId: 610523,
            parentId: 6105,
            regionName: "大荔县",
            level: 2,
          },
          {
            regionId: 610524,
            parentId: 6105,
            regionName: "合阳县",
            level: 2,
          },
          {
            regionId: 610525,
            parentId: 6105,
            regionName: "澄城县",
            level: 2,
          },
          {
            regionId: 610526,
            parentId: 6105,
            regionName: "蒲城县",
            level: 2,
          },
          {
            regionId: 610527,
            parentId: 6105,
            regionName: "白水县",
            level: 2,
          },
          {
            regionId: 610528,
            parentId: 6105,
            regionName: "富平县",
            level: 2,
          },
          {
            regionId: 610581,
            parentId: 6105,
            regionName: "韩城市",
            level: 2,
          },
          {
            regionId: 610582,
            parentId: 6105,
            regionName: "华阴市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6106,
        parentId: 61,
        regionName: "延安市",
        level: 1,
        children: [
          {
            regionId: 610602,
            parentId: 6106,
            regionName: "宝塔区",
            level: 2,
          },
          {
            regionId: 610603,
            parentId: 6106,
            regionName: "安塞区",
            level: 2,
          },
          {
            regionId: 610621,
            parentId: 6106,
            regionName: "延长县",
            level: 2,
          },
          {
            regionId: 610622,
            parentId: 6106,
            regionName: "延川县",
            level: 2,
          },
          {
            regionId: 610625,
            parentId: 6106,
            regionName: "志丹县",
            level: 2,
          },
          {
            regionId: 610626,
            parentId: 6106,
            regionName: "吴起县",
            level: 2,
          },
          {
            regionId: 610627,
            parentId: 6106,
            regionName: "甘泉县",
            level: 2,
          },
          {
            regionId: 610628,
            parentId: 6106,
            regionName: "富县",
            level: 2,
          },
          {
            regionId: 610629,
            parentId: 6106,
            regionName: "洛川县",
            level: 2,
          },
          {
            regionId: 610630,
            parentId: 6106,
            regionName: "宜川县",
            level: 2,
          },
          {
            regionId: 610631,
            parentId: 6106,
            regionName: "黄龙县",
            level: 2,
          },
          {
            regionId: 610632,
            parentId: 6106,
            regionName: "黄陵县",
            level: 2,
          },
          {
            regionId: 610681,
            parentId: 6106,
            regionName: "子长市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6107,
        parentId: 61,
        regionName: "汉中市",
        level: 1,
        children: [
          {
            regionId: 610702,
            parentId: 6107,
            regionName: "汉台区",
            level: 2,
          },
          {
            regionId: 610703,
            parentId: 6107,
            regionName: "南郑区",
            level: 2,
          },
          {
            regionId: 610722,
            parentId: 6107,
            regionName: "城固县",
            level: 2,
          },
          {
            regionId: 610723,
            parentId: 6107,
            regionName: "洋县",
            level: 2,
          },
          {
            regionId: 610724,
            parentId: 6107,
            regionName: "西乡县",
            level: 2,
          },
          {
            regionId: 610725,
            parentId: 6107,
            regionName: "勉县",
            level: 2,
          },
          {
            regionId: 610726,
            parentId: 6107,
            regionName: "宁强县",
            level: 2,
          },
          {
            regionId: 610727,
            parentId: 6107,
            regionName: "略阳县",
            level: 2,
          },
          {
            regionId: 610728,
            parentId: 6107,
            regionName: "镇巴县",
            level: 2,
          },
          {
            regionId: 610729,
            parentId: 6107,
            regionName: "留坝县",
            level: 2,
          },
          {
            regionId: 610730,
            parentId: 6107,
            regionName: "佛坪县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6108,
        parentId: 61,
        regionName: "榆林市",
        level: 1,
        children: [
          {
            regionId: 610802,
            parentId: 6108,
            regionName: "榆阳区",
            level: 2,
          },
          {
            regionId: 610803,
            parentId: 6108,
            regionName: "横山区",
            level: 2,
          },
          {
            regionId: 610822,
            parentId: 6108,
            regionName: "府谷县",
            level: 2,
          },
          {
            regionId: 610824,
            parentId: 6108,
            regionName: "靖边县",
            level: 2,
          },
          {
            regionId: 610825,
            parentId: 6108,
            regionName: "定边县",
            level: 2,
          },
          {
            regionId: 610826,
            parentId: 6108,
            regionName: "绥德县",
            level: 2,
          },
          {
            regionId: 610827,
            parentId: 6108,
            regionName: "米脂县",
            level: 2,
          },
          {
            regionId: 610828,
            parentId: 6108,
            regionName: "佳县",
            level: 2,
          },
          {
            regionId: 610829,
            parentId: 6108,
            regionName: "吴堡县",
            level: 2,
          },
          {
            regionId: 610830,
            parentId: 6108,
            regionName: "清涧县",
            level: 2,
          },
          {
            regionId: 610831,
            parentId: 6108,
            regionName: "子洲县",
            level: 2,
          },
          {
            regionId: 610881,
            parentId: 6108,
            regionName: "神木市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6109,
        parentId: 61,
        regionName: "安康市",
        level: 1,
        children: [
          {
            regionId: 610902,
            parentId: 6109,
            regionName: "汉滨区",
            level: 2,
          },
          {
            regionId: 610921,
            parentId: 6109,
            regionName: "汉阴县",
            level: 2,
          },
          {
            regionId: 610922,
            parentId: 6109,
            regionName: "石泉县",
            level: 2,
          },
          {
            regionId: 610923,
            parentId: 6109,
            regionName: "宁陕县",
            level: 2,
          },
          {
            regionId: 610924,
            parentId: 6109,
            regionName: "紫阳县",
            level: 2,
          },
          {
            regionId: 610925,
            parentId: 6109,
            regionName: "岚皋县",
            level: 2,
          },
          {
            regionId: 610926,
            parentId: 6109,
            regionName: "平利县",
            level: 2,
          },
          {
            regionId: 610927,
            parentId: 6109,
            regionName: "镇坪县",
            level: 2,
          },
          {
            regionId: 610929,
            parentId: 6109,
            regionName: "白河县",
            level: 2,
          },
          {
            regionId: 610981,
            parentId: 6109,
            regionName: "旬阳市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6110,
        parentId: 61,
        regionName: "商洛市",
        level: 1,
        children: [
          {
            regionId: 611002,
            parentId: 6110,
            regionName: "商州区",
            level: 2,
          },
          {
            regionId: 611021,
            parentId: 6110,
            regionName: "洛南县",
            level: 2,
          },
          {
            regionId: 611022,
            parentId: 6110,
            regionName: "丹凤县",
            level: 2,
          },
          {
            regionId: 611023,
            parentId: 6110,
            regionName: "商南县",
            level: 2,
          },
          {
            regionId: 611024,
            parentId: 6110,
            regionName: "山阳县",
            level: 2,
          },
          {
            regionId: 611025,
            parentId: 6110,
            regionName: "镇安县",
            level: 2,
          },
          {
            regionId: 611026,
            parentId: 6110,
            regionName: "柞水县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 62,
    parentId: 0,
    regionName: "甘肃省",
    level: 0,
    children: [
      {
        regionId: 6201,
        parentId: 62,
        regionName: "兰州市",
        level: 1,
        children: [
          {
            regionId: 620102,
            parentId: 6201,
            regionName: "城关区",
            level: 2,
          },
          {
            regionId: 620103,
            parentId: 6201,
            regionName: "七里河区",
            level: 2,
          },
          {
            regionId: 620104,
            parentId: 6201,
            regionName: "西固区",
            level: 2,
          },
          {
            regionId: 620105,
            parentId: 6201,
            regionName: "安宁区",
            level: 2,
          },
          {
            regionId: 620111,
            parentId: 6201,
            regionName: "红古区",
            level: 2,
          },
          {
            regionId: 620121,
            parentId: 6201,
            regionName: "永登县",
            level: 2,
          },
          {
            regionId: 620122,
            parentId: 6201,
            regionName: "皋兰县",
            level: 2,
          },
          {
            regionId: 620123,
            parentId: 6201,
            regionName: "榆中县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6202,
        parentId: 62,
        regionName: "嘉峪关市",
        level: 1,
        children: [
          {
            regionId: 620200,
            parentId: 6202,
            regionName: "嘉峪关市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6203,
        parentId: 62,
        regionName: "金昌市",
        level: 1,
        children: [
          {
            regionId: 620302,
            parentId: 6203,
            regionName: "金川区",
            level: 2,
          },
          {
            regionId: 620321,
            parentId: 6203,
            regionName: "永昌县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6204,
        parentId: 62,
        regionName: "白银市",
        level: 1,
        children: [
          {
            regionId: 620402,
            parentId: 6204,
            regionName: "白银区",
            level: 2,
          },
          {
            regionId: 620403,
            parentId: 6204,
            regionName: "平川区",
            level: 2,
          },
          {
            regionId: 620421,
            parentId: 6204,
            regionName: "靖远县",
            level: 2,
          },
          {
            regionId: 620422,
            parentId: 6204,
            regionName: "会宁县",
            level: 2,
          },
          {
            regionId: 620423,
            parentId: 6204,
            regionName: "景泰县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6205,
        parentId: 62,
        regionName: "天水市",
        level: 1,
        children: [
          {
            regionId: 620502,
            parentId: 6205,
            regionName: "秦州区",
            level: 2,
          },
          {
            regionId: 620503,
            parentId: 6205,
            regionName: "麦积区",
            level: 2,
          },
          {
            regionId: 620521,
            parentId: 6205,
            regionName: "清水县",
            level: 2,
          },
          {
            regionId: 620522,
            parentId: 6205,
            regionName: "秦安县",
            level: 2,
          },
          {
            regionId: 620523,
            parentId: 6205,
            regionName: "甘谷县",
            level: 2,
          },
          {
            regionId: 620524,
            parentId: 6205,
            regionName: "武山县",
            level: 2,
          },
          {
            regionId: 620525,
            parentId: 6205,
            regionName: "张家川回族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6206,
        parentId: 62,
        regionName: "武威市",
        level: 1,
        children: [
          {
            regionId: 620602,
            parentId: 6206,
            regionName: "凉州区",
            level: 2,
          },
          {
            regionId: 620621,
            parentId: 6206,
            regionName: "民勤县",
            level: 2,
          },
          {
            regionId: 620622,
            parentId: 6206,
            regionName: "古浪县",
            level: 2,
          },
          {
            regionId: 620623,
            parentId: 6206,
            regionName: "天祝藏族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6207,
        parentId: 62,
        regionName: "张掖市",
        level: 1,
        children: [
          {
            regionId: 620702,
            parentId: 6207,
            regionName: "甘州区",
            level: 2,
          },
          {
            regionId: 620721,
            parentId: 6207,
            regionName: "肃南裕固族自治县",
            level: 2,
          },
          {
            regionId: 620722,
            parentId: 6207,
            regionName: "民乐县",
            level: 2,
          },
          {
            regionId: 620723,
            parentId: 6207,
            regionName: "临泽县",
            level: 2,
          },
          {
            regionId: 620724,
            parentId: 6207,
            regionName: "高台县",
            level: 2,
          },
          {
            regionId: 620725,
            parentId: 6207,
            regionName: "山丹县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6208,
        parentId: 62,
        regionName: "平凉市",
        level: 1,
        children: [
          {
            regionId: 620802,
            parentId: 6208,
            regionName: "崆峒区",
            level: 2,
          },
          {
            regionId: 620821,
            parentId: 6208,
            regionName: "泾川县",
            level: 2,
          },
          {
            regionId: 620822,
            parentId: 6208,
            regionName: "灵台县",
            level: 2,
          },
          {
            regionId: 620823,
            parentId: 6208,
            regionName: "崇信县",
            level: 2,
          },
          {
            regionId: 620825,
            parentId: 6208,
            regionName: "庄浪县",
            level: 2,
          },
          {
            regionId: 620826,
            parentId: 6208,
            regionName: "静宁县",
            level: 2,
          },
          {
            regionId: 620881,
            parentId: 6208,
            regionName: "华亭市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6209,
        parentId: 62,
        regionName: "酒泉市",
        level: 1,
        children: [
          {
            regionId: 620902,
            parentId: 6209,
            regionName: "肃州区",
            level: 2,
          },
          {
            regionId: 620921,
            parentId: 6209,
            regionName: "金塔县",
            level: 2,
          },
          {
            regionId: 620922,
            parentId: 6209,
            regionName: "瓜州县",
            level: 2,
          },
          {
            regionId: 620923,
            parentId: 6209,
            regionName: "肃北蒙古族自治县",
            level: 2,
          },
          {
            regionId: 620924,
            parentId: 6209,
            regionName: "阿克塞哈萨克族自治县",
            level: 2,
          },
          {
            regionId: 620981,
            parentId: 6209,
            regionName: "玉门市",
            level: 2,
          },
          {
            regionId: 620982,
            parentId: 6209,
            regionName: "敦煌市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6210,
        parentId: 62,
        regionName: "庆阳市",
        level: 1,
        children: [
          {
            regionId: 621002,
            parentId: 6210,
            regionName: "西峰区",
            level: 2,
          },
          {
            regionId: 621021,
            parentId: 6210,
            regionName: "庆城县",
            level: 2,
          },
          {
            regionId: 621022,
            parentId: 6210,
            regionName: "环县",
            level: 2,
          },
          {
            regionId: 621023,
            parentId: 6210,
            regionName: "华池县",
            level: 2,
          },
          {
            regionId: 621024,
            parentId: 6210,
            regionName: "合水县",
            level: 2,
          },
          {
            regionId: 621025,
            parentId: 6210,
            regionName: "正宁县",
            level: 2,
          },
          {
            regionId: 621026,
            parentId: 6210,
            regionName: "宁县",
            level: 2,
          },
          {
            regionId: 621027,
            parentId: 6210,
            regionName: "镇原县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6211,
        parentId: 62,
        regionName: "定西市",
        level: 1,
        children: [
          {
            regionId: 621102,
            parentId: 6211,
            regionName: "安定区",
            level: 2,
          },
          {
            regionId: 621121,
            parentId: 6211,
            regionName: "通渭县",
            level: 2,
          },
          {
            regionId: 621122,
            parentId: 6211,
            regionName: "陇西县",
            level: 2,
          },
          {
            regionId: 621123,
            parentId: 6211,
            regionName: "渭源县",
            level: 2,
          },
          {
            regionId: 621124,
            parentId: 6211,
            regionName: "临洮县",
            level: 2,
          },
          {
            regionId: 621125,
            parentId: 6211,
            regionName: "漳县",
            level: 2,
          },
          {
            regionId: 621126,
            parentId: 6211,
            regionName: "岷县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6212,
        parentId: 62,
        regionName: "陇南市",
        level: 1,
        children: [
          {
            regionId: 621202,
            parentId: 6212,
            regionName: "武都区",
            level: 2,
          },
          {
            regionId: 621221,
            parentId: 6212,
            regionName: "成县",
            level: 2,
          },
          {
            regionId: 621222,
            parentId: 6212,
            regionName: "文县",
            level: 2,
          },
          {
            regionId: 621223,
            parentId: 6212,
            regionName: "宕昌县",
            level: 2,
          },
          {
            regionId: 621224,
            parentId: 6212,
            regionName: "康县",
            level: 2,
          },
          {
            regionId: 621225,
            parentId: 6212,
            regionName: "西和县",
            level: 2,
          },
          {
            regionId: 621226,
            parentId: 6212,
            regionName: "礼县",
            level: 2,
          },
          {
            regionId: 621227,
            parentId: 6212,
            regionName: "徽县",
            level: 2,
          },
          {
            regionId: 621228,
            parentId: 6212,
            regionName: "两当县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6229,
        parentId: 62,
        regionName: "临夏回族自治州",
        level: 1,
        children: [
          {
            regionId: 622901,
            parentId: 6229,
            regionName: "临夏市",
            level: 2,
          },
          {
            regionId: 622921,
            parentId: 6229,
            regionName: "临夏县",
            level: 2,
          },
          {
            regionId: 622922,
            parentId: 6229,
            regionName: "康乐县",
            level: 2,
          },
          {
            regionId: 622923,
            parentId: 6229,
            regionName: "永靖县",
            level: 2,
          },
          {
            regionId: 622924,
            parentId: 6229,
            regionName: "广河县",
            level: 2,
          },
          {
            regionId: 622925,
            parentId: 6229,
            regionName: "和政县",
            level: 2,
          },
          {
            regionId: 622926,
            parentId: 6229,
            regionName: "东乡族自治县",
            level: 2,
          },
          {
            regionId: 622927,
            parentId: 6229,
            regionName: "积石山保安族东乡族撒拉族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6230,
        parentId: 62,
        regionName: "甘南藏族自治州",
        level: 1,
        children: [
          {
            regionId: 623001,
            parentId: 6230,
            regionName: "合作市",
            level: 2,
          },
          {
            regionId: 623021,
            parentId: 6230,
            regionName: "临潭县",
            level: 2,
          },
          {
            regionId: 623022,
            parentId: 6230,
            regionName: "卓尼县",
            level: 2,
          },
          {
            regionId: 623023,
            parentId: 6230,
            regionName: "舟曲县",
            level: 2,
          },
          {
            regionId: 623024,
            parentId: 6230,
            regionName: "迭部县",
            level: 2,
          },
          {
            regionId: 623025,
            parentId: 6230,
            regionName: "玛曲县",
            level: 2,
          },
          {
            regionId: 623026,
            parentId: 6230,
            regionName: "碌曲县",
            level: 2,
          },
          {
            regionId: 623027,
            parentId: 6230,
            regionName: "夏河县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 63,
    parentId: 0,
    regionName: "青海省",
    level: 0,
    children: [
      {
        regionId: 6301,
        parentId: 63,
        regionName: "西宁市",
        level: 1,
        children: [
          {
            regionId: 630102,
            parentId: 6301,
            regionName: "城东区",
            level: 2,
          },
          {
            regionId: 630103,
            parentId: 6301,
            regionName: "城中区",
            level: 2,
          },
          {
            regionId: 630104,
            parentId: 6301,
            regionName: "城西区",
            level: 2,
          },
          {
            regionId: 630105,
            parentId: 6301,
            regionName: "城北区",
            level: 2,
          },
          {
            regionId: 630106,
            parentId: 6301,
            regionName: "湟中区",
            level: 2,
          },
          {
            regionId: 630121,
            parentId: 6301,
            regionName: "大通回族土族自治县",
            level: 2,
          },
          {
            regionId: 630123,
            parentId: 6301,
            regionName: "湟源县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6302,
        parentId: 63,
        regionName: "海东市",
        level: 1,
        children: [
          {
            regionId: 630202,
            parentId: 6302,
            regionName: "乐都区",
            level: 2,
          },
          {
            regionId: 630203,
            parentId: 6302,
            regionName: "平安区",
            level: 2,
          },
          {
            regionId: 630222,
            parentId: 6302,
            regionName: "民和回族土族自治县",
            level: 2,
          },
          {
            regionId: 630223,
            parentId: 6302,
            regionName: "互助土族自治县",
            level: 2,
          },
          {
            regionId: 630224,
            parentId: 6302,
            regionName: "化隆回族自治县",
            level: 2,
          },
          {
            regionId: 630225,
            parentId: 6302,
            regionName: "循化撒拉族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6322,
        parentId: 63,
        regionName: "海北藏族自治州",
        level: 1,
        children: [
          {
            regionId: 632221,
            parentId: 6322,
            regionName: "门源回族自治县",
            level: 2,
          },
          {
            regionId: 632222,
            parentId: 6322,
            regionName: "祁连县",
            level: 2,
          },
          {
            regionId: 632223,
            parentId: 6322,
            regionName: "海晏县",
            level: 2,
          },
          {
            regionId: 632224,
            parentId: 6322,
            regionName: "刚察县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6323,
        parentId: 63,
        regionName: "黄南藏族自治州",
        level: 1,
        children: [
          {
            regionId: 632301,
            parentId: 6323,
            regionName: "同仁市",
            level: 2,
          },
          {
            regionId: 632322,
            parentId: 6323,
            regionName: "尖扎县",
            level: 2,
          },
          {
            regionId: 632323,
            parentId: 6323,
            regionName: "泽库县",
            level: 2,
          },
          {
            regionId: 632324,
            parentId: 6323,
            regionName: "河南蒙古族自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6325,
        parentId: 63,
        regionName: "海南藏族自治州",
        level: 1,
        children: [
          {
            regionId: 632521,
            parentId: 6325,
            regionName: "共和县",
            level: 2,
          },
          {
            regionId: 632522,
            parentId: 6325,
            regionName: "同德县",
            level: 2,
          },
          {
            regionId: 632523,
            parentId: 6325,
            regionName: "贵德县",
            level: 2,
          },
          {
            regionId: 632524,
            parentId: 6325,
            regionName: "兴海县",
            level: 2,
          },
          {
            regionId: 632525,
            parentId: 6325,
            regionName: "贵南县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6326,
        parentId: 63,
        regionName: "果洛藏族自治州",
        level: 1,
        children: [
          {
            regionId: 632621,
            parentId: 6326,
            regionName: "玛沁县",
            level: 2,
          },
          {
            regionId: 632622,
            parentId: 6326,
            regionName: "班玛县",
            level: 2,
          },
          {
            regionId: 632623,
            parentId: 6326,
            regionName: "甘德县",
            level: 2,
          },
          {
            regionId: 632624,
            parentId: 6326,
            regionName: "达日县",
            level: 2,
          },
          {
            regionId: 632625,
            parentId: 6326,
            regionName: "久治县",
            level: 2,
          },
          {
            regionId: 632626,
            parentId: 6326,
            regionName: "玛多县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6327,
        parentId: 63,
        regionName: "玉树藏族自治州",
        level: 1,
        children: [
          {
            regionId: 632701,
            parentId: 6327,
            regionName: "玉树市",
            level: 2,
          },
          {
            regionId: 632722,
            parentId: 6327,
            regionName: "杂多县",
            level: 2,
          },
          {
            regionId: 632723,
            parentId: 6327,
            regionName: "称多县",
            level: 2,
          },
          {
            regionId: 632724,
            parentId: 6327,
            regionName: "治多县",
            level: 2,
          },
          {
            regionId: 632725,
            parentId: 6327,
            regionName: "囊谦县",
            level: 2,
          },
          {
            regionId: 632726,
            parentId: 6327,
            regionName: "曲麻莱县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6328,
        parentId: 63,
        regionName: "海西蒙古族藏族自治州",
        level: 1,
        children: [
          {
            regionId: 632801,
            parentId: 6328,
            regionName: "格尔木市",
            level: 2,
          },
          {
            regionId: 632802,
            parentId: 6328,
            regionName: "德令哈市",
            level: 2,
          },
          {
            regionId: 632803,
            parentId: 6328,
            regionName: "茫崖市",
            level: 2,
          },
          {
            regionId: 632821,
            parentId: 6328,
            regionName: "乌兰县",
            level: 2,
          },
          {
            regionId: 632822,
            parentId: 6328,
            regionName: "都兰县",
            level: 2,
          },
          {
            regionId: 632823,
            parentId: 6328,
            regionName: "天峻县",
            level: 2,
          },
          {
            regionId: 632825,
            parentId: 6328,
            regionName: "大柴旦行政委员会",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 64,
    parentId: 0,
    regionName: "宁夏回族自治区",
    level: 0,
    children: [
      {
        regionId: 6401,
        parentId: 64,
        regionName: "银川市",
        level: 1,
        children: [
          {
            regionId: 640104,
            parentId: 6401,
            regionName: "兴庆区",
            level: 2,
          },
          {
            regionId: 640105,
            parentId: 6401,
            regionName: "西夏区",
            level: 2,
          },
          {
            regionId: 640106,
            parentId: 6401,
            regionName: "金凤区",
            level: 2,
          },
          {
            regionId: 640121,
            parentId: 6401,
            regionName: "永宁县",
            level: 2,
          },
          {
            regionId: 640122,
            parentId: 6401,
            regionName: "贺兰县",
            level: 2,
          },
          {
            regionId: 640181,
            parentId: 6401,
            regionName: "灵武市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6402,
        parentId: 64,
        regionName: "石嘴山市",
        level: 1,
        children: [
          {
            regionId: 640202,
            parentId: 6402,
            regionName: "大武口区",
            level: 2,
          },
          {
            regionId: 640205,
            parentId: 6402,
            regionName: "惠农区",
            level: 2,
          },
          {
            regionId: 640221,
            parentId: 6402,
            regionName: "平罗县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6403,
        parentId: 64,
        regionName: "吴忠市",
        level: 1,
        children: [
          {
            regionId: 640302,
            parentId: 6403,
            regionName: "利通区",
            level: 2,
          },
          {
            regionId: 640303,
            parentId: 6403,
            regionName: "红寺堡区",
            level: 2,
          },
          {
            regionId: 640323,
            parentId: 6403,
            regionName: "盐池县",
            level: 2,
          },
          {
            regionId: 640324,
            parentId: 6403,
            regionName: "同心县",
            level: 2,
          },
          {
            regionId: 640381,
            parentId: 6403,
            regionName: "青铜峡市",
            level: 2,
          },
        ],
      },
      {
        regionId: 6404,
        parentId: 64,
        regionName: "固原市",
        level: 1,
        children: [
          {
            regionId: 640402,
            parentId: 6404,
            regionName: "原州区",
            level: 2,
          },
          {
            regionId: 640422,
            parentId: 6404,
            regionName: "西吉县",
            level: 2,
          },
          {
            regionId: 640423,
            parentId: 6404,
            regionName: "隆德县",
            level: 2,
          },
          {
            regionId: 640424,
            parentId: 6404,
            regionName: "泾源县",
            level: 2,
          },
          {
            regionId: 640425,
            parentId: 6404,
            regionName: "彭阳县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6405,
        parentId: 64,
        regionName: "中卫市",
        level: 1,
        children: [
          {
            regionId: 640502,
            parentId: 6405,
            regionName: "沙坡头区",
            level: 2,
          },
          {
            regionId: 640521,
            parentId: 6405,
            regionName: "中宁县",
            level: 2,
          },
          {
            regionId: 640522,
            parentId: 6405,
            regionName: "海原县",
            level: 2,
          },
        ],
      },
    ],
  },
  {
    regionId: 65,
    parentId: 0,
    regionName: "新疆维吾尔自治区",
    level: 0,
    children: [
      {
        regionId: 6501,
        parentId: 65,
        regionName: "乌鲁木齐市",
        level: 1,
        children: [
          {
            regionId: 650102,
            parentId: 6501,
            regionName: "天山区",
            level: 2,
          },
          {
            regionId: 650103,
            parentId: 6501,
            regionName: "沙依巴克区",
            level: 2,
          },
          {
            regionId: 650104,
            parentId: 6501,
            regionName: "新市区",
            level: 2,
          },
          {
            regionId: 650105,
            parentId: 6501,
            regionName: "水磨沟区",
            level: 2,
          },
          {
            regionId: 650106,
            parentId: 6501,
            regionName: "头屯河区",
            level: 2,
          },
          {
            regionId: 650107,
            parentId: 6501,
            regionName: "达坂城区",
            level: 2,
          },
          {
            regionId: 650109,
            parentId: 6501,
            regionName: "米东区",
            level: 2,
          },
          {
            regionId: 650121,
            parentId: 6501,
            regionName: "乌鲁木齐县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6502,
        parentId: 65,
        regionName: "克拉玛依市",
        level: 1,
        children: [
          {
            regionId: 650202,
            parentId: 6502,
            regionName: "独山子区",
            level: 2,
          },
          {
            regionId: 650203,
            parentId: 6502,
            regionName: "克拉玛依区",
            level: 2,
          },
          {
            regionId: 650204,
            parentId: 6502,
            regionName: "白碱滩区",
            level: 2,
          },
          {
            regionId: 650205,
            parentId: 6502,
            regionName: "乌尔禾区",
            level: 2,
          },
        ],
      },
      {
        regionId: 6504,
        parentId: 65,
        regionName: "吐鲁番市",
        level: 1,
        children: [
          {
            regionId: 650402,
            parentId: 6504,
            regionName: "高昌区",
            level: 2,
          },
          {
            regionId: 650421,
            parentId: 6504,
            regionName: "鄯善县",
            level: 2,
          },
          {
            regionId: 650422,
            parentId: 6504,
            regionName: "托克逊县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6505,
        parentId: 65,
        regionName: "哈密市",
        level: 1,
        children: [
          {
            regionId: 650502,
            parentId: 6505,
            regionName: "伊州区",
            level: 2,
          },
          {
            regionId: 650521,
            parentId: 6505,
            regionName: "巴里坤哈萨克自治县",
            level: 2,
          },
          {
            regionId: 650522,
            parentId: 6505,
            regionName: "伊吾县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6523,
        parentId: 65,
        regionName: "昌吉回族自治州",
        level: 1,
        children: [
          {
            regionId: 652301,
            parentId: 6523,
            regionName: "昌吉市",
            level: 2,
          },
          {
            regionId: 652302,
            parentId: 6523,
            regionName: "阜康市",
            level: 2,
          },
          {
            regionId: 652323,
            parentId: 6523,
            regionName: "呼图壁县",
            level: 2,
          },
          {
            regionId: 652324,
            parentId: 6523,
            regionName: "玛纳斯县",
            level: 2,
          },
          {
            regionId: 652325,
            parentId: 6523,
            regionName: "奇台县",
            level: 2,
          },
          {
            regionId: 652327,
            parentId: 6523,
            regionName: "吉木萨尔县",
            level: 2,
          },
          {
            regionId: 652328,
            parentId: 6523,
            regionName: "木垒哈萨克自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6527,
        parentId: 65,
        regionName: "博尔塔拉蒙古自治州",
        level: 1,
        children: [
          {
            regionId: 652701,
            parentId: 6527,
            regionName: "博乐市",
            level: 2,
          },
          {
            regionId: 652702,
            parentId: 6527,
            regionName: "阿拉山口市",
            level: 2,
          },
          {
            regionId: 652722,
            parentId: 6527,
            regionName: "精河县",
            level: 2,
          },
          {
            regionId: 652723,
            parentId: 6527,
            regionName: "温泉县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6528,
        parentId: 65,
        regionName: "巴音郭楞蒙古自治州",
        level: 1,
        children: [
          {
            regionId: 652801,
            parentId: 6528,
            regionName: "库尔勒市",
            level: 2,
          },
          {
            regionId: 652822,
            parentId: 6528,
            regionName: "轮台县",
            level: 2,
          },
          {
            regionId: 652823,
            parentId: 6528,
            regionName: "尉犁县",
            level: 2,
          },
          {
            regionId: 652824,
            parentId: 6528,
            regionName: "若羌县",
            level: 2,
          },
          {
            regionId: 652825,
            parentId: 6528,
            regionName: "且末县",
            level: 2,
          },
          {
            regionId: 652826,
            parentId: 6528,
            regionName: "焉耆回族自治县",
            level: 2,
          },
          {
            regionId: 652827,
            parentId: 6528,
            regionName: "和静县",
            level: 2,
          },
          {
            regionId: 652828,
            parentId: 6528,
            regionName: "和硕县",
            level: 2,
          },
          {
            regionId: 652829,
            parentId: 6528,
            regionName: "博湖县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6529,
        parentId: 65,
        regionName: "阿克苏地区",
        level: 1,
        children: [
          {
            regionId: 652901,
            parentId: 6529,
            regionName: "阿克苏市",
            level: 2,
          },
          {
            regionId: 652902,
            parentId: 6529,
            regionName: "库车市",
            level: 2,
          },
          {
            regionId: 652922,
            parentId: 6529,
            regionName: "温宿县",
            level: 2,
          },
          {
            regionId: 652924,
            parentId: 6529,
            regionName: "沙雅县",
            level: 2,
          },
          {
            regionId: 652925,
            parentId: 6529,
            regionName: "新和县",
            level: 2,
          },
          {
            regionId: 652926,
            parentId: 6529,
            regionName: "拜城县",
            level: 2,
          },
          {
            regionId: 652927,
            parentId: 6529,
            regionName: "乌什县",
            level: 2,
          },
          {
            regionId: 652928,
            parentId: 6529,
            regionName: "阿瓦提县",
            level: 2,
          },
          {
            regionId: 652929,
            parentId: 6529,
            regionName: "柯坪县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6530,
        parentId: 65,
        regionName: "克孜勒苏柯尔克孜自治州",
        level: 1,
        children: [
          {
            regionId: 653001,
            parentId: 6530,
            regionName: "阿图什市",
            level: 2,
          },
          {
            regionId: 653022,
            parentId: 6530,
            regionName: "阿克陶县",
            level: 2,
          },
          {
            regionId: 653023,
            parentId: 6530,
            regionName: "阿合奇县",
            level: 2,
          },
          {
            regionId: 653024,
            parentId: 6530,
            regionName: "乌恰县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6531,
        parentId: 65,
        regionName: "喀什地区",
        level: 1,
        children: [
          {
            regionId: 653101,
            parentId: 6531,
            regionName: "喀什市",
            level: 2,
          },
          {
            regionId: 653121,
            parentId: 6531,
            regionName: "疏附县",
            level: 2,
          },
          {
            regionId: 653122,
            parentId: 6531,
            regionName: "疏勒县",
            level: 2,
          },
          {
            regionId: 653123,
            parentId: 6531,
            regionName: "英吉沙县",
            level: 2,
          },
          {
            regionId: 653124,
            parentId: 6531,
            regionName: "泽普县",
            level: 2,
          },
          {
            regionId: 653125,
            parentId: 6531,
            regionName: "莎车县",
            level: 2,
          },
          {
            regionId: 653126,
            parentId: 6531,
            regionName: "叶城县",
            level: 2,
          },
          {
            regionId: 653127,
            parentId: 6531,
            regionName: "麦盖提县",
            level: 2,
          },
          {
            regionId: 653128,
            parentId: 6531,
            regionName: "岳普湖县",
            level: 2,
          },
          {
            regionId: 653129,
            parentId: 6531,
            regionName: "伽师县",
            level: 2,
          },
          {
            regionId: 653130,
            parentId: 6531,
            regionName: "巴楚县",
            level: 2,
          },
          {
            regionId: 653131,
            parentId: 6531,
            regionName: "塔什库尔干塔吉克自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6532,
        parentId: 65,
        regionName: "和田地区",
        level: 1,
        children: [
          {
            regionId: 653201,
            parentId: 6532,
            regionName: "和田市",
            level: 2,
          },
          {
            regionId: 653221,
            parentId: 6532,
            regionName: "和田县",
            level: 2,
          },
          {
            regionId: 653222,
            parentId: 6532,
            regionName: "墨玉县",
            level: 2,
          },
          {
            regionId: 653223,
            parentId: 6532,
            regionName: "皮山县",
            level: 2,
          },
          {
            regionId: 653224,
            parentId: 6532,
            regionName: "洛浦县",
            level: 2,
          },
          {
            regionId: 653225,
            parentId: 6532,
            regionName: "策勒县",
            level: 2,
          },
          {
            regionId: 653226,
            parentId: 6532,
            regionName: "于田县",
            level: 2,
          },
          {
            regionId: 653227,
            parentId: 6532,
            regionName: "民丰县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6540,
        parentId: 65,
        regionName: "伊犁哈萨克自治州",
        level: 1,
        children: [
          {
            regionId: 654002,
            parentId: 6540,
            regionName: "伊宁市",
            level: 2,
          },
          {
            regionId: 654003,
            parentId: 6540,
            regionName: "奎屯市",
            level: 2,
          },
          {
            regionId: 654004,
            parentId: 6540,
            regionName: "霍尔果斯市",
            level: 2,
          },
          {
            regionId: 654021,
            parentId: 6540,
            regionName: "伊宁县",
            level: 2,
          },
          {
            regionId: 654022,
            parentId: 6540,
            regionName: "察布查尔锡伯自治县",
            level: 2,
          },
          {
            regionId: 654023,
            parentId: 6540,
            regionName: "霍城县",
            level: 2,
          },
          {
            regionId: 654024,
            parentId: 6540,
            regionName: "巩留县",
            level: 2,
          },
          {
            regionId: 654025,
            parentId: 6540,
            regionName: "新源县",
            level: 2,
          },
          {
            regionId: 654026,
            parentId: 6540,
            regionName: "昭苏县",
            level: 2,
          },
          {
            regionId: 654027,
            parentId: 6540,
            regionName: "特克斯县",
            level: 2,
          },
          {
            regionId: 654028,
            parentId: 6540,
            regionName: "尼勒克县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6542,
        parentId: 65,
        regionName: "塔城地区",
        level: 1,
        children: [
          {
            regionId: 654201,
            parentId: 6542,
            regionName: "塔城市",
            level: 2,
          },
          {
            regionId: 654202,
            parentId: 6542,
            regionName: "乌苏市",
            level: 2,
          },
          {
            regionId: 654203,
            parentId: 6542,
            regionName: "沙湾市",
            level: 2,
          },
          {
            regionId: 654221,
            parentId: 6542,
            regionName: "额敏县",
            level: 2,
          },
          {
            regionId: 654224,
            parentId: 6542,
            regionName: "托里县",
            level: 2,
          },
          {
            regionId: 654225,
            parentId: 6542,
            regionName: "裕民县",
            level: 2,
          },
          {
            regionId: 654226,
            parentId: 6542,
            regionName: "和布克赛尔蒙古自治县",
            level: 2,
          },
        ],
      },
      {
        regionId: 6543,
        parentId: 65,
        regionName: "阿勒泰地区",
        level: 1,
        children: [
          {
            regionId: 654301,
            parentId: 6543,
            regionName: "阿勒泰市",
            level: 2,
          },
          {
            regionId: 654321,
            parentId: 6543,
            regionName: "布尔津县",
            level: 2,
          },
          {
            regionId: 654322,
            parentId: 6543,
            regionName: "富蕴县",
            level: 2,
          },
          {
            regionId: 654323,
            parentId: 6543,
            regionName: "福海县",
            level: 2,
          },
          {
            regionId: 654324,
            parentId: 6543,
            regionName: "哈巴河县",
            level: 2,
          },
          {
            regionId: 654325,
            parentId: 6543,
            regionName: "青河县",
            level: 2,
          },
          {
            regionId: 654326,
            parentId: 6543,
            regionName: "吉木乃县",
            level: 2,
          },
        ],
      },
      {
        regionId: 659001,
        parentId: 65,
        regionName: "石河子市",
        level: 1,
        children: [
          {
            regionId: 659001000,
            parentId: 659001,
            regionName: "石河子市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659002,
        parentId: 65,
        regionName: "阿拉尔市",
        level: 1,
        children: [
          {
            regionId: 659002000,
            parentId: 659002,
            regionName: "阿拉尔市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659003,
        parentId: 65,
        regionName: "图木舒克市",
        level: 1,
        children: [
          {
            regionId: 659003000,
            parentId: 659003,
            regionName: "图木舒克市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659004,
        parentId: 65,
        regionName: "五家渠市",
        level: 1,
        children: [
          {
            regionId: 659004000,
            parentId: 659004,
            regionName: "五家渠市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659005,
        parentId: 65,
        regionName: "北屯市",
        level: 1,
        children: [
          {
            regionId: 659005000,
            parentId: 659005,
            regionName: "北屯市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659006,
        parentId: 65,
        regionName: "铁门关市",
        level: 1,
        children: [
          {
            regionId: 659006000,
            parentId: 659006,
            regionName: "铁门关市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659007,
        parentId: 65,
        regionName: "双河市",
        level: 1,
        children: [
          {
            regionId: 659007000,
            parentId: 659007,
            regionName: "双河市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659008,
        parentId: 65,
        regionName: "可克达拉市",
        level: 1,
        children: [
          {
            regionId: 659008000,
            parentId: 659008,
            regionName: "可克达拉市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659009,
        parentId: 65,
        regionName: "昆玉市",
        level: 1,
        children: [
          {
            regionId: 659009000,
            parentId: 659009,
            regionName: "昆玉市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659010,
        parentId: 65,
        regionName: "胡杨河市",
        level: 1,
        children: [
          {
            regionId: 659010000,
            parentId: 659010,
            regionName: "胡杨河市",
            level: 2,
          },
        ],
      },
      {
        regionId: 659011,
        parentId: 65,
        regionName: "新星市",
        level: 1,
        children: [
          {
            regionId: 659011000,
            parentId: 659011,
            regionName: "新星市",
            level: 2,
          },
        ],
      },
    ],
  },
];
