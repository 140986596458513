<template>
  <el-cascader
    v-bind="$attrs"
    v-model="innerValue"
    :options="options"
    clearable
    filterable
  />
</template>

<script>
import regions from "./region.js";

export default {
  name: "AreaSelector",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      regions,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    options() {
      return regions.map((item) => {
        return {
          value: item.regionId,
          label: item.regionName,
          children: item.children.map((item) => {
            return {
              value: item.regionId,
              label: item.regionName,
              children: item.children.map((item) => {
                return {
                  value: item.regionId,
                  label: item.regionName,
                };
              }),
            };
          }),
        };
      });
    },
  },
};
</script>
